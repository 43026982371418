import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";

import useDocumentTitle from "hooks/documentTitle";
import useMetaDescription from "hooks/metaDescription";
import { getPressArticle, getPressList } from "util/contentfulApi";

import Button from "components/forms/Button";
import Link from "components/layout/Link";
import PageWrapper from "components/layout/PageWrapper";
import TextBlock from "components/layout/TextBlock";
import Section from "components/Section";
import HorizontalScrollBlock from "components/layout/HorizontalScrollBlock";
import ArticleHeader from "components/layout/ArticleHeader";
import ArticleMediaWrapper from "components/layout/ArticleMediaWrapper";
import { articleListIdMap } from "util/data/press";
import ArticleCard from "components/layout/articles/ArticleCard";

const renderOptions = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => (
      <Link
        to={node.data.uri}
        target="_blank"
        rel="noopener noreferer"
      >
        {children}
      </Link>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
  },
};

const ArticleDetail = ({ match }) => {
  const [article, setArticle] = useState(null);
  const [bottomArticles, setBottomArticles] = useState([]);
  const pressListId = match.params.pressId === 'company-news' ? 'news-room' : match.params.pressId;

  useDocumentTitle((article && article.title) || "OJO Home");
  useMetaDescription("");
  useEffect(() => {
    getPressArticle(match.params.articleId).then((data) => {
      setArticle(data);
    });
    getPressList(pressListId, 8, 0, match.params.articleId).then(
      ({ articles }) => {
        setBottomArticles(articles);
      }
    );
  }, [match.params.articleId]);

  const itemsList = bottomArticles.map((article) => (
    <ArticleCard {...article} showImage size="medium" key={article.id} />
  ));  

  const pressId = match.params.pressId === 'news-room' ? 'company-news' : match.params.pressId;

  const breadcrumbsLinks = [
    {
      to: "/media-room",
      text: "Media Room",
    },
    {
      to: `/${pressId}`,
      text: articleListIdMap[match.params.pressId].title,
    }
  ];  

  return (
    <>
      <PageWrapper center backgroundColor="white">
        {article && (
          <>
            <ArticleHeader
              title={article.title}
              breadcrumbsLinks={breadcrumbsLinks}
              articleDate={article.date}
              articleLocation={article.location}
            />
            <ArticleMediaWrapper>
              {article.video && (
                <div className="article__video-frame">
                  <iframe
                    title="Introducing OJO Home"
                    src={`${article.video}?autoplay=0y`}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                    frameborder="0"
                    allow="autoplay; fullscreen"
                    allowfullscreen
                  ></iframe>
                  <script src="https://player.vimeo.com/api/player.js"></script>
                </div>
              )}
              {!article.video && (
                <>
                  <img
                    className="article__main-img"
                    src={article.image}
                    alt={article.title}
                  />
                  <img
                    className="article__mobile-img"
                    src={article.mobileImage || article.image}
                    alt={article.title}
                  />
                </>
              )}
            </ArticleMediaWrapper>
            <TextBlock>
              {documentToReactComponents(article.text, renderOptions)}
            </TextBlock>
          </>
        )}
        {!article && (
          <React.Fragment>
            <h2>Loading...</h2>
            <img alt="Placeholder" />
            <div>Loading...</div>
            <div>Loading...</div>
          </React.Fragment>
        )}
      </PageWrapper>
      <Section title="OJO in the News" underlined>
        <HorizontalScrollBlock itemsList={itemsList} />
        <Button tertiary to="/ojo-in-the-news">
          Read More
        </Button>
      </Section>
    </>
  );
};

ArticleDetail.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      pressId: PropTypes.string,
      articleId: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default ArticleDetail;
