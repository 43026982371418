import PropTypes from 'prop-types';

import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

/* TODO: udpate with image key */
const openGraphKeys = ['title', 'site_name', 'url', 'description', 'type'];
const openGraphConfiguration = {
  default: {
    title: 'Buy, sell, and own with OJO',
    site_name: 'OJO Home',
    url: 'https://ojo.com',
    description: 'OJO provides tools and expert guidance to help make homeownership more accessible to more people.',
    type: 'website',
    image: 'https://cdn.ojo.me/ojo_circle.png',
  },
  '/our-story': {
    title: 'Learn more about what we do at OJO and why',
    site_name: 'OJO Home',
    url: 'https://ojo.com/our-story/',
    description: 'Learn more about OJO, our company, our purpose, our culture, and how we’re working to make homeownership more accessible to more people.',
    type: 'website',
    image: 'https://cdn.ojo.me/ojo_circle.png',
  },
  '/careers': {
    title: 'Learn about careers at OJO',
    site_name: 'OJO Home',
    url: 'https://ojo.com/careers/',
    description: 'Learn more about our award-winning culture, career opportunities, and find the role that’s right for you.',
    type: 'website',
    image: 'https://cdn.ojo.me/ojo_circle.png',
  },
  '/agent-teams': {
    title: 'Grow your real estate business with OJO',
    site_name: 'OJO Home',
    url: 'https://ojo.com/agent-teams/',
    description: 'The OJO Select Network introduces agents to homebuyers and sellers and creates stronger, better informed connections that last.',
    type: 'website',
    image: 'https://cdn.ojo.me/ojo_circle.png',
  },
  '/agent-teams/faq': {
    title: 'OJO Select Network Frequently Asked Questions',
    site_name: 'OJO Home',
    url: 'https://ojo.com/agent-teams/faq',
    description: 'Have questions about the OJO Select Network? Find answers here.',
    type: 'website',
    image: 'https://cdn.ojo.me/ojo_circle.png',
  },
  '/media-room': {
    title: 'See how OJO is making headlines',
    site_name: 'OJO Home',
    url: 'https://ojo.com/media-room/',
    description: 'Find the latest industry news and press resources for OJO.',
    type: 'website',
    image: 'https://cdn.ojo.me/ojo_circle.png',
  },
  '/company-news': {
    title: 'Real estate reports and announcements from OJO',
    site_name: 'OJO Home',
    url: 'https://ojo.com/company-news/',
    description: 'Read the latest announcements and reports from OJO.',
    type: 'website',
    image: 'https://cdn.ojo.me/ojo_circle.png',
  },
  '/brokers-licenses': {
    title: 'OJO Real Estate Broker Licenses',
    site_name: 'OJO Home',
    url: 'https://ojo.com/broker-licenses/',
    description: 'Real estate broker licenses held by OJO.',
    type: 'website',
    image: 'https://cdn.ojo.me/ojo_circle.png',
  },
  '/ojo-in-the-news': {
    title: 'The latest media coverage of OJO',
    site_name: 'OJO Home',
    url: 'https://ojo.com/ojo-in-the-news/',
    description: 'Read the latest OJO media coverage and industry news.',
    type: 'website',
    image: 'https://cdn.ojo.me/ojo_circle.png',
  },
  '/homebuyer': {
    title: 'Find the right home for you with OJO',
    site_name: 'OJO Home',
    url: 'https://ojo.com/homebuyer/',
    description: 'With personalized search, market insights, and expert guidance from top real estate agents, OJO helps you find the houses that feel like home.',
    type: 'website',
    image: 'https://cdn.ojo.me/ojo_circle.png',
  },
  '/homeowner': {
    title: 'Manage your home with OJO',
    site_name: 'OJO Home',
    url: 'https://ojo.com/homeowner/',
    description: 'OJO Homeowner helps you manage the equity you’ve built, track your home’s value, and keep up with home maintenance — think of it as your personal guide to homeownership.',
    type: 'website',
    image: 'https://cdn.ojo.me/ojo_circle.png',
  },
  '/home-seller': {
    title: 'Sell your home with OJO',
    site_name: 'OJO Home',
    url: 'https://ojo.com/home-seller/',
    description: 'OJO helps homeowners get the most out of their home sale using market insights, connections to industry experts, and support from the right listing agent.',
    type: 'website',
  },
  '/partnerships': {
    title: 'Scale your reach by partnering with OJO',
    site_name: 'OJO Home',
    url: 'https://ojo.com/partnerships/',
    description: 'Work with OJO and reach millions of homebuyers, sellers, and owners through customizable offerings.',
    image: 'https://cdn.ojo.me/ojo_circle.png',
  },
};

const SeoTool = ({ openGraphConfig }) => {
  const location = useLocation();
  useEffect(() => {
    let path = location.pathname;
    if (location.pathname.lastIndexOf('/') === location.pathname.length - 1) {
      path = location.pathname.substring(0, location.pathname.length - 1);
    }
    document.getElementById('canonical-url').setAttribute('href', `https://ojo.com${path}`);

    let openGraphSettings = openGraphConfig || openGraphConfiguration[path] || openGraphConfiguration.default;
    openGraphKeys.forEach(key => {
      document.getElementById(`og-${key}`).setAttribute('content', openGraphSettings[key]);
    });

  }, [location, openGraphConfig]);

  return null;
};

SeoTool.propTypes = {
  openGraphConfig: PropTypes.shape({
    title: PropTypes.string,
    site_name: PropTypes.string,
    url: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    image: PropTypes.string,
  }),
};

SeoTool.defaultProps = {
  openGraphConfig: null,
};

export default SeoTool;
