import React, { useState } from "react";
import PropTypes from "prop-types";

import Link from "components/layout/Link";
import { StyledArticleCard } from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index";

const ArticleCard = ({
  backupImage,
  href,
  image,
  isLocal,
  title,
  showImage,
}) => {
  const [imageFailedToLoad, setImageFailedToLoad] = useState(false);

  const linkProps = isLocal
    ? {}
    : { target: "_blank", rel: "noopener noreferer" };

  return (
    <StyledArticleCard>
      <div className="card__content">
        {showImage && (
          <div className="card__image">
            <img
              src={imageFailedToLoad ? backupImage : image}
              alt={title}
              onError={() => setImageFailedToLoad(true)}
            />
          </div>
        )}
        <div className="card__description">
          <h3 className="card__title">
            <Link to={href} {...linkProps}>
              <span dangerouslySetInnerHTML={{ __html: title }} />
            </Link>
          </h3>
          <div className="card__more">
            <Link to={href} {...linkProps}>
              <span>Read </span>
              <FontAwesomeIcon icon="arrow-right" />
            </Link>
          </div>
        </div>
      </div>
    </StyledArticleCard>
  );
};

ArticleCard.propTypes = {
  backupImage: PropTypes.string,
  href: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  isLocal: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  showImage: PropTypes.bool,
  snippet: PropTypes.string,
};

ArticleCard.defaultProps = {
  isLocal: true,
  showImage: false,
  size: "small",
  backupImage: "",
};

export default ArticleCard;
