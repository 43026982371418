export const articleListIdMap = {
  'company-news': {
    title: 'Company News',
  },
  'news-room': {
    title: 'Company News',
  },
  'featured-in': {
    title: 'Featured In',
  },
  'videos': {
    title: 'Videos',
  },
};

export default {
  articleListIdMap,
};