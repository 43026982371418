import React from 'react';
import { StyledFaqFilter } from './faqFilter/styled';
import Select from './forms/Select';

const FaqFilter = ({options, selectedSection, onSelect}) => {
  return (
    <StyledFaqFilter>
      <div className="faq-filter__label">Search by topic:</div>
      <div className="faq-filter__dropdown">
        <Select
          options={options}
          selected={selectedSection}
          onSelect={onSelect}
        />
      </div>
    </StyledFaqFilter>
  );
};

export default FaqFilter;
