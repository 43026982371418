import React from "react";
import { StyledItemCard } from "./itemCard/styled";
import Link from "./layout/Link";

const ItemCard = ({
  linkText,
  linkTo,
  negative,
  image,
  backgroundColor,
  size,
}) => (
  <StyledItemCard negative={negative} backgroundColor={backgroundColor} size={size}>
    <Link to={linkTo} target="_blank" className="card__wrapper">
      <div className="card__container">
        <img src={image} alt={linkText} />
        <div className="card__title">
          <span to={linkTo} target="_blank">{linkText}</span>
        </div>
      </div>
    </Link>
  </StyledItemCard>
);

export default ItemCard;
