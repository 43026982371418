import React, { useContext, useEffect, useState } from "react";

import { Store, SET_PRESS_PAGE } from "Store";

import useDocumentTitle from "hooks/documentTitle";
import useMetaDescription from "hooks/metaDescription";
import useMetaKeywords from "hooks/metaKeywords";
import { getPressList } from "util/contentfulApi";

import ArticleBrief from "components/layout/articles/ArticleBrief";
import Button from "components/forms/Button";
import PageWrapper, { HollowPageWrapper } from "components/layout/PageWrapper";
import PageHeader from "components/layout/PageHeader";
import Section from "components/Section";
import Row from "components/layout/Row";
import Column from "components/layout/Column";
import Block from "components/layout/Block";

const ojoInTheNewsSectionFooter = (
<Button secondary to="/ojo-in-the-news/0">
  View More Articles
</Button>
);

const companyNewsSectionFooter = (
<Button secondary to="/company-news/0">
  View More Articles
</Button>
);

const MediaRoom = () => {
  useDocumentTitle("See how OJO is making headlines");
  useMetaDescription(
    "Find the latest industry news and press resources for OJO."
  );
  useMetaKeywords("OJO");

  const { dispatch, state } = useContext(Store);

  const { newsRoom, loaded } = state.pressPage;

  const highlightArticles = newsRoom.slice(0, 3);
  // const extraLineArticles = newsRoom.slice(3, 6);

  useEffect(() => {
    if (!loaded) {
      window
        .fetch("/data/generated/press.json", {
          method: "GET",
        })
        .then((response) => {
          response.json().then((data) => {
            dispatch({ type: SET_PRESS_PAGE, payload: data });
          });
        });
    }
  }, []);

  const [articles, setArticles] = useState([]);
  const pageNumber = 0;

  useEffect(() => {
    getPressList("feed", 3, pageNumber * 3).then((data) => {
      data.articles.forEach((art, index) => {
        art.backupImage = `/img/placeholders/newsfeed_placeholder_${
          (index % 4) + 1
        }.png`;
      });
      setArticles(data.articles);
    });
  }, [undefined, pageNumber]);



  return (
    <>
      <PageWrapper center backgroundColor="emerald">
        <PageHeader
          supertext="Media Room"
          title="See what we're up to"
          negative
        />
      </PageWrapper>
      <HollowPageWrapper backgroundColor="white">
        <Section
          title="Company News"
          description="Our latest announcements and industry reports."
          center
          footer={companyNewsSectionFooter}
        >
          <Row gap={40} marginBottom={20} matchHeight>
            {highlightArticles.map((article) => (
              <Column>
                <ArticleBrief size="large" showInfo showSnippet {...article} />
              </Column>
            ))}
          </Row>
        </Section>

        <Section
          title="OJO in the News"
          description="We’re making headlines. Find the latest updates about us here."
          center
          footer={ojoInTheNewsSectionFooter}
        >
          <Row gap={40} marginBottom={20} matchHeight>
            {articles.map((article) => (
              <Column>
                <ArticleBrief size="large" showImage showInfo {...article} />
              </Column>
            ))}
          </Row>
          
        </Section>

        <Section
          title="Press Resources"
          description="For more information, please contact Patrick Kearns at pkearns@ojo.com"
          center
        >
          <Row gap={40} marginBottom={20} matchHeight>
            <Column size="3">
              <Block
                title="Press Kit"
                description="Product and official images."
                linkTo={`${window.location.protocol}/media-kit/OJO-Press-Kit-2022.zip`}
                linkText="Download"
              />
            </Column>
            <Column size="3">
              <Block
                title="Company Info"
                description="Our story and leadership team."
                linkTo={`${window.location.protocol}/media-kit/OJO-Leadership-2022.zip`}
                linkText="Download"
              />
            </Column>
            <Column size="3">
              <Block
                title="Brand Assets"
                description="Logos and guidelines."
                linkTo={`${window.location.protocol}/media-kit/OJO-Logos-2022.zip`}
                linkText="Download"
              />
            </Column>
          </Row>
        </Section>
      </HollowPageWrapper>
    </>
  );
};

export default MediaRoom;
