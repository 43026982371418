import React from "react";
import { StyledTabs } from "./Tabs/styled";

const Tabs = ({children}) => {
  return (
    <StyledTabs>
      {children}
    </StyledTabs>
  )

};

export default Tabs;