import styled from "styled-components";
import { deviceScreenSizeQuery } from "components/theme/index";

export const StyledFooter = styled.footer`
  background-color: ${({ theme }) => theme.colors.concrete};
  padding: 85px 38px;
  position: relative;
  color: ${({ theme }) => theme.colors.night};
  z-index: 3;

  @media ${deviceScreenSizeQuery.laptop} {
    padding: 85px 68px;
  }

  .footer__wrapper {
    max-width: ${({ theme }) => theme.breakpointDesktop};
    margin: 0 auto;

    @media ${deviceScreenSizeQuery.laptop} {
      padding: 0;
    }
  }

  .footer__nav-container {
    display: flex;
    justify-content: flex-start;
    max-width: 1304px;
    padding-bottom: 88px;
    position: relative;

    @media ${deviceScreenSizeQuery.laptop} {
      max-width: 100%;
    }
  }

  .footer__logo {
    max-width: 78px;
    position: absolute;
    left: 0;
    top: 10px;

    @media ${deviceScreenSizeQuery.laptop} {
      position: static;
    }
  }

  .footer__login {
    position: absolute;
    right: 0;
    top: 0;

    span button:before {
      content: "Sign In";
      display: inline-block;
    }

    span button > span {
      display: none;
    }

    @media ${deviceScreenSizeQuery.laptop} {
      span button:before {
        display: none;
      }

      span button > span {
        display: inline-block;
      }
    }

    @media ${deviceScreenSizeQuery.laptop} {
      position: static;
    }
  }

  .footer__nav {
    width: 100%;
    padding-top: 114px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    column-gap: 30px;
    row-gap: 30px;
    flex-wrap: wrap;
    user-select: none;

    @media ${deviceScreenSizeQuery.mobile} {
      column-gap: 60px;
      row-gap: 30px;
    }

    * {
      display: block;
    }

    .footer__nav-section {
      &.footer__nav-section--social {
        position: absolute;
        bottom: 30px;
        left: 0;
        right: 0;
        height: 32px;

        @media ${deviceScreenSizeQuery.tablet} {
          position: static;
        }
      }
    }

    .footer__login {
      white-space: nowrap;
    }

    span {
      + span {
        margin-top: 0.625rem;
      }

      > a {
        color: ${({ theme }) => theme.colors.night};
        font-size: 1.125rem;
        line-height: 1.625rem;
        transition: color 0.3s ease-in;

        &:hover,
        &:active {
          color: ${({ theme }) => theme.colors.emerald};
        }
      }
    }

    @media ${deviceScreenSizeQuery.mobile} {
      justify-content: flex-start;
    }

    @media ${deviceScreenSizeQuery.laptop} {
      justify-content: flex-start;
      flex-wrap: nowrap;
      width: auto;
      padding: 0;
      gap: 60px;
    }

    @media ${deviceScreenSizeQuery.desktop} {
      gap: 120px;
    }
  }

  .footer__nav-title {
    font-size: 1.625rem;
    line-height: 2.375rem;
    padding-bottom: 0.875rem;
    color: ${({ theme }) => theme.colors.emerald};
    font-family: ${({ theme }) => theme.fonts.primary};
  }

  .footer__info {
    position: relative;
    font-size: 0.75rem;

    > div {
      width: 100%;
    }

    small {
      font-size: 0.75rem;
      line-height: 1.25rem;
      color: ${({ theme }) => theme.colors.night};
      font-family: ${({ theme }) => theme.fonts.secondary};
    }

    a {
      text-decoration: none;
      color: ${({ theme }) => theme.colors.night};
      transition: color 0.3s ease-in;

      &:hover,
      &:active {
        color: ${({ theme }) => theme.colors.emerald};
      }
    }

    .email-link {
      text-decoration: underline;
    }

    @media ${deviceScreenSizeQuery.laptop} {
      padding-top: 0;
      display: flex;
      justify-content: space-between;
      gap: 60px;
      max-width: 100%;
    }
  }

  .footer__info-brokers {
    margin-top: 30px;

    @media ${deviceScreenSizeQuery.laptop} {
      margin-top: 60px;
    }
  }

  .footer__info-accessibility {
    padding-top: 30px;

    @media ${deviceScreenSizeQuery.laptop} {
      padding-top: 0;
    }
  }

  .footer__info-heart {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
  }

  .footer__social {
    display: flex;
    gap: 24px;
    margin-bottom: 40px;
    align-items: center;
    user-select: none;

    > * {
      max-width: 32px;
      max-height: 45px;

      > img {
        width: 100%;
      }
    }

    @media ${deviceScreenSizeQuery.laptop} {
      position: static;
      gap: 40px;
    }
  }

  .footer__equal-housing {
    margin-top: 30px;

    a {
      display: flex;
      column-gap: 12px;
      align-items: center;
      justify-content: flex-start;
      font-size: 0.625rem;
      line-height: 0.938rem;
      max-width: 330px;

      svg {
        min-width: 30px;
        max-height: 32px;
        transition: fill .3s ease;
      }

      &:hover {
        svg {
          fill: ${({theme})=> theme.colors.emerald};
        }
      }
    }

    @media ${deviceScreenSizeQuery.tablet} {
      margin: 0 0 40px 0;
    }
  }

  .footer__terms {
    a {
      text-decoration: underline;
    }
  }
`;
