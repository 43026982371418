import React from "react";

import useDocumentTitle from "hooks/documentTitle";
import useMetaDescription from "hooks/metaDescription";
import useMetaKeywords from "hooks/metaKeywords";

import PageWrapper from "components/layout/PageWrapper";
import PageFeature from "components/layout/PageFeature";
import Section from "components/Section";
import BrandTile from "components/tiles/BrandTile";
import HorizontalScroll from "components/layout/HorizontalScroll";
import Link from "components/layout/Link";
import homebuyerHeroImage from "assets/img/homebuyer-hero.jpg";
import searchTileImage from "assets/img/homebuyer-search.jpg";
import financesTileImage from "assets/img/homebuyer-finances.jpg";
import agentTileImage from "assets/img/homebuyer-agent.jpg";
import stayOnTopTileImage from "assets/img/homebuyer-stay-on-top.jpg";
import ItemCard from "components/ItemCard";

import seeHomesImage from "assets/img/see-homes.png";
import calculateBuyingImage from "assets/img/calculate-buying-power.png";
import findAgentImage from "assets/img/find-your-agent.png";
import manageHomeImage from "assets/img/manage-your-home.png";
import appStoreImage from "assets/img/appstore.png";
import googlePlayImage from "assets/img/gplay.png";

const itemsList = [
  <ItemCard
    linkTo="https://movoto.com"
    linkText="See Homes"
    backgroundColor="seafoam"
    size="large"
    image={seeHomesImage}
  />,
  <ItemCard
    linkTo="https://www.movoto.com/mortgages/buyingpower/"
    linkText="Calculate Your Buying Power"
    negative
    backgroundColor="forest"
    size="small"
    image={calculateBuyingImage}
  />,
  <ItemCard
    linkTo="https://www.movoto.com/agents/"
    linkText="Find Your Agent"
    backgroundColor="springgreen"
    size="medium"
    image={findAgentImage}
  />,
  <ItemCard
    linkTo="https://homeowner.ojo.com/signup"
    linkText="Manage Your Home"
    backgroundColor="concrete"
    image={manageHomeImage}
  />,
];

const AppLinksFooter = (
  <>
    <Link
      to="https://play.google.com/store/apps/details?id=com.movoto.movoto&utm_campaign=buttonmobilepage"
      rel="nofollow"
      target="_blank"
    >
      <img src={googlePlayImage} alt="OJO at Google Play" />
    </Link>
    <Link
      to="https://apps.apple.com/us/app/movoto-real-estate-homes-for/id537795977?ls=1&utm_campaign=buttonmobilepage"
      rel="nofollow"
      target="_blank"
    >
      <img src={appStoreImage} alt="OJO at Apple App Store" />
    </Link>
  </>
);

const HomeBuyers = () => {
  useDocumentTitle(
    "Find the right home for you with OJO"
  );
  useMetaDescription(
    "With personalized search, market insights, and expert guidance from top real estate agents, OJO helps you find the houses that feel like home."
  );
  useMetaKeywords("Support for homebuyers");

  return (
    <>
      <PageWrapper backgroundColor="emerald">
        <PageFeature
          supertext="Homebuyer"
          title="Welcome home is closer than you think."
          description="With customizable search capabilities, tech-enabled insights, and expert guidance, OJO gives you the tools and support you need to feel confident making your next move."
          image={homebuyerHeroImage}
          ctaLink="https://www.movoto.com/"
          ctaLinkProps={{ target: "_blank", rel: "noopener noreferer" }}
          ctaText="Start Your Search"
          negative
        />
      </PageWrapper>
      <Section marginBottom={40}>
        <BrandTile
          headerTitle="Start your search here"
          headerImage={searchTileImage}
          description="Feel in control of your journey by customizing your search based on what’s important to you. We’ll keep you updated with real time listings based on that criteria so you never miss a home that could be right for you."
          buttonTarget="https://www.movoto.com/"
          buttonText="See Homes"
          buttonLinkProps={{rel: "nofollow", target: "_blank"}}
          footer={AppLinksFooter}
        />
        <BrandTile
          reversed
          headerTitle="Prepare your finances"
          headerImage={financesTileImage}
          description="Understand how much you can afford with our suite of financial tools and connect with a top lender to get preapproved so you’re ready to make an offer when the time’s right."
          buttonTarget="https://www.movoto.com/preapproval/"
          buttonText="Get Preapproved"
          buttonLinkProps={{rel: "nofollow", target: "_blank"}}
        />
        <BrandTile
          headerTitle="Match with an agent"
          headerImage={agentTileImage}
          description="Your OJO Network Agent will help you create a winning offer on the home that’s right for you, and support you through the entire process — from inspection and appraisal to closing."
          buttonTarget="https://www.movoto.com/agents/"
          buttonText="Find Your Agent"
          buttonLinkProps={{rel: "nofollow", target: "_blank"}}
        />
        <BrandTile
          reversed
          headerTitle="Manage your biggest asset"
          headerImage={stayOnTopTileImage}
          description="After you close, OJO Homeowner helps you manage all the different parts of homeownership — in one place. Keep your home up to date, monitor your home’s value, and plan your next decision all from your personalized dashboard."
          buttonTarget="https://homeowner.ojo.com/signup"
          buttonText="Set Up Your Dashboard"
          buttonLinkProps={{rel: "nofollow", target: "_blank"}}
        />
      </Section>
      <Section
        title="Start your journey with OJO"
        center
        backgroundColor="emerald"
        negative
        fullWidth
      >
        <HorizontalScroll itemsList={itemsList} paddingTop={30} />
      </Section>
    </>
  );
};

export default HomeBuyers;
