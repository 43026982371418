import styled from "styled-components";
import { deviceScreenSizeQuery } from "components/theme/index";

import checkIcon from "assets/svg/icon-circle-check.svg";

export const StyledCheckList = styled.ul`
  padding: 0;

  li {
    list-style: none;
    font-size: 1.125rem;
    line-height: 1.5rem;
    padding: 15px 0;
    display: flex;
    align-items: center;
    gap: 20px;

    span {
      display: inline-block;
      width: 24px;
      height: 24px;
      background-image: url(${checkIcon});
      background-size: 24px;
      min-width: 24px;
    }
  }

  @media ${deviceScreenSizeQuery.tablet} {
    display: flex;
    gap: 20px 60px;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 702px;

    li {
      padding: 10px 0;
      width: 194px;
      align-items: flex-start;
    }
  }
`;
