import { deviceScreenSizeQuery } from "components/theme/index";
import styled from "styled-components";

export const StyledPaginator = styled.div`
  width: 100%;

  .paginator__wrapper {
    ul {
      display: flex;
      flex-direction: row;
      list-style: none;
      margin: 0 auto;
      padding: 0;
      user-select: none;
      align-items: center;
      justify-content: space-around;

      .previous,
      .next {
        a {
          font-size: 1rem;
          line-height: 1.5rem;
          font-weight: ${({ theme }) => theme.fonts.weight.bold};
        }
      }

      .previous svg {
        margin-right: 8px;
      }

      .next svg {
        margin-left: 8px;
      }

      li a {
        font-size: 0.875rem;
        color: ${({ theme }) => theme.colors.emerald};
      }

      li a:hover {
        color: ${({ theme }) => theme.colors.emerald};
        text-decoration: underline;
      }

      li.selected {
        font-weight: ${({ theme }) => theme.fonts.weight.bold};
        text-decoration: underline;
      }
    }
  }

  @media ${deviceScreenSizeQuery.tablet} {
    .paginator__wrapper {
      ul {
        gap: 26px;
        justify-content: center;
      }
    }
  }
`;
