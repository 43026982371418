import { deviceScreenSizeQuery } from "components/theme/index";
import styled, { css } from "styled-components";

const desktopGap = 62;
const tabletGap = 30;
const columnFill = (columns, gapWidth) =>
  columns > 1 ? (columns - 1) * gapWidth : 0;

export const StyledColumn = styled.div`
  display: block;
  width: 100%;

  /* gap 4.7546012268% */
  /* col 7.5153374222% */

  @media ${deviceScreenSizeQuery.tablet} {
    ${({ size }) =>
      size &&
      css`
        width: ${size * 128 + columnFill(size, tabletGap) + "px"};
      `}
  }

  @media ${deviceScreenSizeQuery.laptop} {
    ${({ size }) =>
      size &&
      css`
        width: ${size * 128 + columnFill(size, desktopGap) + "px"};
      `}
  }
`;

export const StyledRow = styled.div`
  display: block;

  ${({center}) => center && css`
    margin-left: auto;
    margin-right: auto;
  `}

  ${({maxWidth}) => maxWidth && css`
    max-width: ${maxWidth}px;
  `}

  + * {
    margin-top: 60px;
  }

  > * + * {
    margin-top: ${({ marginBottom }) =>
      marginBottom ? `${marginBottom}px` : `0`};
  }

  @media ${deviceScreenSizeQuery.tablet} {
    display: flex;
    flex-direction: row;
    gap: ${({ gap }) => (gap ? `${gap}px` : `4.7546012268%`)};

    > * + * {
      margin-top: 0;
    }

    > * > div,
    > * > article {
      height: ${({matchHeight}) => matchHeight ? '100%' : 'auto'};
    }
  }
`;
