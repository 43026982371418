import styled, { css } from "styled-components";
import { deviceScreenSizeQuery } from "components/theme/index";

export const StyledPageFeature = styled.div`
  .page-feature__wrapper {
    position: relative;
  }

  .page-feature__supertext {
    font-size: 1.375rem;
    line-height: 1.75rem;
    margin-bottom: 20px;
    font-weight: ${({ theme }) => theme.fonts.weight.bold};
    color: ${({ theme }) => theme.colors.raspberry};
  }

  .page-feature__title {
    font-size: 3rem;
    line-height: 3.5rem;
    color: ${({ theme }) => theme.colors.emerald};
    padding-bottom: 20px;
    margin-bottom: 0;
  }

  .page-feature__description {
    font-size: 1.125rem;
    line-height: 1.625rem;
    color: ${({ theme }) => theme.colors.night};
    margin-bottom: 30px;
  }

  .page-feature__wrapper--cta-first {
    .page-feature__cta {
      margin-bottom: 30px;
    }

    .page-feature__cta--mobile {
      margin-bottom: 30px;
    }
  }

  .page-feature__cta {
    display: none;
  }

  .page-feature__cta--mobile {
    display: block;
  }

  .page-feature__image {
    width: 100%;
    border-radius: 20px;
    margin-bottom: 30px;
    max-height: 400px;
    overflow: hidden;

    img {
      border-radius: 20px;
      width: 100%;

      ${({ imageWidthWrap }) =>
        imageWidthWrap &&
        css`
          height: auto;
          max-width: 100%;
        `}
    }
  }

  @media ${deviceScreenSizeQuery.laptop} {
    padding-bottom: 40px;

    .page-feature__cta {
      display: block;
    }

    .page-feature__cta--mobile {
      display: none;
    }

    .page-feature__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      column-gap: 40px;

      ${({ reversed }) =>
        reversed &&
        css`
          flex-direction: row-reverse;
        `}

      > * {
        width: 100%;
        flex: 1 1 0;
      }

      ${({ minHeight }) =>
        minHeight &&
        css`
          min-height: ${minHeight}px;
        `}
    }

    .page-feature__text {
      padding: 40px 0 0;
      min-width: 0;
      max-width: 560px;
    }

    .page-feature__description {
      margin-bottom: 30px;
    }

    .page-feature__supertext {
      margin-bottom: 30px;
    }

    .page-feature__title {
      font-size: 3.25rem;
      line-height: 3.875rem;
    }

    .page-feature__image {
      max-width: 632px;
      align-self: center;
      margin-bottom: 0;
      max-height: 480px;

      img {
        height: 100%;
        max-width: 1304px;

        ${({ imageWidthWrap }) =>
          imageWidthWrap &&
          css`
            height: auto;
            max-width: 100%;
          `}
      }
    }
  }

  ${({ negative }) =>
    negative &&
    css`
      .page-feature__title {
        color: ${({ theme }) => theme.colors.seafoam};
      }

      .page-feature__description,
      .page-feature__supertext {
        color: ${({ theme }) => theme.colors.white};
      }
    `}
`;
