import React from "react";
import Button from "components/forms/Button";
import { StyledPageFeature } from "./pageFeature/styled";

const PageFeature = ({
  title,
  supertext,
  image,
  imageDescription,
  imageWidthWrap,
  minHeight,
  negative,
  reversed,
  description,
  ctaLink,
  ctaText,
  ctaLinkProps,
  children,
}) => (
  <StyledPageFeature
    negative={negative}
    reversed={reversed}
    imageWidthWrap={imageWidthWrap}
    minHeight={minHeight}
  >
    <div className="page-feature__wrapper">
      <div className="page-feature__text">
        {supertext && (
          <div className="page-feature__supertext">{supertext}</div>
        )}
        <h2 className="page-feature__title">{title}</h2>
        <p className="page-feature__description">{description}</p>

        {ctaLink && (
          <div className="page-feature__cta">
            <Button primary negative to={ctaLink} linkProps={ctaLinkProps}>
              {ctaText}
            </Button>
          </div>
        )}
      </div>
      <div className="page-feature__image">
        <img src={image} alt={imageDescription} />
      </div>

      {ctaLink && (
          <div className="page-feature__cta--mobile">
            <Button primary negative to={ctaLink} linkProps={ctaLinkProps}>
              {ctaText}
            </Button>
          </div>
        )}    

      {children}
    </div>
  </StyledPageFeature>
);

const PageFeatureCTAFirst = ({
  title,
  supertext,
  image,
  imageDescription,
  imageWidthWrap,
  minHeight,
  negative,
  reversed,
  description,
  ctaLink,
  ctaText,
  ctaLinkProps,
  children,
}) => (
  <StyledPageFeature
    negative={negative}
    reversed={reversed}
    imageWidthWrap={imageWidthWrap}
    minHeight={minHeight}
  >
    <div className="page-feature__wrapper page-feature__wrapper--cta-first">
      <div className="page-feature__text">
        {supertext && <div className="page-feature__supertext">{supertext}</div>}
        <h2 className="page-feature__title">{title}</h2>
        <p className="page-feature__description">{description}</p>
        {ctaLink && (
          <div className="page-feature__cta">
            <Button primary negative to={ctaLink} linkProps={ctaLinkProps}>
              {ctaText}
            </Button>
          </div>
        )}
      </div>
      {ctaLink && (
        <div className="page-feature__cta--mobile">
          <Button primary negative to={ctaLink} linkProps={ctaLinkProps}>
            {ctaText}
          </Button>
        </div>
      )}
      <div className="page-feature__image">
        <img src={image} alt={imageDescription} />
      </div>
      {children}
    </div>
  </StyledPageFeature>
);

export { PageFeatureCTAFirst };

export default PageFeature;
