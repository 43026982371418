import React from "react";
import { StyledFaqSection } from "./faqSection/styled";

const FaqSection = ({ title, children }) => (
  <StyledFaqSection>
    {title && <h3 className="faq-section__title">{title}</h3>}
    <div className="faq-section__wrapper">{children}</div>
  </StyledFaqSection>
);

export default FaqSection;
