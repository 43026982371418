import { deviceScreenSizeQuery } from "components/theme/index";
import styled, { css } from "styled-components";

export const StyledOverlappingSection = styled.div`
  position: relative;

  ${({zIndex}) => zIndex && css`
    z-index: ${zIndex};
  `}

  & + & {
    margin-top: 100px;
  }

  .overlapping-section__icon {
    margin-bottom: 20px;
  }

  .overlapping-section__title {
    font-size: 2rem;
    line-height: 2.75rem;
    color: ${({ theme }) => theme.colors.emerald};
  }

  .overlapping-section__image {
    width: 100%;

    img {
      width: 100%;
    }
  }

  .overlapping-section__description {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  @media ${deviceScreenSizeQuery.tablet} {
    display: flex;
    gap: 40px;
    align-items: center;

    > * {
      width: 50%;
    }

    & + & {
      margin-top: 80px;
    }

    .overlapping-section__icon {
      margin-bottom: 10px;
    }

    .overlapping-section__title {
      font-size: 2.5rem;
      line-height: 3.25rem;
      color: ${({ theme }) => theme.colors.emerald};
    }

    .overlapping-section__image {
      width: 50%;
      text-align: right;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;

      img {
        min-width: 100%;
        max-width: 463px;
      }
    }

    .overlapping-section__description {
      font-size: 1.125rem;
      line-height: 1.625rem;
    }
  }

  @media (min-width: 1138px) {
    padding-bottom: 60px;

    > * {
      width: 50%;
    }

    & + & {
      margin-top: 0;
    }

    :nth-child(even) {
      .overlapping-section__image {
        right: 54px;
      }
    }

    .overlapping-section__wrapper {
      padding-right: 40px;
    }

    .overlapping-section__icon {
      margin-bottom: 10px;
    }

    .overlapping-section__title {
      font-size: 2.5rem;
      line-height: 3.25rem;
      color: ${({ theme }) => theme.colors.emerald};
    }

    .overlapping-section__image {
      position: absolute;
      top: -30px;
      right: 0;
      padding-left: 53px;
      overflow: hidden;
      width: 50%;
      text-align: right;

      img {
        min-width: 100%;
        max-width: 463px;
      }
    }

    .overlapping-section__description {
      font-size: 1.125rem;
      line-height: 1.625rem;
    }
  }
`;
