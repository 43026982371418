import React from "react";
import PropTypes from "prop-types";

import { StyledBlock } from "./block/styled";
import Button from "components/forms/Button";

const Block = ({
  title,
  description,
  negative,
  linkTo,
  linkText,
}) => {
  return (
    <StyledBlock negative={negative}>
      <div className="block__content">
        <h3 className="block__title">
          {title}
        </h3>
        <p className="block__description">
          {description}
        </p>
        
        { linkTo && negative &&
          <Button secondary negative to={linkTo}>{linkText}</Button>
        }

        { linkTo && !negative &&
          <Button secondary to={linkTo}>{linkText}</Button>
        }
      </div>
    </StyledBlock>
  );
};

Block.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  negative: PropTypes.bool,
  downloadLink: PropTypes.string,
};

Block.defaultProps = {
  title: "",
  description: "",
};

export default Block;
