import React from "react";
import { StyledHorizontalScrollBlock } from "./horizontalScrollBlock/styled";

import { ScrollMenu } from "react-horizontal-scrolling-menu";
import usePreventBodyScroll from "hooks/usePreventBodyScroll";

const HorizontalScrollBlock = ({ itemsList, double, children }) => {
  const { disableScroll, enableScroll } = usePreventBodyScroll();

  const scrollableItemsList = () => {
    if (double) {
      return itemsList.reduce((list, item, index, array) => {
        if (index % 2 === 0) {
          list.push(<div key={index}>{array.slice(index, index + 2)}</div>);
        }
  
        return list;
      }, []);
    }

    return itemsList;
  };

  return (
    <StyledHorizontalScrollBlock>
      <div onMouseEnter={disableScroll} onMouseLeave={enableScroll}>
        <ScrollMenu
          onWheel={onWheel}
          separatorClassName="separator"
          itemClassName="cosito"
        >
          {itemsList &&
            scrollableItemsList().map((item, index) => (
              <div key={index}>{item}</div>
            ))}
          {children &&
            children.map((item, index) => (
              <div itemId={index} key={index}>
                {item}
              </div>
            ))}
        </ScrollMenu>
      </div>
    </StyledHorizontalScrollBlock>
  );
};

export default HorizontalScrollBlock;

function onWheel(scrollContext, event) {
  const isThouchpad =
    Math.abs(event.deltaX) !== 0 || Math.abs(event.deltaY) < 15;

  if (isThouchpad) {
    event.stopPropagation();
    return;
  }

  if (event.deltaY < 0) {
    scrollContext.scrollPrev();
  } else if (event.deltaY > 0) {
    scrollContext.scrollNext();
  }
}
