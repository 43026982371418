export const screenSize = {
  mobileS: "320px",
  mobile: "425px",
  tablet: "768px",
  laptop: "1024px",
  desktop: "1440px",
};

export const deviceScreenSizeQuery = {
  mobileS: `(min-width: ${screenSize.mobileS})`,
  mobile: `(min-width: ${screenSize.mobile})`,
  tablet: `(min-width: ${screenSize.tablet})`,
  laptop: `(min-width: ${screenSize.laptop})`,
  desktop: `(min-width: ${screenSize.desktop})`,
};

const WebsiteTheme = {
  colors: {
    // PRIMARY
    emerald: "#0E6959",
    concrete: "#F2F2F2",
    white: "#FFFFFF",
    //SECONDARY
    springgreen: "#ABE629",
    green: "#94C723",
    darkgreen: "#84B615",
    aubergine: "#38003E",
    grape: "#741B47",
    night: "#212121",
    // TERTIARY
    seafoam: "#C8F6EB",
    forest: "#00494F",
    mustard: "#EBB600",
    // ACCENT
    raspberry: "#B51851",
    // GRAYS
    gray: "#DDDDDD",
    shark: "#545454",
    solitude: "#E6EBF3",
  },
  fonts: {
    default: "'TWK Lausanne', sans-serif",
    primary: "'Wulkan Display', serif",
    secondary: "'TWK Lausanne', sans-serif",
    weight: {
      bold: "750",
      semibold: "600",
      regular: "400",
    },
  },
  zIndex: {
    header: 1000,
    dropdown: 1001,
    modal: 1010,
  },
  breakpoints: { ...deviceScreenSizeQuery },
  breakpointHeader: "768px",
  breakpointDesktop: "1304px",
};

export default WebsiteTheme;
