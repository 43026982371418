/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import PropTypes from "prop-types";

import Button from "components/forms/Button";
import { StyledHero } from "components/hero/styled";
import Search from "components/search/Search";

const HeroSection = ({ largeTitle, title, buttonText, buttonTarget }) => {
  return (
    <StyledHero>
      <div className="hero__img"></div>
      <div className="hero__container">
        {largeTitle && <div className="hero__title">{largeTitle}</div>}
        {title && <h1>{title}</h1>}

        <Search />
        
        {buttonText && (
          <div className="hero__cta">
            <Button
              negative
              large
              to={buttonTarget}
              linkProps={{ target: "_blank", rel: "noopener noreferrer" }}
            >
              {buttonText}
            </Button>
          </div>
        )}
      </div>
    </StyledHero>
  );
};

HeroSection.propTypes = {
  buttonTarget: PropTypes.string,
  buttonText: PropTypes.string,
  description: PropTypes.node,
  title: PropTypes.string,
  largeTitle: PropTypes.string,
};

HeroSection.defaultProps = {
  buttonTarget: null,
  buttonText: "",
  description: "",
  largeTitle: "",
  title: "",
};

export default HeroSection;
