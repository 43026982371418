export const MOBILE_MEDIA_QUERY = '(max-width: 960px)';

export const LOGIN_URL = "https://consumer.ojo.me/login";
export const TOS_URL = "/terms-and-conditions";
export const PRIVACY_URL = '/terms-and-conditions#privacy-policy';
export const CA_TOS_URL = "https://content.ojo.me/ca-terms-conditions.pdf";
export const CA_PRIVACY_URL = "https://content.ojo.me/ca-privacy-policy.pdf";
export default {
  MOBILE_MEDIA_QUERY,
};
