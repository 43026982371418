import React from 'react';
import classnames from 'classnames';

import classes from './WhatIsOjo.module.scss';

import Button from 'components/forms/Button';

const WhatIsOjo = () => {

  return (<div className="foggy">
    <div className={classnames('center', classes.topSection)}>
      <h1>Find the right home for you</h1>
      <Button
        primary
        to='https://home.ojo.com/feed'
        linkProps={{target: '_blank', rel: 'noopener noreferrer'}}
      >
        Discover homes
      </Button>
    </div>

    <div className={classnames('section columns10 fullWidthMobile', classes.bottomSection)}>
      <div>
        <div style={{padding: '56.25% 0 0 0', position: 'relative'}}>
          <iframe title='Find the right home for you, with OJO' src="https://player.vimeo.com/video/492250999?autoplay=1" style={{position:'absolute', top: 0, left: 0, width: '100%', height: '100%'}} frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>);

};

export default WhatIsOjo;
