import React from 'react';

import PageWrapper from 'components/layout/PageWrapper';
import PageFeature from 'components/layout/PageFeature';
import notFoundImage from 'assets/img/404.png';

const NotFoundPage = () => {
  return (
    <PageWrapper backgroundColor="emerald">
      <PageFeature
        supertext="404 Error!"
        title="Sorry for the error - let’s get you back on track."
        negative 
        ctaText="Go To OJO Home"
        ctaLink="/"
        image={notFoundImage}
        imageWidthWrap
      />
    </PageWrapper>
  );
};

export default NotFoundPage;
