import React, { Component } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'util/icons';
import { LOGIN_URL } from 'util/constants';

import { Store, StoreProvider } from 'Store';
import AboutUs from 'pages/AboutUs';
import AgentApplication from 'pages/AgentApplication';
import ArticleDetail from 'pages/ArticleDetail';
import ArticleListPage from 'pages/ArticleListPage';
import BrokerLicensesPage from 'pages/BrokerLicensesPage';
import Careers from 'pages/Careers';
import HomeBuyersPage from 'pages/HomeBuyers';
import HomeownerPage from 'pages/Homeowner';
import HomesellerPage from 'pages/HomeSeller';
import FaqPage from 'pages/FaqPage';
import Footer from 'components/layout/Footer';
import Header from 'components/layout/Header';
import Home from 'pages/Home';
import NotFoundPage from 'pages/NotFoundPage';
import CompanyNews from 'pages/CompanyNews';
import ScrollToTop from 'components/ScrollToTop';
import SEOArticle from 'pages/SEOArticle';
import SEOHomepage from 'pages/SEOHomepage';
import SeoTool from 'components/SeoTool';
import NewsFeed from 'pages/NewsFeed';
import PrivacyPage from 'pages/Privacy';
import TermsAndConditionsPage from 'pages/TermsAndConditions';
import TermsAndConditionsProPlusPage from 'pages/TermsAndConditionsProPlus';
import JobsPage from 'pages/JobsPage';
import WhatIsOjo from 'pages/WhatIsOjo';
import Partnerships from 'pages/Partnerships';
import MediaRoom from 'pages/MediaRoom';
import WebsiteTheme from 'components/theme/index';
import { StyledAppContainer } from 'styled';
import { FixedFooter } from 'components/layout/FixedFooter';

const teams = [
  'engineering',
  'finance',
  'legal',
  'marketing',
  'mortgage',
  'operations',
  'product',
  'revenue',
  'sales',
  'miscellaneous',
];
const teamsPaths = teams.join('|');

/**
 * These paths will use the general SEOTool component with no additional
 * configuration required.  The SEO Homepage and SEO Article paths will
 * include the SEOTool on their own and provide custom open graph configuration
 */
const seoToolRenderPaths = [
  '/',
  '/our-story',
  '/agent-teams',
  '/agent-teams/faq/:id?',
  'careers',
  '/media-room', '/media-room/:id', '/media-room/:id/:pageNumber',
  '/press/:id', '/article/:pressId/:articleId', '/privacy',
  '/terms-and-conditions', '/broker-licenses', '/jobs',
  `/jobs/:id(${teamsPaths})`,
  '/media-kit',
  '/press-resources',
  '/ojo-in-the-news',
  '/homebuyer',
  '/partnerships',
];

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={WebsiteTheme}>
        <StoreProvider>
          <Router>
            <ScrollToTop>
              <StyledAppContainer>
                <Switch>
                  {seoToolRenderPaths.map(path => <Route path={path} key={path} component={SeoTool} />)}
                </Switch>
                <Switch>
                  <Route exact path='/whatisojo' render={props => <Header hideNavigation {...props} />} />
                  <Route component={Header} />
                </Switch>
                <Switch>
                  <Route exact path='/' component={Home} />
                  <Route path='/partnerships' component={Partnerships} />
                  <Redirect from='/signup' to='/' />
                  <Route path='/our-story' component={AboutUs} />
                  <Redirect exact from='/our-company' to='/our-story' />
                  <Redirect exact from='/about' to='/our-story' />
                  <Route path='/homebuyer' component={HomeBuyersPage} />
                  <Redirect exact from='/homebuyers' to='/homebuyer' />
                  <Route path='/homeowner' component={HomeownerPage} />
                  <Route path='/home-seller' component={HomesellerPage} />
                  <Redirect exact from='/agent-brokers' to='/agent-teams' />
                  <Redirect exact from='/agent-brokers/faq' to='/agent-teams/faq' />
                  <Redirect from='/agent-brokers/faq/:id' to='/agent-teams/faq/:id' />
                  <Redirect from='/agents' to='/agent-teams' />
                  <Route exact path='/agent-teams' element={<AgentRedirect />} />
                  <Route
                    path='/agent-teams/faq/:id?'
                    render={props => <FaqPage
                      source='agent-teams'
                      prependPath='/agent-teams'
                      {...props}
                    />}
                  />
                  <Route path='/agent-application' component={AgentApplication} />
                  <Route path='/broker-licenses' component={BrokerLicensesPage} />
                  <Route path='/careers' component={Careers} />
                  <Route path='/media-room' component={MediaRoom} />
                  <Redirect from='/media-kit' to='/media-room' />
                  <Redirect exact from='/press' to='/media-room' />
                  <Redirect from='/press/company-news' to='/company-news' />
                  <Route exact path='/company-news' component={CompanyNews} />
                  <Route path='/company-news/:pageNumber' component={CompanyNews} />
                  <Redirect exact from='/press-resources' to='/company-news' />
                  <Redirect from='/press-resources/:pageNumber' to='/company-news/:pageNumber' />
                  <Route path='/press/:id' component={ArticleListPage} />
                  <Route path='/ojo-in-the-news' component={NewsFeed} exact />
                  <Route path='/ojo-in-the-news/:pageNumber' component={NewsFeed} />
                  <Redirect exact from='/newsfeed' to='/ojo-in-the-news' />
                  <Redirect exact from='/media-coverage' to='/ojo-in-the-news' />
                  <Redirect from='/media-coverage/:pageNumber' to='/ojo-in-the-news/:pageNumber' />

                  <Route path='/article/:pressId/:articleId' component={ArticleDetail} />
                  <Route exact path='/terms-and-conditions' component={TermsAndConditionsPage} />
                  <Route exact path='/terms-and-conditions/proplus' component={TermsAndConditionsProPlusPage} />
                  <Route exact path='/privacy' component={PrivacyPage} />
                  
                  <Redirect from='/conversion-boost' to='/agent-teams' />

                  <Route exact path='/resources' component={SEOHomepage} />
                  <Route path='/resources/:articleId' component={SEOArticle} />

                  <Route exact path={`/jobs/:id(${teamsPaths})`} component={JobsPage} />
                  <Redirect from='/jobs/:id' to='/jobs' />
                  <Route path='/jobs' component={JobsPage} />

                  <Redirect from='/teams' to='/careers' />

                  <Route from='/login' component={() => {
                    window.location.replace(LOGIN_URL);
                    return null;
                  }} />

                  <Route exact path='/whatisojo' component={WhatIsOjo} />
                  {/*<Route exact path={`/faq/:id(${Object.keys(faqSections).join('|')})`} component={FaqPage} />
                  <Redirect from='/faq/:id' to='/faq' />
        <Route path='/faq' component={FaqPage} />*/}
                  <Route component={NotFoundPage} />

                </Switch>
                <Footer />
                <FixedFooter isMobile={Store.isMobile} />
              </StyledAppContainer>
            </ScrollToTop>
          </Router>
        </StoreProvider>
      </ThemeProvider>
    );
  }
}
function AgentRedirect() {
  // using window.location.href 
  window.location.href = 'https://agents.movoto.com/movoto-programs';
  return null;
}
export default App;
