import React from "react";
import {
  StyledPageWrapper,
  StyledHollowPageWrapper,
} from "./pageWrapper/styled";

const PageWrapper = ({ children, center, backgroundColor, marginBottom}) => (
  <StyledPageWrapper center={center} backgroundColor={backgroundColor} marginBottom={marginBottom}>
    <div className="page-wrapper__container">{children}</div>
  </StyledPageWrapper>
);

export const HollowPageWrapper = ({ backgroundColor, children }) => (
  <StyledHollowPageWrapper backgroundColor={backgroundColor}>{children}</StyledHollowPageWrapper>
);

export default PageWrapper;
