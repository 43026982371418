import { deviceScreenSizeQuery } from "components/theme/index";
import styled from "styled-components";

export const StyledTestimonialBubble = styled.div`
  position: relative;
  padding-left: 45.5px;
  padding-top: 10px;
  text-align: left;

  @media ${deviceScreenSizeQuery.tablet} {
    min-width: 40%;
    max-width: 500px;
    flex: 1;
  }

  :before {
    content: "“";
    font-family: ${({theme}) => theme.fonts.primary};
    font-size: 4.75rem;
    line-height: 5.75rem;
    color: ${({theme}) => theme.colors.emerald};
    position: absolute;
    left: 0;
    top: 0;
  }

  div.testimonial__quotation,
  .testimonial__quotation {
    font-size: 1.5rem;
    line-height: 2rem;
    font-family: ${({theme}) => theme.fonts.primary};
    margin-bottom: 16px;
    text-align: left;

    @media ${deviceScreenSizeQuery.tablet} {
      font-size: 1.625rem;
      line-height: 2.375rem;
    }
  }

  .testimonial__name {
    font-size: 1.375rem;
    line-height: 1.75rem;
    color: ${({theme}) => theme.colors.emerald};
    font-weight: ${({theme}) => theme.fonts.weight.bold};
    text-align: left;
  }
`;