import styled, { css } from "styled-components";
import { deviceScreenSizeQuery } from "components/theme/index";

export const StyledFeaturedSection = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: ${({ theme, backgroundColor }) =>
    theme.colors[backgroundColor]};

  @media ${deviceScreenSizeQuery.laptop} {
    background-color: transparent;
  }

  .featured-section__container {
    padding: 100px 38px;
    max-width: 1304px;

    ${({ backgroundColor }) =>
      backgroundColor &&
      css`
        background-color: ${({ theme }) => theme.colors[backgroundColor]};
      `}

    ${({ small }) =>
      small &&
      css`
        border-radius: 20px;
        margin: 38px 38px 100px;
      `}

    @media ${deviceScreenSizeQuery.mobileS} {
      border-radius: 0px;
      margin: 38px 0px 100px;
    }

    @media ${deviceScreenSizeQuery.tablet} {
      padding: 80px 68px 100px;
      border-radius: 20px;
      margin: 0 68px;
      max-width: 1440px;

      ${({ small }) =>
        small &&
        css`
          margin: 0;
          max-width: 1072px;
        `}

      ${({ marginBottom }) =>
        marginBottom &&
        css`
          margin-bottom: ${marginBottom}px;
        `}
    }

    @media ${deviceScreenSizeQuery.laptop} {
      ${({ marginTop }) =>
        marginTop &&
        css`
          margin-top: ${marginTop}px;
        `}
    }
  }

  .featured-section__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  .featured-section__header {
    margin-left: auto;
    margin-right: auto;
  }

  .featured-section__wrapper {
    margin-left: auto;
    margin-right: auto;
    font-size: 1.375rem;
    line-height: 2rem;

    ul {
      padding-left: 1.375rem;
    }

    div,
    p,
    strong,
    ul li {
      font-size: 1.125rem;
      line-height: 1.625rem;
    }

    ${({ underlined }) =>
      underlined &&
      css`
        :last-child {
          padding-bottom: 60px;
          border-bottom: 1px solid ${({ theme }) => theme.colors.shark};
        }
      `}

    @media (min-width: 768px) {
      margin: 0 auto;
    }

    @media (min-width: 1215px) {
      margin: 0 auto;
      max-width: 1304px;
      width: 100%;
    }

    ${({ negative }) =>
      negative &&
      css`
        color: ${({ theme }) => theme.colors.white};

        p,
        strong,
        ul li {
          color: ${({ theme }) => theme.colors.white};
        }
      `}

    ${({ small }) =>
      small &&
      css`
        text-align: center;

        img {
          max-width: 100%;
        }
      `}
  }

  .featured-section__title {
    font-size: 2.75rem;
    line-height: 3.25rem;
    margin-bottom: 20px;
    text-align: center;
    color: ${({ theme }) => theme.colors.emerald};

    @media ${deviceScreenSizeQuery.tablet} {
      font-size: 3.25rem;
      line-height: 4.25rem;
    }

    ${({ negative }) =>
      negative &&
      css`
        color: ${({ theme }) => theme.colors.seafoam};
      `}

    ${({ center }) =>
      center &&
      css`
        @media ${deviceScreenSizeQuery.tablet} {
          text-align: center;
        }
      `}
  }

  .featured-section__description {
    font-size: 1.125rem;
    line-height: 1.625rem;
    margin-bottom: 30px;
    max-width: 1080px;

    ${({ negative }) =>
      negative &&
      css`
        color: ${({ theme }) => theme.colors.white};
      `}

    ${({ center }) =>
      center &&
      css`
        @media ${deviceScreenSizeQuery.tablet} {
          margin-left: auto;
          margin-right: auto;
          text-align: center;
        }
      `}
  }
`;
