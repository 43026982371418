import { deviceScreenSizeQuery } from 'components/theme/index';
import styled from 'styled-components';

export const StyledFaqFilter = styled.div`
  padding: 20px 0;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: flex-start;

  .faq-filter__label {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }

  @media ${deviceScreenSizeQuery.tablet} {
    padding: 30px 0;
  }
`;