import React from "react";

import { getBrokerLicenses } from "util/contentfulApi";
import useDocumentTitle from "hooks/documentTitle";
import useMetaDescription from "hooks/metaDescription";
import PageWrapper from "components/layout/PageWrapper";
import PageHeader from "components/layout/PageHeader";
import TextBlock from "components/layout/TextBlock";

const BrokerLicensesPage = () => {
  useDocumentTitle("OJO State Real Estate Broker Licenses");
  useMetaDescription(
    "State real estate broker licenses held by OJO."
  );

  const [licenses, setLicenses] = React.useState([]);

  React.useEffect(() => {
    getBrokerLicenses().then((licenses) => {
      setLicenses(licenses);
    });
  }, []);

  return (
    <PageWrapper>
      <PageHeader
        supertext="Broker Licenses"
        title="State Real Estate Broker Licenses"
      />
      <TextBlock>
        <p>Unless indicated otherwise, all licenses held by OJO Home LLC.</p>
        <table>
          <tbody>
            {licenses.map((license, index) => (
              <tr key={index}>
                <td className="state">{license.state}</td>
                <td
                  dangerouslySetInnerHTML={{ __html: ` ${license.license}` }}
                ></td>
              </tr>
            ))}
          </tbody>
        </table>
      </TextBlock>
    </PageWrapper>
  );
};

export default BrokerLicensesPage;
