import styled, { css } from "styled-components";
import { deviceScreenSizeQuery } from "components/theme/index";

export const StyledTextBlock = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.night};

  & + * {
    margin-top: 60px;
  }

  a {
    color: ${({ theme }) => theme.colors.raspberry};
  }

  strong {
    font-weight: ${({ theme }) => theme.fonts.weight.semibold};
  }

  ul {
    list-style: outside;
    padding-left: 1rem;

    li {
      position: relative;
      padding-left: 0;
    }

    .no-decoration {
      list-style-type: none;
      list-style: none;
      padding-left: 0;
    }
  }

  table {
    td {
      font-size: 1rem;
      line-height: 1.5rem;
      padding-top: 10px;
    }

    td.state {
      font-weight: ${({theme}) => theme.fonts.weight.bold};
      vertical-align: top;
      padding-top: 10px;
      padding-right: 1rem;
    }
  }

  .highlight {
    color: ${({ theme }) => theme.colors.emerald};
  }

  ${({ featured }) =>
    featured &&
    css`
      color: ${({ theme }) => theme.colors.emerald};
    `}

  @media ${deviceScreenSizeQuery.tablet} {
    ul {
      li {
        padding-left: 21px;
      }
    }
  }
`;
