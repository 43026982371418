import React, { useState } from "react";
import PropTypes from "prop-types";

import { ReactComponent as ChevronRight } from "assets/svg/chevron-right.svg";

import { StyledExpandableList, StyledExpandableListItem } from "./ExpandableList/styled";
import TextBlock from "components/layout/TextBlock";
import WebsiteTheme from "components/theme/index";

const ExpandableList = ({ entries }) => {
  const [expandedIndexes, setExpandedIndexes] = useState({ 0: true });

  return (
    <StyledExpandableList>
      {entries.map(({ text, title }, index) => {
        return (
          <StyledExpandableListItem expanded={expandedIndexes[index]}
            role="button"
            onClick={() => {
              const newExpandedIndexes = {
                ...expandedIndexes,
              };
              newExpandedIndexes[index] = !newExpandedIndexes[index];
              setExpandedIndexes(newExpandedIndexes);
            }}
            key={title}
          >
            <div className="expandable-list__item-title">
              {title}
              <span className="expandable-list__item-icon">
                <ChevronRight fill={WebsiteTheme.colors.raspberry} />
              </span>
            </div>
            <div className="expandable-list__item-content">
              <TextBlock>{text}</TextBlock>
            </div>
          </StyledExpandableListItem>
        );
      })}
    </StyledExpandableList>
  );
};

ExpandableList.propTypes = {
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.any,
      title: PropTypes.string,
    })
  ).isRequired,
};

export default ExpandableList;
