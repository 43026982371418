import styled from "styled-components";
import heroImg from "../../assets/img/heroimg.jpg";
import { deviceScreenSizeQuery } from "components/theme/index";


const StyledHero = styled.section`
  position: relative;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.concrete};
  margin-bottom: 60px;
  position: relative;
  padding: 0;
  min-height: 77.2vh;
  border-radius: 0 0 20px 20px;
  display: flex;
  align-items: flex-end;

  @media ${deviceScreenSizeQuery.tablet} {
    min-height: 85vh;
    padding: 140px 68px 0;
    margin-bottom: 20px;
  }

  .hero__img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    min-height: 60vh;
    background-image: url(${heroImg});
    background-size: cover;
    background-position: top center;
    border-radius: 0 0 20px 20px;

    @media ${deviceScreenSizeQuery.tablet} {
      min-height: 75vh;
    }
  }

  .hero__container {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    width: 100%;
    margin: 0 auto;
    background-color: ${({ theme }) => theme.colors.emerald};
    box-sizing: border-box;
    padding: 40px 38px;
    z-index: 2;

    @media ${deviceScreenSizeQuery.tablet} {
      max-width: 1304px;
      padding: 40px 112px;
    }
  }

  .hero__cta {
    margin-top: 2.5rem;
  }

  .hero__title {
    font-size: 2.75rem;
    line-height: 3rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 24px;
    color: ${({ theme }) => theme.colors.seafoam};
    font-family: ${({ theme }) => theme.fonts.primary};
    box-sizing: border-box;

    @media ${deviceScreenSizeQuery.tablet} {
      padding: 0 40px;
    }

    @media ${deviceScreenSizeQuery.laptop} {
      font-size: 3.25rem;  
      line-height: 4rem;
      margin-bottom: 40px;
    }

    @media ${deviceScreenSizeQuery.desktop} {
      font-size: 3.25rem;  
      line-height: 4rem;
    }
  }
`;

export { StyledHero };
