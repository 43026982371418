import styled, { css } from "styled-components";
import { deviceScreenSizeQuery } from "components/theme/index";

export const StyledHorizontalScrollBlock = styled.div`
  width: 100%;
  overflow: hidden;
  padding-bottom: 44px;

  .separator {
    padding-left: 40px;
  }

  .react-horizontal-scrolling-menu--item {
    flex-basis: 0;
    flex-grow: 1;
    user-select: none;

    /* TODO: Improve selectors */
    > div > div > *:last-child {
      margin-top: 40px;
    }
  }

  .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
  }

  .react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`;

export const StyledHorizontalScroll = styled.div`
  display: block;
  
  .horizontal-scroll__mask {
    width: 100%;
    padding-top: 40px;
    padding-bottom: 20px;
  }

  .horizontal-scroll__wrapper {
    display: flex;
    height: max-content;
    overflow-y: visible;
    overflow-x: scroll;
    width: 100%;
    flex-direction: row;
    gap: 20px;
    padding-bottom: 20px;

    @media ${deviceScreenSizeQuery.tablet} {
      gap: 40px;
    }

    ${({ paddingTop }) => paddingTop && css`
      @media ${deviceScreenSizeQuery.tablet} { 
        padding-top: ${paddingTop}px;
      }
    `}
  }

  ${({ double }) =>
    double &&
    css`
      .column-wrap {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
      }

      @media ${deviceScreenSizeQuery.tablet} {
        .column-wrap {
          gap: 40px;
        }
      }
    `}
`;
