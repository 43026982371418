import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleDown,
  faAngleUp,
  faArrowCircleRight,
  faArrowRight,
  faBars,
  faBone,
  faCalendar,
  faCar,
  faCheck,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faClock,
  faComment,
  faCommentAlt,
  faDollarSign,
  faDumbbell,
  faGamepad,
  faHandshake,
  faHandsHelping,
  faLaptop,
  faMapMarkerAlt,
  faPlus,
  faProjectDiagram,
  faSearch,
  faShieldAlt,
  faSign,
  faStar,
  faSun,
  faTag,
  faUserCircle,
  faTimes,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';

library.add(faAngleDown);
library.add(faAngleUp);
library.add(faArrowCircleRight);
library.add(faArrowRight);
library.add(faBars);
library.add(faBone);
library.add(faCalendar);
library.add(faCar);
library.add(faCheck);
library.add(faCheckCircle);
library.add(faChevronLeft);
library.add(faChevronRight);
library.add(faClock);
library.add(faComment);
library.add(faCommentAlt);
library.add(faDollarSign);
library.add(faDumbbell);
library.add(faGamepad);
library.add(faHandshake);
library.add(faHandsHelping);
library.add(faLaptop);
library.add(faMapMarkerAlt);
library.add(faPlus);
library.add(faProjectDiagram);
library.add(faSearch);
library.add(faShieldAlt);
library.add(faSign);
library.add(faStar);
library.add(faSun);
library.add(faTag);
library.add(faTimes);
library.add(faUserCircle);
library.add(faWrench);
