import React from "react";
import { StyledPaginator } from "./paginator/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPaginate from "react-paginate";
import Media from "react-media";
import { MOBILE_MEDIA_QUERY } from "util/constants";

const Paginator = ({ totalPages, pageNumber, onPageChange }) => (
  <StyledPaginator>
    <div className="paginator__wrapper">
      {totalPages > 1 && (
        <Media query={MOBILE_MEDIA_QUERY}>
          {(matches) => (
            <ReactPaginate
              initialPage={pageNumber}
              containerClassName={""}
              onPageChange={onPageChange}
              pageCount={totalPages}
              previousLabel={
                <span>
                  <FontAwesomeIcon icon="chevron-left" />
                  Previous
                </span>
              }
              nextLabel={
                <span>
                  Next
                  <FontAwesomeIcon icon="chevron-right" />
                </span>
              }
              breakLabel={"..."}
              pageRangeDisplayed={matches ? 2 : 5}
              marginPagesDisplayed={matches ? 0 : 2}
            />
          )}
        </Media>
      )}
    </div>
  </StyledPaginator>
);

export default Paginator;
