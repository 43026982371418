import React from "react";

import data from "util/data";
import useDocumentTitle from "hooks/documentTitle";
import useMetaDescription from "hooks/metaDescription";
import useMetaKeywords from "hooks/metaKeywords";

import BigTextTile from "components/tiles/BigTextTile";
import Button from "components/forms/Button";
import PageWrapper from "components/layout/PageWrapper";
import PageFeature from "components/layout/PageFeature";
import careersHeroImage from "../assets/img/careers-hero.jpg";
import Section from "components/Section";
import CoreValuesContainer from "components/layout/CoreValuesContainer";
import CheckList from "components/CheckList";
import TeamsShowcase from "components/TeamsShowcase";

const Careers = () => {
  useDocumentTitle(
    "Learn about careers at OJO"
  );
  useMetaDescription(
    "Learn more about our award-winning culture, career opportunities, and find the role that’s right for you."
  );
  useMetaKeywords("Careers at OJO");

  return (
    <>
      <PageWrapper backgroundColor="emerald">
        <PageFeature
          supertext="Careers"
          title="We’re innovators, collaborators, and specialists"
          description="Creating a better path toward successful homeownership starts with an exceptional team to pave it."
          image={careersHeroImage}
          ctaLink="/jobs"
          ctaText="View Current Openings"
          negative
        />
      </PageWrapper>
      <Section center title="Our Core Values">
        <CoreValuesContainer>
          {data.coreValues.map((value) => (
            <BigTextTile
              key={value.header}
              header={value.header}
              text={value.text}
              image={value.image}
            />
          ))}
        </CoreValuesContainer>
      </Section>
      <Section
        center
        title="Benefits &amp; Perks"
        description="Our greatest investment is our people and we are dedicated to supporting our team."
      >
        <CheckList items={data.benefits} />
      </Section>
      <TeamsShowcase 
        desktopTitle="Explore our teams"
        title="Small teams, big  talent" 
      />      
      <Section
        title="Want to join the team?"
        description="Browse our current openings to see if there’s a role for you."
        center
        negative
        backgroundColor="forest"
        footer={
          <Button primary negative to="/jobs">
            View Current Openings
          </Button>
        }
      ></Section>
    </>
  );
};

export default Careers;
