import React, { useState } from "react";
import PropTypes from "prop-types";

import omit from "lodash/omit";

import useDocumentTitle from "hooks/documentTitle";
import useMetaDescription from "hooks/metaDescription";
import faqConfig from "util/data/faq";

import ExpandableList from "components/interactive/ExpandableList";
import PageWrapper from "components/layout/PageWrapper";
import PageHeader from "components/layout/PageHeader";
import FaqSection from "components/FaqSection";
import FaqFilter from "components/FaqFilter";

const renderFaqSection = (sectionId, options, sections) => {
  const label = options.find((o) => o.id === sectionId).label;
  const questions = sections[sectionId];

  return (
    <FaqSection key={sectionId} title={label}>
      <ExpandableList
        entries={questions.map((q) => ({
          title: q.question,
          text: <div dangerouslySetInnerHTML={{ __html: q.answer }} />,
        }))}
      />
    </FaqSection>
  );
};

const FaqPage = ({ history, match, prependPath, source }) => {
  const {
    defaultHighlight,
    options,
    sections,
    title,
    pageTitle,
    pageMetaDescription,
  } = faqConfig[source];
  useDocumentTitle(pageTitle);
  useMetaDescription(pageMetaDescription);

  const [selectedSection, setSelectedSection] = useState(
    match.params.id || "all"
  );

  const showAllMode = selectedSection === "all";

  const highlightedSection = showAllMode ? defaultHighlight : selectedSection;
  const otherSections = omit(sections, highlightedSection);

  return (
    <PageWrapper center backgroundColor="white">
      <PageHeader title={title} />
      <FaqFilter options={options} selectedSection={selectedSection} onSelect={(id) => {
            if (id !== selectedSection) {
              setSelectedSection(id);
              if (id === "all") {
                history.replace(`${prependPath}/faq`);
              } else {
                history.replace(`${prependPath}/faq/${id}`);
              }
            }
          }} />
     

      <div>
        {renderFaqSection(highlightedSection, options, sections)}
      </div>
      {/*showAllMode && <ContentDivider
        title='Are you a real estate agent?'
        description='Find out how OJO can help you.'
        buttonText='See how agents use OJO'
        className={classes.contentDivider}
      />*/}
      {showAllMode && (
        <div>
          {Object.keys(otherSections).map((key) =>
            renderFaqSection(key, options, sections)
          )}
        </div>
      )}
      {/*<ContentDivider
        title={<div className={classes.shortTitle}>Have questions? OJO has answers.</div>}
        description={<div className={classes.shortDescription}>
          Feel free to call our support line at
          <span className={classes.highlight}> (512) 456-8292</span>,
          or email us at <Link to='mailto:support@ojolabs.com'>support@ojolabs.com</Link>.
          </div>}
      />*/}
    </PageWrapper>
  );
};

FaqPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  }).isRequired,
  prependPath: PropTypes.string,
  source: PropTypes.string,
};

FaqPage.defaultProps = {
  prependPath: "",
  source: "default",
};

export default FaqPage;
