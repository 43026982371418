import React from "react";
import { StyledArticleMediaWrapper } from "./articleMediaWrapper/styled";

const ArticleMediaWrapper = ({ children }) => (
  <StyledArticleMediaWrapper>
    { children }
  </StyledArticleMediaWrapper>
);

export default ArticleMediaWrapper;
