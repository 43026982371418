import React from "react";

import useDocumentTitle from "hooks/documentTitle";
import useMetaDescription from "hooks/metaDescription";
import useMetaKeywords from "hooks/metaKeywords";

import Button from "components/forms/Button";
import PageWrapper from "components/layout/PageWrapper";
import PageFeature from "components/layout/PageFeature";
import Section from "components/Section";
import BrandTile from "components/tiles/BrandTile";
import partnershipsHeroImage from "assets/img/partnerships-hero.jpg";

const Partnerships = () => {
  useDocumentTitle(
    "Scale your reach by partnering with OJO"
  );
  useMetaDescription(
    "Work with OJO and reach millions of homebuyers, sellers, and owners through customizable offerings."
  );
  useMetaKeywords(
    "OJO"
  );

  return (
    <>
      <PageWrapper backgroundColor="emerald">
        <PageFeature
          supertext="Partnerships"
          title="Scale your consumer reach with OJO"
          description="OJO offers a variety of customizable solutions that enable partners to effectively connect with homebuyers, sellers, and owners in need of their services."
          image={partnershipsHeroImage}
          ctaLink="mailto:mike@ojo.com"
          ctaLinkProps={{ target: "_blank", rel: "noopener noreferer" }}
          ctaText="Contact Us"
          negative
        />
      </PageWrapper>
      <Section>
        <BrandTile
          headerTitle="Reach millions of homebuyers, sellers, and owners"
          headerImage="/img/Provider_Relationships@2x.png"
          description="Introduce your products and services to OJO consumers right when they need them in their real estate journey and access detailed insights that can inform your engagement strategies."
        />
        <BrandTile
          reversed
          headerTitle="Customized solutions for your website "
          headerImage="/img/Affiliate_Marketing@2x.png"
          description="Monetize your site traffic and offer immediate value to your customers with seamless content and listing data integrations."
        />
      </Section>
      <Section
        title="Partner with us and grow your reach"
        description="If you've got questions about joining OJO, or just want to learn more about the company, send us a note. "
        negative
        backgroundColor="forest"
        split
      >
        <Button
          primary
          negative
          to="mailto:mike@ojo.com"
          linkProps={{ target: "_blank", rel: "noopener noreferer" }}
        >
          Contact Us
        </Button>
      </Section>
    </>
  );
};

export default Partnerships;
