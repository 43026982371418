import 'react-app-polyfill/ie11';
import React from 'react';
import { hydrate, render } from "react-dom";
import './index.css';
import App from './App';
import QueryString from 'query-string';
import * as serviceWorker from './serviceWorker';

// stash query params in a cookie
if (window.location.search) {
  const queryParams = QueryString.parse(window.location.search);
  window.localStorage.setItem('initialParams', JSON.stringify(queryParams));
}

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
