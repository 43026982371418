import React from "react";
import { StyledSection } from "./section/styled";

const Section = ({
  title,
  description,
  center,
  negative,
  underlined,
  children,
  footer,
  backgroundColor,
  split,
  marginBottom,
  maxWidth,
  video,
  fullWidth
}) => (
  <StyledSection
    negative={negative}
    center={center}
    underlined={underlined}
    backgroundColor={backgroundColor}
    split={split}
    marginBottom={marginBottom}
    maxWidth={maxWidth}
    video={video}
    fullWidth={fullWidth}
  >
    {split && (
      <>
        <div className="section__wrapper">
          <div className="section__split">
            {title && <h2 className="section__title">{title}</h2>}
          </div>
          <div className="section__split">
            {description && (
              <p className="section__description">{description}</p>
            )}
            <div className="section__wrapper">{children}</div>
            {footer && <div className="section__footer">{footer}</div>}
          </div>
        </div>
      </>
    )}
    {!split && (
      <>
        {title && (
          <div className="section__header">
            <h2 className="section__title">{title}</h2>
            {description && (
              <p className="section__description">{description}</p>
            )}
          </div>
        )}
        <div className="section__wrapper">{children}</div>
        {footer && <div className="section__footer">{footer}</div>}
      </>
    )}
  </StyledSection>
);

export default Section;
