import styled, { css } from "styled-components";
import { deviceScreenSizeQuery } from "components/theme/index";

export const StyledBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding-right: 0;
  box-sizing: border-box;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.night};

  ${({ negative }) => negative && css`
    border-color: ${({ theme }) => theme.colors.seafoam};
    `}

  .block__title {
    font-family: ${({ theme }) => theme.fonts.primary};
    margin-bottom: 10px;
    font-size: 2rem;
    line-height: 3rem;
    color: ${({ theme }) => theme.colors.emerald};

    ${({ negative }) => negative && css`
      color: ${({ theme }) => theme.colors.seafoam};
    `}
  }

  .block__content {
    padding: 40px;
    display: flex;
    flex-direction: column;
    background-color: transparent;
  }

  .block__description { 
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 40px;

    ${({ negative }) => negative && css`
      color: ${({ theme }) => theme.colors.white};
    `}
  }

  @media ${deviceScreenSizeQuery.tablet} {
    max-width: 408px;
  }
`;
