import React, { useEffect, useState } from "react";

import useDocumentTitle from "hooks/documentTitle";
import useMetaDescription from "hooks/metaDescription";
import useMetaKeywords from "hooks/metaKeywords";
import { getPressList } from "util/contentfulApi";
import ArticleBrief from "components/layout/articles/ArticleBrief";
import PageWrapper from "components/layout/PageWrapper";
import PageHeader from "components/layout/PageHeader";
import PageFooter from "components/layout/PageFooter";
import Paginator from "components/Paginator";
import Button from "components/forms/Button";

const PAGE_SIZE = 20;

const breadcrumbsLinks = [{
  to: "/media-room",
  text: "Media Room",
}];

const NewsFeed = ({ history, match }) => {
  useDocumentTitle("The latest media coverage of OJO");
  useMetaDescription("Read the latest OJO media coverage and industry news.");
  useMetaKeywords("Media coverage");

  const [articles, setArticles] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const pageNumber = match.params.pageNumber
    ? Number.parseInt(match.params.pageNumber, 10)
    : 0;

  useEffect(() => {
    getPressList("feed", PAGE_SIZE, pageNumber * PAGE_SIZE).then((data) => {
      data.articles.forEach((art, index) => {
        art.backupImage = `/img/placeholders/newsfeed_placeholder_${
          (index % 4) + 1
        }.png`;
      });
      setArticles(data.articles);
      setTotalPages(Math.ceil(data.total / PAGE_SIZE));
      setLoaded(true);
    });
  }, [match.params.id, pageNumber]);

  return (
    <PageWrapper center backgroundColor="white">
      <PageHeader breadcrumbsLinks={breadcrumbsLinks} title="OJO in the News" />
      <div>
        {!loaded && <div>Loading...</div>}
        {articles.map((article) => (
          <ArticleBrief {...article} size="wide" showImage showInfo key={article.id} />
        ))}
      </div>
      <PageFooter>
        <Paginator
          totalPages={totalPages}
          pageNumber={pageNumber}
          onPageChange={(data) => {
            if (data.selected !== pageNumber) {
              history.push(`/ojo-in-the-news/${data.selected}`);
            }
          }}
        />
        <Button secondary solid to="/media-room">Return to Media Room</Button>
      </PageFooter>
    </PageWrapper>
  );
};

export default NewsFeed;
