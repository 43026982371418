export function getIFrameUrl(localStorageKey, betaUrl, defaultUrl) {
  let iframeUrlFromLocalStorage = '';
    try {
      iframeUrlFromLocalStorage = window && window.localStorage && window.localStorage.getItem(localStorageKey)
    } catch(e) {
      // empty
    }

    if (iframeUrlFromLocalStorage) {
      return iframeUrlFromLocalStorage;
    }
    if (window.location.hostname.includes('beta')) {
      return betaUrl;
    }

    return defaultUrl;
}