import React from 'react';
import PropTypes from 'prop-types';

import { StyledTestimonialBubble } from './styled';

const TestimonialBubble = ({ name, quotation }) => {
  return (
    <StyledTestimonialBubble>
      <div className="testimonial__quotation">
        {quotation}”
      </div>
      <div className="testimonial__name">— {name}</div>
    </StyledTestimonialBubble>
  );
}

TestimonialBubble.propTypes = {
  name: PropTypes.string.isRequired,
  quotation: PropTypes.string.isRequired,
};

export default TestimonialBubble;