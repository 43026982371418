import React from "react";
import { StyledFeaturedSection } from "./featuredSection/styled";

const FeaturedSection = ({
  title,
  description,
  center,
  negative,
  children,
  footer,
  backgroundColor,
  marginBottom,
  marginTop,
  small
}) => (
  <StyledFeaturedSection
    negative={negative}
    center={center}
    backgroundColor={backgroundColor}
    marginBottom={marginBottom}
    marginTop={marginTop}
    small={small}
  >
    <div className="featured-section__container">
      <div className="featured-section__wrapper">
        {title && <h2 className="featured-section__title">{title}</h2>}
        {description && (
          <p className="featured-section__description">{description}</p>
        )}
      </div>
      {children && 
        <div className="featured-section__wrapper">{children}</div>
      }
      {footer &&
        <div className="featured-section__footer">{footer}</div>
      }
    </div>
  </StyledFeaturedSection>
);

export default FeaturedSection;
