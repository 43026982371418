import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as ChevronRight } from "assets/svg/chevron-right.svg";
import { StyledSelect } from "./select/styled";

import WebsiteTheme from "components/theme/index";

const Select = ({ onSelect, options, placeholder, selected }) => {
  const selectRef = useRef(null);
  const [expanded, setExpanded] = useState(false);
  const selectedOption = options.find((o) => o.id === selected);

  const handleClick = (e) => {
    if (!selectRef.current.contains(e.target)) {
      setExpanded(false);
    }
  };

  useEffect(() => {
    if (expanded) {
      document.addEventListener("mousedown", handleClick);
    }

    return function cleanup() {
      document.removeEventListener("mousedown", handleClick);
    };
  });

  

  return (
    <StyledSelect ref={selectRef} expanded={expanded}>
      <div
        className="select__selected"
        noHighlight={!selectedOption || selectedOption.id === placeholder}
        onClick={() => setExpanded(true)}
      >
        <span>{selectedOption ? selectedOption.label : placeholder}</span>
        <ChevronRight fill={WebsiteTheme.colors.white} />
      </div>
      <div className="options">
        {options.map(({ id, label }, index) => (
          <div
            className="option"
            data-selected={id === selected && id !== placeholder}
            data-placeholder={id === placeholder}
            onClick={() => {
              setExpanded(false);
              if (id !== placeholder) {
                onSelect(id);
              }
            }}
            key={id}
          >
            {label}
            {id === selected && (
              <span className="checkmark">
                <FontAwesomeIcon icon="check" />
              </span>
            )}
            {/* {index === 0 && (
              <ChevronRight fill={WebsiteTheme.colors.white} className="collapseButton" />
            )} */}
          </div>
        ))}
      </div>
    </StyledSelect>
  );
};

Select.propTypes = {
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  placeholder: PropTypes.string,
  selected: PropTypes.string,
};

Select.defaultProps = {
  options: [],
  placeholder: "",
  selected: "",
};

export default Select;
