import React, { useRef, useEffect, useState } from "react";
import { StyledHorizontalScroll } from "./horizontalScrollBlock/styled";

const HorizontalScroll = ({ itemsList, double, paddingTop }) => {
  const ref = useRef(null);
  const [padding, setPadding] = useState(`68px`);

  const scrollableItemsList = () => {
    if (double) {
      return itemsList.reduce((list, item, index, array) => {
        if (index % 2 === 0) {
          list.push(
            <div className="column-wrap" key={index}>
              {array.slice(index, index + 2)}
            </div>
          );
        }

        return list;
      }, []);
    }

    return itemsList;
  };

  function handleResize() {
    let windowWidth = window.innerWidth;

    if (windowWidth >= 1024 && windowWidth < 1304) {
      windowWidth = window.innerWidth + 136;
    } else if (windowWidth < 1024) {
      windowWidth = window.innerWidth + 76;
    } else {
      windowWidth = 1304;
    }

    const calculatedPadding = Math.abs(
      (ref.current.scrollWidth - windowWidth) / 2
    );

    if (ref.current.scrollWidth && window.innerWidth) {
      setPadding(`${calculatedPadding}px`);
    }

    ref.current.scrollTo(calculatedPadding, 0);
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize(); 
    
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <StyledHorizontalScroll double={double} ref={ref} paddingTop={paddingTop}>
      <div className="horizontal-scroll__mask">
        <div
          className="horizontal-scroll__wrapper"
          style={{ paddingLeft: padding, paddingRight: padding }}
        >
          {itemsList &&
            scrollableItemsList().map((item, index) => (
              <div key={index}>{item}</div>
            ))}
        </div>
      </div>
    </StyledHorizontalScroll>
  );
};

export default HorizontalScroll;
