import styled, { css } from "styled-components";
import { deviceScreenSizeQuery } from "components/theme/index";

export const StyledOfferings = styled.div`
  padding: 40px 38px;
  overflow: hidden;

  @media ${deviceScreenSizeQuery.laptop} {
    padding: 60px 68px;
  }

  .offerings__title {
    font-size: 2.25rem;
    line-height: 2.75rem;
    margin-bottom: 2.5rem;
    color: ${({ theme }) => theme.colors.emerald};
    text-align: center;

    @media ${deviceScreenSizeQuery.laptop} {
      font-size: 2.5rem;
      line-height: 3.75rem;
      margin-bottom: 2rem;
    }
  }

  .offerings__wrapper {
    margin-left: auto;
    margin-right: auto;

    ${({ underlined }) =>
      underlined &&
      css`
        :last-child {
          padding-bottom: 60px;
          border-bottom: 1px solid ${({ theme }) => theme.colors.shark};
        }
      `}

    @media (min-width: 768px) {
      margin: 0 auto;
    }

    @media (min-width: 1215px) {
      margin: 0 auto;
      max-width: 1304px;
      width: 100%;
    }

  }
`;
