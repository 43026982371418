export const jumbotron = [
  {
    video:
      "https://player.vimeo.com/external/345704252.hd.mp4?s=fc7a0f8992830017566a2ccdc548ed62c0882032&profile_id=175",
    mobileVideo:
      "https://player.vimeo.com/external/345704252.sd.mp4?s=d4accb50ada1eb7e18d560679be2f0f970f37f64&profile_id=164",
    image: "/img/Megha_House_Exterior_0322.jpg",
    imageDesktop: "/img/Megha_House_Exterior_0322_852.jpg",
    duration: 8000,
    title: "Discover new homes",
    conversation: [
      {
        text: "This new listing made me think of you. It has your dream kitchen and a fenced in backyard for your dog.",
        fromOjo: true,
        attachments: [
          {
            type: "listing",
            image: `${process.env.PUBLIC_URL}/img/listing_thumb.jpg`,
            price: 475000,
          },
        ],
      },
    ],
  },
  {
    video:
      "https://player.vimeo.com/external/345703675.hd.mp4?s=bbe5a74e4cafb80b0cbe54c9c281783a1c9dd614&profile_id=175",
    mobileVideo:
      "https://player.vimeo.com/external/345703675.sd.mp4?s=54cb7214efaa751fc082988e5c693aaa1921fc41&profile_id=164",
    image: "/img/John_House_Kitchen_0283.jpg",
    imageDesktop: "/img/John_House_Kitchen_0283_852.jpg",
    duration: 8000,
    title: "Get answers fast",
    conversation: [
      {
        text: "How big is the dining room?",
      },
      {
        text: "It's 200 square feet.  Perfect for entertaining!",
        fromOjo: true,
      },
    ],
  },
  {
    video:
      "https://player.vimeo.com/external/345703068.hd.mp4?s=09c4949415794ddaf1b65b8e067c442d3ac4f55e&profile_id=175",
    mobileVideo:
      "https://player.vimeo.com/external/345703068.sd.mp4?s=3575b53bed7e0f5abc762014c888e9963a918046&profile_id=164",
    image: "/img/Mike_House_Dogs_0218.jpg",
    imageDesktop: "/img/Mike_House_Dogs_0218_852.jpg",
    duration: 8000,
    title: "Make smarter decisions",
    conversation: [
      {
        text: "It’s nice, but can I really afford it?",
      },
      {
        text: "It's at the top of your budget, but still within your price range.",
        fromOjo: true,
        attachments: [
          {
            type: "bar-chart",
            data: [
              {
                title: "Mortgage",
                rate: 2677,
                ratePerLabel: "month",
                percentages: [
                  {
                    percent: 0.77,
                    color: "#00D7F1",
                  },
                ],
              },
              {
                title: "Utilities",
                rate: 340,
                ratePerLabel: "month",
                percentages: [
                  {
                    percent: 0.55,
                    color: "#00C853",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    video: `https://player.vimeo.com/external/345702705.hd.mp4?s=f624f993f0c6043e8e847423283eeca3f71c41b1&profile_id=175`,
    mobileVideo:
      "https://player.vimeo.com/external/345702705.sd.mp4?s=87a34e076ee0b9761f9ec7401aca517e4420c73b&profile_id=164",
    image: "/img/Mike_House_DarkKitchen_0053.jpg",
    imageDesktop: "/img/Mike_House_DarkKitchen_0053_852.jpg",
    duration: 8000,
    title: "Stay up-to-date",
    conversation: [
      {
        text: "Great news! The house on Tillery St. is back on the market and predicted to sell quickly. Would you like to see it?",
        fromOjo: true,
      },
      {
        text: "That’s good news, can I see it at 6 PM?",
      },
    ],
  },
  {
    video:
      "https://player.vimeo.com/external/345703279.hd.mp4?s=343b6e18203383364c23864d1f680e23a81adf0e&profile_id=175",
    mobileVideo:
      "https://player.vimeo.com/external/345703279.sd.mp4?s=b5e33e97825f1ec03ba6be8aaf6cb5da944f58ee&profile_id=164",
    image: "/img/Megha_House_Exterior_0381.jpg",
    imageDesktop: "/img/Megha_House_Exterior_0381_852.jpg",
    duration: 8000,
    title: "Connect with an agent",
    conversation: [
      {
        text: "I love all three of these new homes! Can I tour them this weekend?",
      },
      {
        text: "Yes! Let me connect you with an agent who specializes in this area.",
        fromOjo: true,
      },
    ],
  },
];

export const propertyListings = [
  {
    name: "Kyle L.",
    location: "Denver",
    personImage: `${process.env.PUBLIC_URL}/img/user-headshots/Mike_House_Portraits_0008.jpg`,
    features: ["4+ bedrooms", "Ranch-style", "Curb appeal"],
    image: "/img/Mike_House_Kitchen_0510.jpg",
  },
  {
    name: "Paul R.",
    location: "Atlanta",
    personImage: `${process.env.PUBLIC_URL}/img/user-headshots/Megha_House_Portraits_0077.jpg`,
    features: ["Tall ceilings", "Large master bath", "Chef’s kitchen"],
    image: "/img/Megha_House_Salad_0028.jpg",
  },
  {
    name: "Jones Family",
    location: "Philadelphia",
    personImage: `${process.env.PUBLIC_URL}/img/user-headshots/Mike_House_Portraits_0092.jpg`,
    features: ["New construction", "Fenced yard", "Screened-in porch"],
    image: "/img/Mike_House_Swing_0088.jpg",
  },
  {
    name: "Thompson Family",
    location: "Toronto",
    personImage: `${process.env.PUBLIC_URL}/img/user-headshots/Mike_House_MenChillin_0321.jpg`,
    features: ["20 minute commute", "Mayfair Elementary School", "Playroom"],
    image: "/img/Mike_House_Soccer_0072.jpg",
  },
];

export const featuredConversations = [
  {
    type: "chat",
    data: {
      image: "/img/Mike_House_Sprinkler_0182.jpg",
      conversation: [
        {
          text: "What does the HOA fee cover?",
        },
        {
          text: "The HOA fee includes exterior maintenance and lawn care.",
          fromOjo: true,
        },
      ],
    },
  },
  {
    type: "chat",
    data: {
      image: "/img/Megha_House_Bathroom_0033.jpg",
      conversation: [
        {
          text: "What are the estimated property taxes?",
        },
        {
          text: "The estimated property taxes are $4534 annually.",
          fromOjo: true,
        },
      ],
    },
  },
  {
    type: "chat",
    data: {
      image: "/img/John_House_Bedroom_0135.jpg",
      conversation: [
        {
          text: "My kids are going to love this one! What elementary school is the home zoned for?",
        },
        {
          text: "This home is zoned to Sunnyside Elementary School.",
          fromOjo: true,
        },
      ],
    },
  },
];

export const huntSmarterConversations = [
  {
    type: "chat",
    data: {
      image: "/img/Megha_House_Exterior_0120.jpg",
      imageOnRight: true,
      conversation: [
        {
          text: "It’s nice, but can I really afford it?",
        },
        {
          text: "Yes! Mortgage and bills come out to approximately $3017/month.",
          fromOjo: true,
          barChartData: [
            {
              title: "Mortgage",
              rate: 2677,
              ratePerLabel: "month",
              percentages: [
                {
                  percent: 0.77,
                  color: "#00D7F1",
                },
              ],
            },
            {
              title: "Utilities",
              rate: 340,
              ratePerLabel: "month",
              percentages: [
                {
                  percent: 0.55,
                  color: "#00C853",
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    type: "chat",
    data: {
      image: "/img/Mike_House_Playroom_0036.jpg",
      imageOnRight: true,
      conversation: [
        {
          text: "This home just hit the market. It’s two miles outside your preferred area, but matches your preference of four bedrooms and space to grow.",
          fromOjo: true,
          attachments: [
            {
              type: "listing",
              image: "/img/EXTERIOR_64x64.jpg",
              price: 249000,
            },
          ],
        },
      ],
    },
  },
  {
    type: "listing-highlight",
    data: {
      image: "/img/Mike_House_iPad_0016.jpg",
      imageOnRight: true,
      listing: {
        image: "/img/INTERIOR_172x126.jpg",
        price: 372000,
        percentage: 90,
      },
      features: [
        "Two-car garage",
        "Fireplace",
        "Eco-Friendly",
        "Private yard",
        "Enclosed Patio",
      ],
    },
  },
];

export const areaHighlights = [
  {
    button: {
      type: "text",
      text: "Just browsing",
    },
    image: "/img/Mike_House_Dogs_0195.jpg",
    quote: `“You know how sometimes when you’re working with someone, it’s all about business? I didn’t feel that way about OJO. I know this sounds odd, but OJO seems friendly and easy to get along with.”`,
    source: "Marie",
  },
  {
    button: {
      type: "text",
      text: "Moving fast",
    },
    image: "/img/John_House_Kitchen_0059.jpg",
    quote:
      "“The house sold within five days and there were a lot of people looking at it. If I didn't find it with OJO or start the process, we probably wouldn't have gotten this house”",
    source: "Teresa",
  },
];

export const agentInfo = [
  {
    button: {
      type: "text",
      text: "Quality agents",
    },
    type: "chat",
    data: {
      image: "/img/Mike_House_FamilyFirePit_0161.jpg",
      imageOnRight: true,
      conversation: [
        {
          text: "Thanks OJO, love this listing.  Can I see it tomorrow at noon?",
        },
        {
          text: "Sure, one of my team members will be in touch shortly to connect you with a top realtor in the area.",
          fromOjo: true,
        },
      ],
    },
  },
  {
    button: {
      type: "text",
      text: "Trusted brands",
    },
    type: "images",
    data: {
      mainImage: "/img/Megha_House_Kitchen_0019.jpg",
      imageOnRight: true,
      images: [
        {
          url: `${process.env.PUBLIC_URL}/img/3rd-party-logos/partners_cb.png`,
          alt: "Coldwell Banker",
        },
        {
          url: `${process.env.PUBLIC_URL}/img/3rd-party-logos/partners_bhg.png`,
          alt: "Better Homes and Gardens",
        },
        {
          url: `${process.env.PUBLIC_URL}/img/3rd-party-logos/partners_c21.png`,
          alt: "Century 21",
        },
        {
          url: `${process.env.PUBLIC_URL}/img/3rd-party-logos/partners_soth.png`,
          alt: "Sothebys",
        },
        {
          url: `${process.env.PUBLIC_URL}/img/3rd-party-logos/partners_bank.png`,
          alt: "Bankrate",
        },
        {
          url: "/img/3rd-party-logos/rbc-logo.png",
          alt: " RBC Royal Bank",
        },
      ],
    },
  },
];

export const availableAreas = [
  "Atlanta, GA",
  "Austin, TX",
  "Baltimore, MD",
  "Boston, MA",
  "Charlotte, NC",
  "Chicago, IL",
  "Cincinnati, OH",
  "Colorado Springs, CO",
  "Columbus, OH",
  "Dallas, TX",
  "Denver, CO",
  "Fort Myers, FL",
  "Houston, TX",
  "Los Angeles, CA",
  "Miami, FL",
  "Milwaukee, WI",
  "Orlando, FL",
  "Philadelphia, PA",
  "Sacramento, CA",
  "Salt Lake City, UT",
  "San Diego, CA",
  "San Francisco, CA",
  "Sarasota, FL",
  "Tampa, FL",
  "Toronto, ON",
  "Washington, DC",
];

export const comingSoonAreas = [
  "Atlanta, GA",
  "Austin, TX",
  "Baltimore, MD",
  "Boston, MA",
  "Columbus, OH",
  "Denver, CO",
];

const johnBerkowitz = {
  name: "John Berkowitz",
  title: "Chief Executive Officer",
  bio: `An empathy-led mindset has been central to John’s entire entrepreneurial journey. He founded OJO Labs in 2015, fueled by a mission to improve the way people make their most important decisions. Now, OJO aims to level the playing field in real estate so that everyone can experience the security of home.
  <br /><br />
  A central and formative element of John’s childhood was watching his father run their family-owned construction company. So after completing his BA at George Washington University, John set his sights on finding ways to support small businesses in expanding their customer base. That’s when he co-founded and scaled Yodle, an all-in-one digital marketing platform, to more than 50,000 small-business customers with over 1,400 employees and $200 million in revenue. In 2016, Yodle was acquired by Web.com in a $342 million cash transaction.
  <br /><br />
  Despite the ambitious task of growing a startup into a highly impactful, industry-leading company, John still thinks of it like a family business, considering his wife, Alina, and their four sons an extension of his executive board.
  <br /><br />
  More than trying to achieve a work-life balance, John believes in finding a positive way of experiencing both in tandem. He’s dedicated to creating a work environment that blends productivity, employee satisfaction, and impact. In line with those beliefs, OJO has been recognized with multiple workplace awards, including Fortune Best Workplaces in Technology, Inc. Best Workplaces, Austin Business Journal Best Workplaces, Built In Austin’s Best Places to Work, and Great Place to Work’s Best Workplaces in TexasTM.
  <br /><br />
  John has received numerous leadership accolades, including Austin Business Journal’s Best Small Company CEO, Austin Under 40 Award, HousingWire Vanguards, Swanepoel’s SP200, and The Most Powerful Technology Executives in The Residential Real Estate Brokerage Industry. He is also actively involved in nonprofits and currently serves on the board of CASA of Travis County, fighting for the welfare of children.
  <br /><br />
  In his own unique way of blending business, family life, and deeply felt empathy, John does his part to transform industries and set up the next generation of trailblazers.
  `,
  linkedInUrl: "https://www.linkedin.com/in/johnberkowitz/",
};

const imtiyazHaque = {
  name: "Imtiyaz Haque",
  title: "Chief Growth Officer",
  bio: `Equal parts entrepreneur, leader, and product architect, Imtiyaz has an unwavering passion for building and nurturing high-growth products and teams. As CEO at Movoto, he transformed the business from one that was unprofitable, to one of the fastest-growing top-five residential search sites. Since the acquisition of Movoto by OJO Labs in June 2020, Imtiyaz has continued to focus on accelerating consumer acquisition of Movoto products in the U.S., as well as the expansion into the Canadian market with OJO.ca. In October of 2021, he took over responsibilities for revenue generation as Chief Revenue Officer at OJO.
  <br /><br />
  Imtiyaz became CEO at Movoto in January 2017, when it was under Recruit Holdings of Japan. One year later, he was part of the management buyout that relaunched Movoto as an independent startup company. Prior to becoming CEO at Movoto, Imtiyaz served as Vice President of Engineering where he led the company’s global engineering and R&D. In his previous capacity, as Head of API and Innovation, and then Global R&D for Philips, Imtiyaz focused on lean startup principles and agile development methodology to deliver new innovations to the company. He honed his skills in entrepreneurship when he founded and served as CEO at a Java technology company, growing it to more than 100 clients in less than two years and achieving profitability, which led to a successful company acquisition.
  <br /><br />
  With a strong commitment to leadership, Imtiyaz passes on his experience and knowledge to up-and-coming entrepreneurs by mentoring teams at the University of California, San Francisco (UCSF) and UC Berkeley’s Haas School of Business.
  <br /><br />
  Outside of the office, Imtiyaz loves to cook. You can often find him in the company kitchen sharing his homemade culinary delights with his colleagues.
  `,
  linkedInUrl: "https://www.linkedin.com/in/imtiyazhaque/",
};

export const leadership = [
  {
    ...johnBerkowitz,
    image: "/img/headshots/JOHN_BERKOWITZ.jpg",
    linkedInUrl: "https://www.linkedin.com/in/johnberkowitz/",
  },
  {
    image: "/img/headshots/ANGELA_DUNHAM.jpg",
    name: "Angela Dunham",
    title: "Chief Operating Officer",
    bio: `As Chief Operating Officer at OJO Labs, Angela’s presence and positive impact can be felt by everyone at the company, whether in Austin, Minneapolis, San Mateo, or St. Lucia. Angela has more than 20 years of experience building award-winning work cultures, efficient program management processes, learning and development organizations, and developing top talent.
    <br /><br />
    Angela received a BA from the University of Texas at Austin. Over the next decade she grew her career at MCI (now Verizon) where she served in a number of leadership and operational roles that culminated in her leading learning and development for all 18 of the company’s residential customer call centers. She joined David Rubin at Profitfuel in 2008 and was instrumental in the success that led the company to being acquired by Yodle in 2011, where she began working with John Berkowitz. After a successful run at Yodle as SVP of Operations, she served on the executive leadership team for Web.com and ran operations for the company.
    <br /><br />
    She joined OJO as Chief Operating Officer in 2017 and since then has scaled the company’s operations from 20 people in Austin to more than 700 employees across four locations. Notably, she led efforts to set up a facility on the island of St. Lucia that currently has over 500 employees. Angela is passionate about nurturing employees and helping them advance in their careers, as well as giving back to her local community. She is deeply invested in OJO’s Diversity, Equity, and Inclusion task force.
    <br /><br />
    Aside from driving a rapidly growing tech company, Angela loves running, watching her kids play sports, and serving in volunteer leadership roles at her children’s schools. She serves as a mentor for Austin Business Journal events, as well as a mentor to several women in Austin who are growing their careers. Angela remains a strong advocate for the city of Austin, often volunteering in a variety of initiatives and helping to bring new talent and diversity into the blossoming community.
    `,
    linkedInUrl: "https://www.linkedin.com/in/angela-dunham-aa52744/",
  },
  {
    image: "/img/headshots/CHRIS_HELLER.jpg",
    name: "Chris Heller",
    title: "President",
    bio: `As President at OJO Labs, Chris is shaping partner strategies, creating a cohesive structure between real estate professionals and OJO, and accelerating adoption in the industry. He brings deep expertise having held influential industry positions, including CEO at mellohome and former CEO at Keller Williams Realty International (KWRI). Chris earned his real estate license when he was 20-years-old and built one of the most successful real estate teams in the U.S. From Rookie of the Year in 1989 to becoming the top-producing agent in San Diego County and the No. 1 Keller Williams associate in all of North America, Heller has earned the respect of colleagues and clients for the results he delivers.
    <br /><br />
    Under Chris’ leadership, the Chris Heller Real Estate Team sold more than 100 homes a year for almost three decades. He continues to manage an active real estate team working with consumers in San Diego, which to this day is one of the top teams in the country. Named President of KW Worldwide in 2010, Heller launched the company’s first regions outside of North America, leading KW to record productivity and profitability. His vision and leadership helped grow the company into the most dominant worldwide real estate franchise in history. In 2015, Heller was named CEO at KWRI, leading the transformation into the technology company it is today and continuing record growth and profitability.
    <br /><br />
    As CEO at mellohome, Chris set the strategy for cross-functional groups tasked with creating simpler and smarter consumer homebuying, financing, and improvement experiences. Under his leadership, mellohome doubled its growth and drove significant change within the experiential and product landscape for the homeownership industry at large.
    <br /><br />
    Outside of the office, Chris enjoys staying physically and mentally fit through daily exercise and reading, and spending time with his wife and four children.
    `,
    linkedInUrl: "https://www.linkedin.com/in/chiefrealestateofficerojolabs/",
  },
  {
    image: "/img/headshots/JERIMIAH_TAYLOR.jpg",
    name: "Jerimiah Taylor",
    title: "Chief Real Estate Officer",
    bio: `OJO Chief Real Estate Officer Jerimiah Taylor began in the industry in 2001 as a residential loan processor and has since matriculated through the industry to lead some of the largest and most innovative companies in real estate and mortgage. Jerimiah’s career has led him to have three distinct areas of expertise, real estate, mortgage and technology. In addition to serving on the executive leadership team at OJO, he's participated in more than 1,000 residential real estate transactions and has owned/operated brokerages of all sizes and scales. 
    <br /><br />
    In the mortgage space, in addition to experience with individual mortgage transactions as a processor and/or originator, Jerimiah was an EVP at mellohome, for the second largest non-banker lender in the U.S. 
    <br /><br />
    Jerimiah is also serial entrepreneur and product builder. His last technology startup, Brokerkit, scaled from a bootstrap startup to generating more than $1 million ARR, to a successful exit to a private equity firm in just 21 months. 
    <br /><br />
    Outside of work, Jerimiah is a father to 3 daughters and enjoys traveling with his wife who leads their real estate team and real estate investment businesses. Jerimiah has a personal passion for motorsports, enjoys golf and loves to explore and learn about wine.
    `,
    linkedInUrl: "https://www.linkedin.com/in/jtaylorrealtor/",
  },
  {
    image: "/img/headshots/DAVE_ROBINETT.jpg",
    name: "Dave Robinett",
    title: "Chief Legal Officer",
    bio: `As the Chief Legal Officer at OJO Labs, Dave oversees all legal matters for the company, including venture capital and debt financing, acquisitions, partner contracts, intellectual property, employment, and compliance matters.
    <br /><br />
    Dave received a BS in Mathematical Economics from Tulane University in New Orleans, then spent three years teaching high school math in New Orleans and Houston with Teach for America. Knowing he wanted to transition into corporate law, Dave moved to Austin to complete a JD/MBA at The University of Texas at Austin. After finishing law school, Dave was a corporate lawyer in the Austin offices of Hughes & Luce (now K&L Gates), Gunderson Dettmer, and Vinson & Elkins, focusing mainly on software, internet, and other startups. Having worked with startups spanning across a multitude of industries, services, and products, Dave’s versatility and ability to learn quickly about each client enabled him to advise his clients on financing, M&A transactions, and IPOs. Before joining OJO, Dave launched his own firm, where he worked with different clients across the technology spectrum for 10 years.
    <br /><br />
    Dave represented OJO as outside counsel in 2015, at the company’s formation, and came on board full-time in 2019. Dave had previously worked closely with John Berkowitz and David Rubin, helping with the successful sale of David’s company, ProfitFuel, to John’s company, Yodle.
    <br /><br />
    Outside of work, Dave enjoys cooking, traveling with his wife and two college-aged kids, and getting in over his head on home improvement projects.
    `,
    linkedInUrl: "https://www.linkedin.com/in/drobinett/",
  },
  {
    ...imtiyazHaque,
    image: "/img/headshots/HAQUE_IMTIYAZ.jpg",
  },
  {
    image: "/img/headshots/MIKE_RYAN.jpg",
    name: "Mike Ryan",
    title: "Chief Partnership Officer",
    bio: `Armed with strong sales and business development acumen, Mike has been instrumental in forging enterprise partnerships with industry leaders, helping secure millions in investment, and fueling OJO Labs’ ability to quickly become an industry transformer.
    <br /><br />
    Mike received a BA from the University of Colorado at Boulder and shortly thereafter joined John Berkowitz, who was building the online advertising company, Yodle, in Austin, Texas. Mike helped build Yodle’s annual revenues from $2 million in 2009 to over $40 million in 2014. This experience in scaled growth proved to be an excellent foundation for his upcoming journey at OJO.
    <br /><br />
    Mike joined the OJO team in 2015 as one of its earliest employees and has been a key leader in the company’s evolution to date. Mike’s business and customer savviness provides OJO, its investors, and partners with the guidance and transparency needed to scale the product across multiple channels and markets nationwide. With his “win-win-win” philosophy, Mike is a powerful ally and operative who ensures the company maintains a positive forward trajectory.
    <br /><br />
    Mike lives in Austin and when not working, can be found spending time with his family, coaching his two young boys in sports, or enjoying the various outdoor activities that Austin has to offer.
    `,
    linkedInUrl: "https://www.linkedin.com/in/mike-ryan-27185b7/",
  },
  {
    image: "/img/headshots/QINGQING_OUYANG.jpg",
    name: "Qingqing Ouyang",
    title: "Chief Product & Technology Officer",
    bio: `As Chief Product & Technology Officer at OJO Labs, Qingqing is responsible for
    building and leading a talented team to drive innovation for homebuyers.
    As Executive Vice President of Engineering at OJO, she grew the footprint
    of the OJO engineering team globally, scaling from seven local engineers to
    more than 100 around the world, in under two years. Her ability to recruit the
    right talent for the right opportunity, while maintaining a strong engineering
    culture, has significantly increased the company’s ability to bring innovative
    technological solutions to guide consumers through a transparent
    homebuying journey.
    <br /><br />
    Qingqing’s career has spanned two decades in software development,
    holding multiple engineering leadership positions. Prior to joining OJO,
    Qingqing served in senior management roles at Sun Microsystems, Oracle,
    Dell, and Bazaarvoice. She held the head of engineering position at Main
    Street Hub, driving the growth of thousands of small businesses using
    technology-enabled marketing solutions. Main Street Hub was acquired by
    GoDaddy in 2018, with its solutions made available to millions of GoDaddy
    customers. Qingqing is driven by working on meaningful missions with
    talented people who are intelligent, open-minded, and selfless.
    <br /><br />
    She holds bachelor degrees in Mathematics and Computer Science from
    Smith College, as well as a master’s degree in Computer Science from the
    University of Wisconsin at Madison.
    <br /><br />
    Outside of the office, Qingqing enjoys traveling the world with her family. She
    is also actively involved with various volunteering events, including promoting
    STEM initiatives around Austin, which is most dear to her heart.
    `,
    linkedInUrl: "https://www.linkedin.com/in/qouyang/",
  },
];

const boardOfDirectors = [
  {
    ...johnBerkowitz,
    title: "Co-Founder & CEO, OJO Labs",
  },
  {
    name: "Russell Valdez",
    title: "Managing Partner and Co-Founder of Moontower",
    bio: `Russell is Managing Partner and Co-Founder of Moontower. Prior to founding Moontower, Russell was Chief Investment Officer of Wafra (a ~$30 billion AUM investment firm) and Vice Chair of its board of directors. He served on Wafra’s key management committees, and his role encompassed investment leadership, corporate strategy, product/business development, investor relations and legal and operations infrastructure. At Wafra, Russell directly oversaw the financial services and real assets investments strategies. In that capacity, he led all elements of the investment lifecycle, including serving on the boards of various portfolio companies.
    <br /><br />
    Prior to Wafra, Russell practiced corporate and transactional law as an Associate at Covington & Burling LLP, focusing on mergers and acquisitions and private equity. He also held roles at Chemonics International and Visa Europe. Russell is a director on the board at OJO Labs. He is the Chair of the board of CASA (Court Appointed Special Advocates) of Travis County and serves on the board of YPO Austin. Russell received his J.D. from Harvard Law School and his B.A. in History from Stanford University.`,
    linkedInUrl: "https://www.linkedin.com/in/russell-valdez-61442a2/",
  },
  {
    name: "David Rubin",
    title: "Co-Founder, OJO Labs",
    bio: `As co-founder and former Chief Strategy Officer at OJO Labs, David brings over thirty years of entrepreneurial experience building, operating, and scaling successful technology companies. David’s innate ability to create unique and viable businesses through deep insight into industry and customer needs, led to significant leadership roles early in his career. In his first job, David quickly excelled as the top sales agent, convincing the CEO to allow him to set up and run a subsidiary that tapped a different clientele pool. In the four years that David ran the operation, he grew the company’s total revenue from $40 million to over $100 million. David built on what he had learned and began applying it to new business opportunities in the mid-nineties and early 2000s.
    <br /><br />
    Residing in Silicon Valley in the early days of the internet, David was a pioneer in building enterprise software distribution and management systems, through his company BitSource, and attracting clients like Unisys, Symantec, Microsoft and Novell. After his first successful exit, David moved to Austin, Texas, and got right to work doing what he does best - finding existing problems and creating powerful solutions. David founded HomeCity in 2000, focusing on delivering location-based real estate listings, then ProfitFuel in 2002 that helped large brands sell by leveraging large, systematic call centers - then shifted to a powerful SEO model before selling to Yodle in 2011.
    <br /><br />
    At OJO, David was paramount in the company’s ability to establish foreign operations in partnership with local governments and build patented technology that are transforming industries. Outside of launching companies that add value in the world, David enjoys spending time with his family, flying as a fully-licensed pilot, and is heavily involved with CASA, serving on the board to help abused and neglected children.`,
    linkedInUrl: "https://www.linkedin.com/in/david-rubin-a33957/",
  },
  {
    name: "Krishna Srinivasan",
    title: "Co-Founder, LiveOak Venture Partners",
    bio: `Krishna is a co-founder of LiveOak Venture Partners and has been investing
    in early stage Texas-based companies and entrepreneurs since 2000. His
    current and past board involvements at LiveOak include OJO Labs, CS Disco,
    Hive9, Homeward, Imandra, Rollick, Telestax, Digital Pharmacist, Opcity, and
    StackEngine (Nasdaq: ORCL).
    <br /><br />
    Prior to co-founding LiveOak, Krishna was a Partner at Austin Ventures.
    There, he worked with companies in enterprise software and infrastructure
    solutions segments. Before that, Krishna was with Motorola where he wrote
    large-scale optimization software for supply chain planning and worked
    with a variety of business units on strategic and operational issues.
    <br /><br />
    Krishna received his MBA from Wharton where he graduated with the
    highest academic honors as a Palmer Scholar. He holds an MS in Operations
    Research from the University of Texas at Austin, and a BS in Mechanical
    Engineering from the Indian Institute of Technology, having graduated with
    the highest all-round honors.
    <br /><br />
    Krishna currently serves as Chairman of the Miracle Foundation board, an
    Austin-based organization that supports orphanages in India, and is one
    of the founding members and a past board member of TiE Austin. He is also
    a board member of the Entrepreneurs Foundation of Central Texas.`,
    linkedInUrl: "https://www.linkedin.com/in/krishnasrinivasan1/",
  },
  {
    name: "Michael Gordon",
    title: "CFO & COO, MongoDB",
    bio: `Michael brings more than 25 years of financial management and business
    experience to his roles as Chief Operating Officer and Chief Financial
    Officer of MongoDB. Michael has a strong background in finance, strategy,
    and business operations, along with a track record of helping high-growth
    companies scale successfully.
    <br /><br />
    Previously, he was the COO and CFO at Yodle, where he was responsible for
    the strategic and operational management of all aspects of finance and
    business operations, business development, reseller programs, corporate
    development, and legal affairs. Prior to Yodle, Michael was a Managing
    Director in the media and telecom investment banking group at Merrill
    Lynch. While there, he helped raise over $20 billion in capital for clients —
    including many initial public offerings — and advised clients on more than
    $50 billion in mergers and acquisitions transactions. Before Merrill Lynch,
    Michael worked in brand management at Procter & Gamble.
    <br /><br />
    Michael is a member of the CNBC Global CFO Council, serves on the Tech:
    NYC Leadership Council, and is an advisor/board member to entrepreneurial
    and high-growth companies, including OJO Labs. He was named in the 2016-2017 
    class of David Rockefeller Fellows, and is on the Board of Directors of
    Share Our Strength — one of the nation’s leading anti-hunger organizations.
    Michael received a BA from Harvard College and an MBA from Harvard
    Business School.`,
    linkedInUrl: "https://www.linkedin.com/in/mgnyc/",
  },
  {
    name: "Barrie Laver",
    title:
      "Managing Director, Head Venture Capital & Private Equity with Royal Bank of Canada",
    bio: `Barrie is Managing Director, Head Venture Capital and Private Equity with
    Royal Bank of Canada (RBC) and leads RBC Capital Partners, the bank’s VC
    and PE arm. His primary focus is on leading venture investments in support of
    RBC’s broader innovation strategy. Typically, these are minority investments in
    early-stage technology companies where RBC has a commercial partnership
    and where the investment is seen to deliver increased strategic benefit, as well
    as strong financial returns. Barrie’s background is primarily in the venture
    capital industry, both with RBC, as well as his own independent venture fund
    that focused on early-stage technology investments.
    <br /><br />
    Barrie has also served as CEO with companies in the technology, hospitality,
    and manufacturing sectors, including Vintage Hotels in Niagara-on-the-
    Lake and Prinova Inc. (now Messagepoint Inc.) in Toronto. He has experience
    on numerous private, public, and nonprofit boards and is currently a board
    member with OJO Labs and Aqua Forum, a nonprofit focused on water
    sustainability. His board experience also includes having served on the Board
    of Governors of Havergal College, a leading independent girls’ school in
    Toronto, where he was Chair of the Board from 2014-2017, as well as serving
    as a Trustee of Runnymede HealthCare Centre in Toronto from 1990-1999 and
    2000-2003.
    <br /><br />
    Barrie is a member of CPA Canada and the Institute of Corporate Directors.`,
    linkedInUrl: "https://www.linkedin.com/in/barrielaver/",
  },
  {
    name: "Scott Hamer",
    title: "President, Mortgage & Real Estate, Red Ventures",
    bio: `Scott has over 20 years of digital marketing experience, including more than
    15 years at Red Ventures, where he has filled a variety of roles in performance
    marketing, sales operations, and business leadership. He has led key business
    units in financial services, telecom, insurance, and security. Scott currently
    serves as the President of Mortgage and Real Estate at Red Ventures. He
    focuses the majority of his time leading the Bankrate mortgages platform
    and the company’s two mortgage origination businesses – Sage Mortgage
    and Interest Mortgage.
    <br /><br />
    Scott has served on the board of OJO Labs since 2018 when Red Ventures led
    OJO’s Series B round. He has a BA in Economics from DePauw University.`,
    linkedInUrl: "https://www.linkedin.com/in/scott-hamer-71432b6/",
  },
  imtiyazHaque,
  {
    name: "Lauren Rich",
    title: "Managing Director, Wafra",
    bio: `Lauren Rich is a Managing Director and serves on Wafra’s Management Committee. 
    Ms. Rich helps lead Wafra’s strategic partnership investment mandates in both mature 
    and next generation asset management businesses. While at Wafra, Ms. Rich has 
    completed several direct minority investments in alternative asset management 
    firms, GP financings, secondary private equity investments as well as investments 
    within closed end funds.
    <br /><br />
    Previously, Ms. Rich was an Associate with Davis Polk & Wardwell in New York, where 
    she advised clients on a broad range of financing transactions.
    <br /><br />
    Ms. Rich earned a BA from the University of Pennsylvania and a JD from 
    New York University School of Law.`,
    linkedInUrl: "https://www.linkedin.com/in/lauren-rich-81673343",
  },
];

export const advisors = [
  {
    image: "https://via.placeholder.com/145x141",
    name: "Steve Waters",
  },
  {
    image: "https://via.placeholder.com/145x141",
    name: "Justin Petro",
  },
  {
    image: "https://via.placeholder.com/145x141",
    name: "Chris Heller",
  },
  {
    image: "https://via.placeholder.com/145x141",
    name: "Steve Waters 2",
  },
  {
    image: "https://via.placeholder.com/145x141",
    name: "Justin Petro 2",
  },
  {
    image: "https://via.placeholder.com/145x141",
    name: "Chris Heller 2",
  },
];

export const aboutUsCareers = [
  {
    type: "chat",
    data: {
      image: "/img/20191022_Ojo_Kitchen_0042_WEB.jpg",
      imageOnRight: true,
    },
  },
  {
    type: "chat",
    data: {
      image: "/img/20191022_Ojo_Kitchen_0100_WEB.jpg",
      imageOnRight: true,
    },
  },
  {
    type: "chat",
    data: {
      image: "/img/20191022_Ojo_Kitchen_0171_WEB.jpg",
      imageOnRight: true,
    },
  },
  {
    type: "chat",
    data: {
      image: "/img/20191022_Ojo_LivingRoom_0034_WEB.jpg",
      imageOnRight: true,
    },
  },
  {
    type: "chat",
    data: {
      image: "/img/20191022_Ojo_Corner_Offices_0195_WEB.jpg",
      imageOnRight: true,
    },
  },
];

export const ojoCares = [
  {
    type: "chat",
    data: {
      image: "/img/OJO_cares.jpg",
      imageOnRight: true,
    },
  },
];

export const funders = [
  {
    image: `${process.env.PUBLIC_URL}/img/3rd-party-logos/investors_wafra.png`,
    link: "https://www.wafra.com/",
    alt: "Wafra",
  },
  {
    image: `${process.env.PUBLIC_URL}/img/3rd-party-logos/investors_breyer.png`,
    link: "https://breyercapital.com/",
    alt: "Breyer Capital",
  },
  {
    image: "//v.fastcdn.co/u/7270fb60/36145182-0-logo-live-oak.png",
    link: "https://liveoakvp.com/",
    alt: "Live Oak Venture Partners",
  },
  {
    image: "//v.fastcdn.co/u/7270fb60/36145192-0-logo-rbc.png",
    link: "https://www.rbc.com/about-rbc.html",
    alt: " RBC Royal Bank",
  },
  {
    image: "//v.fastcdn.co/u/7270fb60/36145187-0-logo-northwestern-mu.png",
    link: "https://innovation.northwesternmutual.com/",
    alt: "Northwestern Mutual",
  },
  {
    image: "//v.fastcdn.co/u/7270fb60/36145177-0-logo-realogy.png",
    link: "https://www.realogy.com/",
    alt: "Realogy",
  },
  {
    image: "//v.fastcdn.co/u/7270fb60/36145202-0-logo-servicemaster.png",
    link: "https://www.servicemaster.com/",
    alt: "ServiceMaster",
  },
  {
    image: "//v.fastcdn.co/u/7270fb60/36145197-0-logo-silverton.png",
    link: "https://www.silvertonpartners.com/",
    alt: "Silverton Partners",
  },
];

export const workingWith = [
  {
    image: `${process.env.PUBLIC_URL}/img/3rd-party-logos/partners_cb.png`,
    alt: "Coldwell Banker",
  },
  {
    image: `${process.env.PUBLIC_URL}/img/3rd-party-logos/partners_bhg.png`,
    alt: "Better Homes and Gardens",
  },
  {
    image: `${process.env.PUBLIC_URL}/img/3rd-party-logos/partners_c21.png`,
    alt: "Century 21",
  },
  {
    image: `${process.env.PUBLIC_URL}/img/3rd-party-logos/partners_soth.png`,
    alt: "Sothebys",
  },
  {
    image: `${process.env.PUBLIC_URL}/img/3rd-party-logos/partners_bank.png`,
    alt: "Bankrate",
  },
  {
    image: `${process.env.PUBLIC_URL}/img/3rd-party-logos/partners_era.png`,
    alt: "ERA",
  },
  {
    image: `${process.env.PUBLIC_URL}/img/3rd-party-logos/partners_corcoran.png`,
    alt: "Corcoran",
  },
];

export const coreValues = [
  {
    header: `Unwavering\ncompassion`,
    text: "We better our communities by leading with heart and intelligence and celebrating authenticity.",
    image: "/img/core-value-badges/unwavering-compassion.svg",
  },
  {
    header: "Collaborative\nexcellence",
    text: "We foster a culture of collaboration and teamwork, where individuals work together toward common goals.",
    image: "/img/core-value-badges/collaborative-excellence.svg",
  },
  {
    header: "Own\nthe\noutcome",
    text: "We are driven by meaningful outcomes. We practice extreme ownership and accountability to deliver exceptional results.",
    image: "/img/core-value-badges/own-the-outcome.svg",
  },
  {
    header: "There\nis\nalways\na\nway",
    text: "We turn challenges into opportunities by employing creative and resourceful approaches to problem-solving.",
    image: "/img/core-value-badges/there-is-always-a-way.svg",
  },
];

export const benefits = [
  {
    text: "Paid Parental Leave",
  },
  {
    text: "OJO Cares Community Volunteering",
  },
  {
    text: "Dog-Friendly Environment",
  },
  {
    text: "Home Office & Wellness Stipends",
  },
  {
    text: "Open PTO Policy",
  },
  {
    text: "Flexible Schedules",
  },
  {
    text: "Competitive Salaries & Equity",
  },
  {
    text: "100% Coverage of Medical Benefits",
  },  
];

export const talents = [
  {
    image: "/img/teams/OJO_TEAM_PRODUCT.jpg",
    header: "Product",
    description:
      "Product strategists, storytellers, and customer experience experts designing innovative solutions to customer challenges.",
    callToAction: "See open positions",
    link: "/jobs/product",
  },
  {
    image: "/img/teams/OJO_TEAM_ENGINEERING.jpg",
    header: "Engineering",
    description:
      "Complex problem solvers, imagineers, and creators tackling gnarly technical challenges and developing delightful customer experiences.",
    callToAction: "See open positions",
    link: "/jobs/engineering",
  },
  {
    image: "/img/teams/OJO_TEAM_MARKETING.jpg",
    header: "Marketing",
    description:
      "Customer-obsessed brand builders, storytellers, and data-driven leaders building profitable and enduring relationships.",
    callToAction: "See open positions",
    link: "/jobs/marketing",
  },
  {
    image: "/img/teams/OJO_TEAM_PEOPLE_OPERATIONS.jpg",
    header: "Operations",
    description:
      "Culture drivers, talent magnets, and foundation builders focusing on the needs of our people, managing the essentials of an exceptional company.",
    callToAction: "See open positions",
    link: "/jobs/operations",
  },
  {
    image: "/img/teams/OJO_TEAM_FINANCE.jpg",
    header: "Finance",
    description:
      "Strategists, organizers, and success enablers  leading an agile and disciplined approach to investments and management of our growth, people, and operations.",
    callToAction: "See open positions",
    link: "/jobs/finance",
  },
  {
    image: "/img/teams/OJO_TEAM_INDUSTRY.jpg",
    header: "Industry Partners",
    description:
      "Dreamers, builders, and nurturers driving strategic partnerships to put OJO in the hands of millions of customers.",
    callToAction: "See open positions",
    link: "/jobs/industry-partners",
  },
  {
    image: "/img/teams/OJO_TEAM_PRODUCT.jpg",
    header: "Mortgage Referral",
    description:
      "Empowering home buyers nationwide to make better and more informed decisions on financing their dream home by connecting them with the right mortgage partner at the right time.    ",
    callToAction: "See open positions",
    link: "/jobs/mortgage-referral",
  },
];

export const employeeQuotes = [
  {
    image: "/img/ALEX.jpg",
    quote:
      "“A very passionate team, pursuing a challenging and inspiring mission, in a company that deeply cares about its people.”",
    source: {
      name: "Alex Kromsov",
      title: "Senior Director of Analytics",
    },
  },
  {
    image: "/img/ANALYSA.jpg",
    quote:
      "“There is a strong sense of community. We work hard together, we support each other, and we celebrate wins together.”",
    source: {
      name: "Analysa Gonzales",
      title: "Data Science & Signal Processing",
    },
  },
  {
    image: "/img/THIEN.jpg",
    quote:
      "“Everyone is incredibly smart, driven, and humble. We get stuff done because the entire team is focused on creating a great product for our consumers.”",
    source: {
      name: "Thien Vo",
      title: "Software Engineer",
    },
  },
];

export const askOjoPage = {
  whatCanIAsk: {
    image: "https://via.placeholder.com/640x398",
    conversation: [
      {
        text: "Hey OJO, what can I ask you?",
      },
      {
        text: "You can ask me anything about real estate, including specific property information. Need some inspiration?",
        fromOjo: true,
      },
    ],
  },
  localInfo: {
    image: "https://via.placeholder.com/640x398",
    conversation: [
      {
        text: "Try asking me a question about the neighborhood or school district!",
        fromOjo: true,
      },
    ],
  },
  localInfoQuestions: [
    "What neighborhoods are good for families?",
    "Is there a pet hospital or vet nearby?",
    "How far is the walk to the nearest park?",
    "Is there neighborhood traffic?",
    "Can you hear the trains at night?",
  ],
  insiderInfo: {
    image: "https://via.placeholder.com/640x398",
    conversation: [
      {
        text: "If I can answer a question from my database, I’ll ask a real person — so you can get the inside scoop.",
        fromOjo: true,
      },
    ],
  },
  insiderInfoQuestions: [
    "How much did the seller pay for the home?",
    "Why is this house back on the market?",
    "When was the house last renovated?",
    "How long has this home been on the market?",
  ],
  homeDetails: {
    image: "https://via.placeholder.com/640x398",
    conversation: [
      {
        text: "Giving you the details on homes you love is my bread and butter. Ask me anything, and I’ll find the answer!",
        fromOjo: true,
      },
    ],
  },
  homeDetailsInteriorQuestions: [
    "Will the master fit a king-sized bed?",
    "Does the living room get natural light?",
    "Does the house have a sub-pump?",
    "Does the home have insulation in the attic?",
    "Any ghost sightings or paranormal activity?",
  ],
  homeDetailsExteriorQuestions: [
    "How old is the roof?",
    "Is the house in a flood zone?",
    "What is the type of foundation?",
    "What species of tree is in the front yard?",
    "Will my SUV fit in the garage?",
  ],
};

export const cultureMontageList = [
  {
    url: "/img/culture/ACC_DOG_WALK.jpg",
    size: 2,
  },
  {
    url: "/img/culture/ACC_GROUP_PIC.jpg",
    size: 2,
  },
  {
    url: "/img/culture/BPTW_REV.jpg",
    size: 3,
  },
  {
    url: "/img/culture/MOBILE_LOAVES.jpg",
    size: 2,
  },
  {
    url: "/img/culture/MOBILE_LOAVES_2.jpg",
    size: 4,
  },
  {
    url: "/img/culture/OJO_ANNIVERSARY.jpg",
    size: 1,
  },
  {
    url: "/img/culture/OJO_ANNIVERSARY_2.jpg",
    size: 5,
  },
  {
    url: "/img/culture/OJO_A_LIST_GROUP.jpg",
    size: 3,
  },
  {
    url: "/img/culture/OJO_CHRISTMAS_1.jpg",
    size: 5,
  },
  {
    url: "/img/culture/OJO_CHRISTMAS_2.jpg",
    size: 4,
  },
  {
    url: "/img/culture/OJO_CHRISTMAS_OFFICE.jpg",
    size: 2,
  },
  {
    url: "/img/culture/OJO_REALOGY_FACEBOOK.jpg",
    size: 3,
  },
  {
    url: "/img/culture/SAFE_PAPER_PLANES.jpg",
    size: 3,
  },
  {
    url: "/img/culture/SAFE_PAPER_PLANES_2.jpg",
    size: 2,
  },
  {
    url: "/img/culture/SAFE_PAPER_PLANES_3.jpg",
    size: 5,
  },
  {
    url: "/img/culture/ST_LUCIA_GROUP.jpg",
    size: 4,
  },
  {
    url: "/img/culture/TEXAS_FOOD_BANK.jpg",
    size: 1,
  },
  {
    url: "/img/culture/TEXAS_FOOD_BANK_2.jpg",
    size: 3,
  },
];

export const offices = [
  {
    buttonName: "Austin HQ",
    header: "Austin, TX",
    subHeader: "THE COMMAND CENTER",
    description:
      "OJO Labs’ headquarters is where the magic happens. As the heartbeat of OJO’s product development and operations, Austin is a growing hub with new jobs in Engineering, Data Science, Product Design, UX, Customer Success, and Marketing.",
    image: "/img/offices/austin.jpg",
  },
  {
    buttonName: "St. Lucia",
    header: "St. Lucia",
    subHeader: "THE ENGINE ROOM",
    description:
      "St. Lucia is home to OJO Labs’ Artificial Intelligence Training (AIT) and Customer Support Operations. Powered by an extensive network of industry experts, our team is dedicated to providing real-time responses to every request, no matter how specific. They’re a big part of what helps OJO get smarter over time.",
    image: "/img/offices/stlucia.jpg",
    videoUrl: "https://player.vimeo.com/video/525908258?autoplay=0y",
    buttonText: "Meet the team",
  },
  {
    buttonName: "Minneapolis",
    header: "Minneapolis",
    subHeader: "THE POWER PLANT",
    description:
      "Minneapolis-St. Paul is home to OJO Labs’ data services operations. The team fuels OJO Labs’ conversational AI platform with the most robust property listing database in North America.",
    image: "/img/offices/wolfnet.jpg",
  },
];

export const growthTimeline = [
  {
    name: "2021",
    bullets: [
      "OJO Labs launches OJO.ca, a personalized home search experience for Canadian homebuyers.",
    ],
  },
  {
    name: "2020",
    bullets: [
      "OJO Labs acquires personal finance platform, Digs.",
      "OJO Labs raises $62.5 million and acquires listing platform Movoto in a move to transform the homebuying journey.",
    ],
  },
  {
    name: "2019",
    bullets: [
      "OJO Labs acquires RealSavvy, a powerful real estate software platform for agents, brokers, and teams.",
      "OJO Labs closes a $45 million Series C funding round.",
    ],
  },
  {
    name: "2018",
    bullets: [
      "The first OJO home purchase closes escrow in Miami, Florida.",
      "OJO Labs is granted U.S. patents for humans-in-the-loop training and natural language processing.",
      "OJO Labs acquires data aggregator and listing giant, Wolfnet Technologies.",
      "OJO Labs closes a $20.5 million Series B funding round.",
    ],
  },
  {
    name: "2017",
    bullets: ["OJO Labs opens St. Lucia training center."],
  },
  {
    name: "2016",
    bullets: [
      "OJO Labs closes a $6 million Series A funding round.",
      "John Berkowitz and David Rubin found OJO Labs.",
    ],
  },
];


export const testimonials = [
  {
    name: "Jennifer",
    location: "Portland, OR",
    quotation:
      "Just got my second OJO Client under contract! And I am showing homes to a 3rd. Thank you OJO for your amazing partnership!",
  },
  {
    name: "Carol",
    location: "Charlotte, NC",
    quotation:
      "OJO has streamlined the process for agents. OJO is easy to work with and the clients really seem to find the process to be smooth and easy for them.",
  },
  {
    name: "Jason",
    location: "Berkeley, MI",
    quotation:
      "Amazing agent support and an excellent revenue stream for realtors. OJO is an honest company that provides a valuable service. I am very happy to have them as a partner. They treat my clients and me with respect and professionalism.",
  },
  {
    name: "P. Cross",
    location: "Durham, NC",
    quotation:
      "I like the fact that with OJO I have the ability to interact with prospects that otherwise I would never have had the opportunity to have met. I have met prospects that became clients that have become friends. I like the fact that I can choose the prospects that I want to work with. With OJO, I get to set my own pace and workload which is awesome.",
  },
];

export default {
  aboutUsCareers,
  advisors,
  agentInfo,
  areaHighlights,
  askOjoPage,
  availableAreas,
  benefits,
  boardOfDirectors,
  comingSoonAreas,
  coreValues,
  cultureMontageList,
  employeeQuotes,
  featuredConversations,
  funders,
  growthTimeline,
  huntSmarterConversations,
  jumbotron,
  leadership,
  offices,
  ojoCares,
  propertyListings,
  talents,
  testimonials,
  workingWith,
};
