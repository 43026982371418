import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import classes from './Modal.module.scss';

const Modal = ({ backdropClass, children, close, contentClass, isOpen, showCloseButton }) => {
  useEffect(() => {
    if (isOpen) {
      disableBodyScroll();
    }

    return function cleanup() {
      clearAllBodyScrollLocks();
    };
  });

  return ReactDOM.createPortal(
    <CSSTransition
      in={isOpen}
      timeout={600}
      classNames={{
        enter: classes.modalEnter,
        enterActive: classes.modalEnterActive,
        exit: classes.modalExit,
        exitActive: classes.modalExitActive,
      }}
      unmountOnExit
    >
      <div className={classes.modal}>
        <div className={classnames(classes.modalBackdrop, backdropClass)} onClick={close} />
        <div className={classnames(classes.modalContent, contentClass)}>
          {children}
        </div>
        {showCloseButton && <div role='button' tabIndex='0' onClick={close} className={classes.closeButton}>
          <FontAwesomeIcon icon='times' />
        </div>}
      </div>
    </CSSTransition>, document.body
  );
}

Modal.propTypes = {
  backdropClass: PropTypes.string,
  children: PropTypes.any.isRequired,
  close: PropTypes.func.isRequired,
  contentClass: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  showCloseButton: PropTypes.bool,
};

Modal.defaultProps = {
  backdropClass: '',
  contentClass: '',
  showCloseButton: true,
}

export default Modal;