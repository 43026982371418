import styled, { css } from "styled-components";
import { deviceScreenSizeQuery } from "components/theme/index";

export const StyledBrandTile = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  gap: 20px;

  &:last-child {
    padding-bottom: 0;
  }

  @media ${deviceScreenSizeQuery.tablet} {
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 3.75rem;
  }

  ${({ negative }) =>
    negative &&
    css`
      color: ${({ theme }) => theme.colors.white};
    `}

  ${({ reversed }) =>
    reversed &&
    css`
      @media ${deviceScreenSizeQuery.tablet} {
        flex-direction: row-reverse;
      }
    `}

  .tile__image {
    flex-grow: 1;
    flex: 1 1;
    border-radius: 12px;
    overflow: hidden;

    img {
      width: 100%;
    }
    @media ${deviceScreenSizeQuery.tablet} {
      border-radius: 20px;
    }
  }

  .tile__title {
    font-size: 1.75rem;
    line-height: 2.375rem;
    margin-bottom: 0.75rem;
    color: ${({ theme }) => theme.colors.emerald};

    ${({ negative }) =>
      negative &&
      css`
        color: ${({ theme }) => theme.colors.seafoam};
      `}

    @media ${deviceScreenSizeQuery.tablet} {
      font-size: 2rem;
      line-height: 3rem;
      margin-bottom: 1.25rem;
    }
  }

  .tile__content {
    font-size: 1.125rem;
    line-height: 1.625rem;
    flex-grow: 1;
    flex: 1 1 0px;
    display: flex;

    @media ${deviceScreenSizeQuery.tablet} {
      justify-content: center;
      align-items: center;
    }
  }

  .tile__wrapper {
    @media ${deviceScreenSizeQuery.tablet} {
      max-width: 408px;
    }
  }

  .tile__description {
    margin-bottom: 1.25rem;

    @media ${deviceScreenSizeQuery.tablet} {
      margin-bottom: 2.5rem;
    }
  }

  .tile__footer {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
  }
`;
