import styled, { css } from "styled-components";
import { deviceScreenSizeQuery } from "components/theme/index";

export const StyledFixedFooter = styled.div`
  display: block;
  position: fixed;
  transition: transform .3s ease;
  transform: translateY(100%);
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 20px;
  line-height: 25px;
  border-radius: 20px 20px 0 0;
  background-color: ${({ theme }) => theme.colors.grape};
  z-index: 10;

  ${({isVisible}) => isVisible && css`
    transform: translateY(0);
  `}

  .fixed-footer__wrapper {
    max-width: 1304px;
    line-height: 25px;
    height: 100%;
    margin: 0 auto;
    min-width: 30%;
    color: ${({ theme }) => theme.colors.white};
    display: flex;
    align-items: center;
    column-gap: 8px;
    justify-content: center;
  }

  .fixed-footer__mobile-text {
    display: none;
    font-size: 0.875rem;
    line-height: 1.3rem;
  }

  .fixed-footer__desktop-text {
    display: none;
  }

  .fixed-footer__desktop-feature {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.seafoam};
    display: none;
  }

  .fixed-footer__links {
    display: flex;
    column-gap: 22px;

    a {
      display: inline-block;
      
      img {
        max-width: 104px;
      }
    }
  }

  @media ${deviceScreenSizeQuery.mobile} {
    padding: 16px 20px;

    .fixed-footer__wrapper {
      justify-content: space-between;
    }

    .fixed-footer__mobile-text {
      display: block;
    }

    .fixed-footer__links {
      column-gap: 8px;
    }
  }

  @media ${deviceScreenSizeQuery.tablet} {
    height: 67px;
    padding: 16px 24px;

    .fixed-footer__wrapper {
      display: flex;
      align-items: center;
      column-gap: 32px;
      justify-content: space-between;
    }

    .fixed-footer__mobile-text {
      display: none;
    }

    .fixed-footer__desktop-text {
      display: block;
    }

    .fixed-footer__links {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      column-gap: 32px;
    }
  }
`;
