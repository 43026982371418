import React from "react";
import { StyledArticleHeader } from "./pageHeader/styled";
import Breadcrumbs from "./Breadcrumbs";

const toReadableDate = (dateString) =>
  new Date(dateString).toLocaleDateString(undefined, {
    month: "long",
    day: "numeric",
    year: "numeric",
  });

const ArticleHeader = ({
  title,
  breadcrumbsLinks,
  articleDate,
  articleLocation,
}) => (
  <StyledArticleHeader>
    {breadcrumbsLinks && (
      <div className="page-header__supertext">
        <Breadcrumbs links={breadcrumbsLinks} />
      </div>
    )}
    <h2 className="page-header__title">{title}</h2>
    <div className="page-header__info">
      {toReadableDate(articleDate)}
      {articleLocation && <span> | {articleLocation}</span>}
    </div>
  </StyledArticleHeader>
);

export default ArticleHeader;
