import styled from "styled-components";
import { deviceScreenSizeQuery } from "components/theme/index";

export const StyledArticleMediaWrapper = styled.div`
  .article__mobile-img {
    display: block;
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
    margin-bottom: 30px;

    @media ${deviceScreenSizeQuery.tablet} {
      display: none;
    }
  }

  .article__main-img {
    width: 100%;
    height: 210px;
    object-fit: cover;
    display: none;
    border-radius: 20px;
    margin-bottom: 30px;

    @media ${deviceScreenSizeQuery.tablet} {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  .article__video-frame {
    position: relative;
    width: 100vw;
    height: calc(100vw * 9 / 16);
    margin: 0 -32px;

    @media ${deviceScreenSizeQuery.desktop} {
      min-width: 640px;
      min-height: 360px;
      width: auto;
      height: auto;
      display: inline-block;
      margin: 0;
    }
  }
`;
