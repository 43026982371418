import { deviceScreenSizeQuery } from "components/theme/index";
import styled from "styled-components";

export const StyledTabs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 60px;
  padding-top: 20px;

  > * {
    width: 210px;
  }

  @media ${deviceScreenSizeQuery.tablet} {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 40px;
    max-width: 1304px;
    margin: 0 auto 60px;
  }

`;