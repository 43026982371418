import styled, { css } from "styled-components";
import { deviceScreenSizeQuery } from "components/theme/index";

export const StyledArticleBrief = styled.article`
  width: 267px;
  height: 123px;
  box-sizing: border-box;
  background-color: transparent;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.night};
  overflow: hidden;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  position: relative;
  padding-right: 122px;

  & + & {
    margin-top: 20px;
  }

  @media ${deviceScreenSizeQuery.tablet} {
    height: 202px;
  }

  .brief__content {
    display: flex;
    flex-direction: row-reverse;
  }

  .brief__image {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    max-width: 112px;
    height: 122px;
    overflow: hidden;

    img {
      max-height: 100%;
      transform: translateX(-25%);
    }

    @media ${deviceScreenSizeQuery.tablet} {
      width: 100%;
      max-width: 202px;
      height: 202px;
    }
  }

  .brief__description {
    padding: 12px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media ${deviceScreenSizeQuery.tablet} {
      padding: 20px 16px;
    }
  }

  .brief__title {
    margin-bottom: 0.75rem;

    @media ${deviceScreenSizeQuery.tablet} {
      margin-bottom: 1rem;
      max-height: 110px;
      overflow: hidden;
    }
  }

  .brief__title a {
    font-size: 1.25rem;
    line-height: 1.875rem;
    font-family: ${({ theme }) => theme.fonts.primary};
    transition: color 0.3s ease-in;
    /* display: -webkit-box;
    -webkit-line-clamp: 2; */
    text-overflow: ellipsis;
    overflow: hidden;
    /* -webkit-box-orient: vertical; */

    @media ${deviceScreenSizeQuery.tablet} {
      font-size: 1.625rem;
      line-height: 2.375rem;
    }
  }

  .brief__more a {
    font-size: 1.125rem;
    font-weight: ${({ theme }) => theme.fonts.weight.bold};
    color: ${({ theme }) => theme.colors.raspberry};
  }

  .brief__title a:hover {
    color: ${({ theme }) => theme.colors.raspberry};
  }

  img {
    object-fit: cover;
  }

  ${({ size }) =>
    size === "wide" &&
    css`
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      padding-right: 0;

      .brief__title {
        margin-bottom: 0;

        a {
          font-size: 1.625rem;
          line-height: 2.375rem;
          color: ${({ theme }) => theme.colors.emerald};
        }
      }

      .brief__description {
        padding: 40px;
      }

      .brief__info {
        color: ${({ theme }) => theme.colors.shark};
        font-size: 0.875rem;
        line-height: 1.25rem;
        margin-bottom: 10px;
      }

      .brief__content {
        display: flex;
        flex-direction: column;
        background-color: transparent;
      }

      .brief__image {
        display: block;
        position: static;
        max-width: 100%;
        width: 100%;
        height: 248px;

        img {
          min-width: 100%;
          transform: translateX(0);
        }
      }

      .brief__more {
        display: none;
      }

      @media ${deviceScreenSizeQuery.tablet} {
        .brief__content {
          flex-direction: row;
          padding-left: 300px;
          position: relative;
        }

        .brief__title a {
          font-size: 2rem;
          line-height: 3rem;
        }

        .brief__image {
          position: absolute;
          display: block;
          width: 300px;
          min-height: 100%;
          left: 0;
          top: 0;
          bottom: 0;

          img {
            min-width: 100%;
            max-height: 100%;
            transform: translateX(0);
          }
        }
      }
    `}

  ${({ size }) =>
    size === "medium" &&
    css`
      background-color: blue;

      width: 320px;

      img {
        width: 320px;
        height: 200px;
      }
    `}

  ${({ size }) =>
    size === "small" &&
    css`
      background-color: green;

      img {
        width: 300px;
        height: 169px;
      }
    `}

    ${({ size }) =>
    size === "large" &&
    css`
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      padding-right: 0;

      .brief__title {
        margin-bottom: 10px;

        a {
          font-size: 1.625rem;
          line-height: 2.375rem;
          color: ${({ theme }) => theme.colors.emerald};
        }
      }

      .brief__description {
        padding: 40px;
      }

      .brief__info {
        color: ${({ theme }) => theme.colors.shark};
        font-size: 0.875rem;
        line-height: 1.25rem;
        margin-bottom: 10px;
      }

      .brief__content {
        display: flex;
        flex-direction: column;
        background-color: transparent;
      }

      .brief__image {
        display: none;
        position: static;
        max-width: 100%;
        height: 248px;

        img {
          width: 100%;
          min-height: 248px;
          transform: translateX(0);
        }
      }

      .brief__more {
        display: none;
      }

      ${({ showImage }) =>
        showImage &&
        css`
          .brief__image {
            display: block;
          }
        `}

      @media ${deviceScreenSizeQuery.tablet} {
        .brief__content {
          flex-direction: row;
          position: relative;
        }

        .brief__title a {
          font-size: 1.625rem;
          line-height: 2.375rem;
        }

        .brief__image {
          display: none;
        }

        ${({ showImage }) =>
          showImage &&
          css`
            .brief__content {
              flex-direction: column;
            }

            .brief__image {
              position: static;
              display: block;
              max-width: 100%;
              height: 248px;

              img {
                width: 100%;
                transform: translateX(0);
              }
            }
          `}
      }
    `}
`;

export const StyledArticleCard = styled.article`
  width: 267px;
  height: 123px;
  box-sizing: border-box;
  background-color: transparent;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.night};
  overflow: hidden;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  position: relative;
  padding-right: 122px;

  @media ${deviceScreenSizeQuery.tablet} {
    width: 500px;
    height: 202px;
    padding-right: 214px;
  }

  .card__content {
    display: flex;
    flex-direction: row-reverse;
  }

  .card__image {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    max-width: 112px;
    height: 122px;
    overflow: hidden;

    img {
      max-height: 100%;
      transform: translateX(-25%);
    }

    @media ${deviceScreenSizeQuery.tablet} {
      width: 100%;
      max-width: 202px;
      height: 202px;
    }
  }

  .card__description {
    padding: 12px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media ${deviceScreenSizeQuery.tablet} {
      padding: 20px 16px;
    }
  }

  .card__title {
    margin-bottom: 0.75rem;

    @media ${deviceScreenSizeQuery.tablet} {
      margin-bottom: 1rem;
    }
  }

  .card__title a {
    font-size: 1.25rem;
    line-height: 1.875rem;
    font-family: ${({ theme }) => theme.fonts.primary};
    display: -webkit-box;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;

    @media ${deviceScreenSizeQuery.tablet} {
      font-size: 1.625rem;
      line-height: 2.375rem;
    }
  }

  .card__more a {
    font-size: 1.125rem;
    font-weight: ${({ theme }) => theme.fonts.weight.bold};
    color: ${({ theme }) => theme.colors.raspberry};

    svg {
      margin-left: 10px;
    }
  }

  .card__title a,
  .card__title a:visited {
    color: ${({ theme }) => theme.colors.night};
  }

  img {
    object-fit: cover;
  }

  &.small {
    img {
      width: 300px;
      height: 169px;
    }
  }
`;
