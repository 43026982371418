import React from "react";
import { StyledPositionsFilter } from "./PositionsFilter/styled";

const optionClasses = (selected, option, disabled) => {
  if (disabled) {
    return `positions-filter__option disabled`; 
  }

  return `positions-filter__option ${option.id === selected ? "selected" : ""}`;
};

const PositionsFilter = ({ options, selected, onSelect, title }) => {
  optionClasses(selected, options);

  return (
    <>
      <StyledPositionsFilter>
        <div className="positions-filter__wrapper">
          <h2 className="positions-filter__title">{title}</h2>
          <div className="positions-filter__container">
            {options.map((option, index) => (
              <>
                {option.disabled && (
                  <div
                    className={optionClasses(selected, option, true)}
                    key={index}
                  >
                    {option.label}
                  </div>
                )}
                {!option.disabled && (
                  <div
                    className={optionClasses(selected, option)}
                    key={index}
                    onClick={() => onSelect(option.id)}
                  >
                    {option.label}
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      </StyledPositionsFilter>
    </>
  );
};

export default PositionsFilter;
