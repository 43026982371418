import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classes from './CustomToggle.module.scss';

const CustomToggle = React.forwardRef((props, ref) => {
  const { children, ...rest } = props;
  const ariaExpanded = props['aria-expanded'];
  return (<a
    className={classes["custom-toggle__link"]}
    ref={ref}
    {...rest}
  >
    {children}
    <span className={classes.dropdownIcon}>
      {!ariaExpanded && <FontAwesomeIcon icon='angle-down' />}
      {ariaExpanded && <FontAwesomeIcon icon='angle-up' />}
    </span>
  </a>);
});

export default CustomToggle;