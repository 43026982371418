import React from 'react';
import isMobileDevice from 'util/isMobileDevice';

export const Store = React.createContext();

export const SET_PRESS_PAGE = 'SET_PRESS_PAGE';
export const SET_NEWS_FEED = 'SET_NEWS_FEED';
export const SET_AVAILABLE_JOBS = 'SET_AVAILABLE_JOBS';
export const SET_DEPARTMENTS = 'SET_DEPARTMENTS';

const initialState = {
  isMobile: isMobileDevice(),
  pressPage: {
    featuredContent: [],
    featuredIn: [],
    videos: [],
    newsRoom: [],
    resources: [],
    mediaKit: {},
    loaded: false,
  },
  newsFeed: {
    articles: [],
    loaded: false,
  },
  jobs: {
    available: {
      value: [],
      loaded: false,
    },
    departments: {
      value: [],
      loaded: false,
    },
  },
};

function reducer(state, action) {
  switch (action.type) {
    case SET_PRESS_PAGE:
      return {
        ...state,
        pressPage: {
          ...action.payload,
          loaded: true,
        },
      };
    case SET_NEWS_FEED:
      return {
        ...state,
        newsFeed: {
          ...action.payload,
          loaded: true
        },
      };
      case SET_AVAILABLE_JOBS:
        return {
          ...state,
          jobs: {
            ...state.jobs,
            available: {
              value: action.payload,
              loaded: true
            },
          },
        };
      case SET_DEPARTMENTS:
        return {
          ...state,
          jobs: {
            ...state.jobs,
            departments: {
              value: action.payload,
              loaded: true
            },
          },
        };
    default:
      return state;
  }
}

export const StoreProvider = (props) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const value = { state, dispatch };

  return <Store.Provider value={value}>{props.children}</Store.Provider>;
}