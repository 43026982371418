import styled from "styled-components";

export const StyledSearch = styled.div`
  max-width: 640px;
  width: 100%;
  margin: 0 auto;
  height: 64px;
  overflow: visible;
  border: 0;
  position: relative;
  background: transparent;

  iframe {
    border: 0;
    width: 100%;
    min-height: 300px;
  }

  .search__container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
  }

  @media screen and (min-width: 1000px) {
    height: 55px;
  }
`;
