import React from "react";

import Link from "components/layout/Link";
import Button from "components/forms/Button";

import linkedinLogo from "assets/svg/social-icons/linkedin-logo.svg";
import facebookLogo from "assets/svg/social-icons/facebook-logo.svg";
import twitterLogo from "assets/svg/social-icons/twitter-logo.svg";
import ojoLogo from "assets/svg/ojo-logo-footer.svg";
import { ReactComponent as EqualHousingOpportunity } from "assets/svg/equal-housing-opportunity.svg";

import { StyledFooter } from "./footer/styled";
import WebsiteTheme from "components/theme/index";

const Footer = () => {
  return (
    <StyledFooter>
      <div className="footer__wrapper">
        <div className="footer__nav-container">
          <div className="footer__nav">
            <div className="footer__logo">
              <img src={ojoLogo} alt="OJO" />
            </div>
            <div className="footer__nav-section">
              <div className="footer__nav-title">About Us</div>
              <Link to="/our-story">Our Story</Link>
              <Link to="/media-room">Media Room</Link>
              <Link to="/company-news">Company News</Link>
              <Link to="/ojo-in-the-news">OJO in the News</Link>
              <Link to="/careers">Careers</Link>
            </div>
            <div className="footer__nav-section">
              <div className="footer__nav-title">Offerings</div>
              <Link to="/homebuyers">Homebuyer</Link>
              <Link to="/homeowner">Homeowner</Link>
              <Link to="/home-seller">Home Seller</Link>
              <Link to="https://agents.movoto.com/movoto-programs">Real Estate Professional</Link>
              <Link to="/partnerships">Partnerships</Link>
            </div>
            <div className="footer__nav-section footer__nav-section--social">
              <div className="footer__social">
                <a
                  href="https://twitter.com/ojolabs"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={twitterLogo} alt="OJO on Twitter" />
                </a>
                <a
                  href="https://www.linkedin.com/company/ojo-labs/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={linkedinLogo} alt="OJO on LinkedIn" />
                </a>
                <a
                  href="https://www.facebook.com/ojolabs"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={facebookLogo} alt="OJO on Facebook" />
                </a>
              </div>
            </div>
            <div className="footer__login">
              <Button tertiary to="https://referrals.movoto.com/auth/login">
                <span>Agent Sign In</span>
              </Button>
            </div>
          </div>
        </div>
        <div className="footer__info">
          <div>
            <small>
              Made with <span className="footer__info-heart">❤</span> in Austin,
              TX
            </small>
            <br />
            <small>
              (512) 456-8292 &#183; 1007 S Congress, Building 9, Suite 400,
              Austin, TX 78704
            </small>
            <br />
            <small className="footer__terms">
              Read our <Link to="/terms-and-conditions">Terms</Link> &amp; <Link to="/privacy">Privacy Policy</Link>
            </small>
            <br />
            <small>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSfPgOhSEOlOEOao3sCWN9XW9_VLER7lLS_74I23CUbRRU-NJA/viewform?usp=sf_link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Do Not Sell or Share My Personal Information
              </a>
              <span>&nbsp;•&nbsp;</span>
              <a
                href={`${process.env.PUBLIC_URL}/pdf/Privacy_Information_for_California_Residents.pdf`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Info for California Residents
              </a>
            </small>
            <div className="footer__info-brokers">
              <small>
                Texas Real Estate Commission Broker Licenses:
                <br />
                OJO Labs Inc. - 9007689
                <br />
                OJO Home LLC - 9008342
              </small>
              <div>
                <small>
                  <a
                    href="https://www.trec.texas.gov/forms/consumer-protection-notice"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    TREC Consumer Notice
                  </a>
                  <span>&nbsp;•&nbsp;</span>
                  <a
                    href={`${process.env.PUBLIC_URL}/pdf/iabs_ojo.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    TREC Information About Brokerage Services
                  </a>
                  <span>&nbsp;•&nbsp;</span>
                  <Link
                    to="/broker-licenses"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Real Estate Broker Licenses
                  </Link>
                </small>
              </div>
            </div>
          </div>
          <div>
            <div className="footer__equal-housing">
              <Link
                to="https://www.hud.gov/program_offices/fair_housing_equal_opp"
                target="_blank"
                rel="noopener noreferrer"
              >
                <EqualHousingOpportunity
                  fill={WebsiteTheme.colors.night}
                  fillColor={WebsiteTheme.colors.night}
                />

                OJO IS COMMITTED TO AND ABIDES BY THE FAIR HOUSING ACT AND EQUAL OPPORTUNITY ACT.
              </Link>
            </div>
            <div className="footer__info-accessibility">
              <small>
                Accessibility Statement:
                <br />
                OJO Labs is committed to making our products and services
                accessible to everyone, including people with disabilities. We
                work continuously to provide a digital experience that is
                accessible to all. If you have difficulty accessing any part of
                this website and need an accommodation or have a request for
                auxiliary aides or services, please contact us at{" "}
                <Link
                  to="mailto:accessibility@ojolabs.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="email-link"
                >
                  accessibility@ojolabs.com
                </Link>
                . We welcome your feedback and will consider all requests.
              </small>
            </div>
          </div>
        </div>
      </div>
    </StyledFooter>
  );
};

export default Footer;
