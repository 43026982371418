import React from 'react';
import PropTypes from 'prop-types';

import classes from './SeoSignup.module.scss';
import Button from 'components/forms/Button';

const SeoSignup = ({buttonText, subTitle, title}) => {
  return (<div className={classes.signUpSection}>
    <div className={classes.title}>{title}</div>
    <div className={classes.subTitle}>{subTitle}</div>
    <Button primary large to='/signup'>{buttonText}</Button>
  </div>);
};

SeoSignup.propTypes = {
  buttonText: PropTypes.string,
  subTitle: PropTypes.string,
  title: PropTypes.string,
};

SeoSignup.defaultProps = {
  buttonText: 'Sign Up',
  subTitle: 'Try OJO Today',
  title: 'Start your move',
};

export default SeoSignup;
