import React from "react";
import { StyledFixedFooter } from "./FixedFooter/styled";
import useScrollToView from "hooks/useScrollToView";

import appStoreImage from "assets/img/appstore-btn.png";
import googlePlayImage from "assets/img/gplay-btn.png";

const APP_STORE_LINK_MOBILE =
  "https://apps.apple.com/app/apple-store/id537795977?pt=469170&ct=directojohomepage&mt=8";
const APP_STORE_LINK_DESKTOP =
  "https://apps.apple.com/app/apple-store/id537795977?pt=469170&ct=directojohomepage&mt=8";
const GOOGLE_PLAY_LINK_MOBILE =
  "https://play.google.com/store/apps/details?id=com.movoto.movoto&referrer=utm_source%3Dojohome%26utm_medium%3Dojohome%26utm_term%3Dojohome%26utm_content%3Dojohome%26utm_campaign%3Ddirectojohomepage";
const GOOGLE_PLAY_LINK_DESKTOP =
  "https://play.google.com/store/apps/details?id=com.movoto.movoto&referrer=utm_source%3Dojohome%26utm_medium%3Dojohome%26utm_term%3Dojohome%26utm_content%3Dojohome%26utm_campaign%3Ddirectojohomepage";

export const FixedFooter = ({ isMobile = false }) => {
  const isFooterVisible = useScrollToView();

  const appStoreLink = isMobile
    ? APP_STORE_LINK_MOBILE
    : APP_STORE_LINK_DESKTOP;
  const googlePlayLink = isMobile
    ? GOOGLE_PLAY_LINK_MOBILE
    : GOOGLE_PLAY_LINK_DESKTOP;

  return (
    <StyledFixedFooter isVisible={isFooterVisible}>
      <div className="fixed-footer__wrapper">
        <span className="fixed-footer__mobile-text">
          Buy, sell, and own
          <br /> right from your pocket
        </span>
        <strong className="fixed-footer__desktop-text">
          Buy, sell, and own right from your pocket — download our app.
        </strong>
        <div className="fixed-footer__links">
          <a href={appStoreLink} title="Download our app on Apple App Store">
            <img
              src={appStoreImage}
              alt="Download our app on Apple App Store"
            />
          </a>
          <a href={googlePlayLink} title="Download our app on Google Play">
            <img src={googlePlayImage} alt="Download our app on Google Play" />
          </a>
        </div>
      </div>
    </StyledFixedFooter>
  );
};
