import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { articleListIdMap } from "util/data/press";
import { getPressList } from "util/contentfulApi";
import useDocumentTitle from "hooks/documentTitle";
import useMetaDescription from "hooks/metaDescription";

import ArticleBrief from "components/layout/articles/ArticleBrief";
import Button from "components/forms/Button";
import PageHeader from "components/layout/PageHeader";
import PageWrapper from "components/layout/PageWrapper";
import PageFooter from "components/layout/PageFooter";
import Paginator from "components/Paginator";

const PAGE_SIZE = 10;

const titleMap = {
  VIDEOS: "OJO Press Videos",
  "OJO LABS FEATURED IN": "OJO Home",
  "OJO LABS NEWSROOM": "OJO Newsroom",
};

const descriptionMap = {
  VIDEOS:
    "OJO press videos - learn more about how we're changing real estate with AI.",
};

const ArticleListPage = ({ history, match }) => {
  const [articles, setArticles] = useState([]);
  const [totalPages, setTotalPages] = useState(1);

  const { title } = articleListIdMap[match.params.id];
  const pageNumber = match.params.pageNumber
    ? Number.parseInt(match.params.pageNumber, 10)
    : 0;
  useDocumentTitle(titleMap[title.toUpperCase()] || "OJO Home");
  useMetaDescription(descriptionMap[title.toUpperCase()] || "");
  
  useEffect(() => {
    getPressList(match.params.id, PAGE_SIZE, pageNumber * PAGE_SIZE).then(
      (data) => {
        setArticles(data.articles);
        setTotalPages(Math.ceil(data.total / PAGE_SIZE));
      }
    );
  }, [match.params.id, pageNumber]);

  const breadcrumbsLinks = [
    {
      to: "/media-room",
      text: "Media Room",
    },
  ];

  return (
    <PageWrapper center backgroundColor="white">
      <PageHeader title={title} breadcrumbsLinks={breadcrumbsLinks} />
      <div>
        {articles.map((article, index) => (
          <ArticleBrief {...article} size="large" key={index} showSnippet />
        ))}
      </div>
      <PageFooter>
        <Paginator
          totalPages={totalPages}
          pageNumber={pageNumber}
          onPageChange={(data) => {
            if (data.selected !== pageNumber) {
              // todo: check this
              history.push(`/${match.params.id}/${data.selected}`);
            }
          }}
        />
        <Button secondary solid to="/media-room">
          Return to Media Room
        </Button>
      </PageFooter>
    </PageWrapper>
  );
};

ArticleListPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      pageNumber: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default ArticleListPage;
