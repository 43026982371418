import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

import useDocumentTitle from 'hooks/documentTitle';
import useMetaDescription from 'hooks/metaDescription';
import { getSeoArticle } from 'util/contentfulApi';

import Button from 'components/forms/Button';
import Link from 'components/layout/Link';
import ArticleGrid from 'components/seo/ArticleGrid';
import SeoSignup from 'components/seo/SeoSignup';

import mailImage from 'assets/img/social-icons/seo/mail.png';
import linkedinImage from 'assets/img/social-icons/seo/linkedin.png';
import facebookImage from 'assets/img/social-icons/seo/facebook.png';
import twitterImage from 'assets/img/social-icons/seo/twitter.png';
import SeoTool from 'components/SeoTool';

import classes from './SEOArticle.module.scss';

const SEOArticle = ({ match }) => {
  const [data, setData] = useState(null);
  useDocumentTitle(data ? data.seoTitleTag : '');
  useMetaDescription(data ? data.seoMetaDescription : '');

  useEffect(() => {
    getSeoArticle(match.params.articleId).then(pageData => {
      setData(pageData);
    });
  }, []);

  if (!data) {
    return (
      <div className={classes.page}>
        Loading...
      </div>
    );
  }

  const {
    articleList,
    author,
    buttonText,
    buttonDestination,
    content,
    image,
    imageCaption,
    openGraphConfig,
    publishDate,
    showButton,
    showSignupSection,
    title,
   } = data;

   const linkProps = buttonDestination && buttonDestination.indexOf('/') === 0 ? {} : {
    target: '_blank',
    rel: 'noopener noreferrer',
   };

  return (
    <React.Fragment>
      <SeoTool openGraphConfig={{...openGraphConfig, url: window.location.href}} />
      <div className={classes.page}>
        <div className={classes.sectionTitle}>RESOURCES</div>
        <div className={classes.title}>{title}</div>
        <div className={classes.metadata}>
          <span className={classes.date}>
            {new Date(publishDate).toLocaleDateString(undefined, {month: 'long', day: 'numeric', year: 'numeric'})}
          </span>
          <span className={classes.author}>{author}</span>
        </div>
        <div className={classes.shareActions}>
          <Link
            to={`https://www.linkedin.com/shareArticle?mini=true&url=${window.location}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={linkedinImage} alt='Share on LinkedIn'/>
          </Link>
          <Link
            to={`https://twitter.com/intent/tweet?text=I found this article really useful and thought you would too: ${window.location}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={twitterImage} alt='Share on Twitter'/>
          </Link>
          <Link
            to={`https://www.facebook.com/dialog/share?app_id=220055839085029&display=popup&href=${window.location}&redirect_uri=${window.location}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={facebookImage} alt='Share on Facebook'/>
          </Link>
          <Link
            to={`mailto:?body=I found this article really useful and thought you would too: ${window.location}&subject=${title}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={mailImage} alt='Share via mail' />
          </Link>
        </div>
        <div className={classes.image}>
          <img src={image} alt={title} />
          <div className={classes.caption}>{imageCaption}</div>
        </div>
        <div
          className={classes.content}
          dangerouslySetInnerHTML={{__html: documentToHtmlString(content)}}
        />
        {showButton && <div className={classes.button}>
          <Button
            secondary
            large
            to={buttonDestination}
            linkProps={linkProps}
          >
              {buttonText} <FontAwesomeIcon icon='chevron-right' />
            </Button>
        </div>}
        {articleList && <ArticleGrid {...articleList} />}
      </div>
      {showSignupSection && <SeoSignup />}
    </React.Fragment>
  )
};

SEOArticle.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      articleId: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default SEOArticle;