import { deviceScreenSizeQuery } from "components/theme/index";
import styled, { css } from "styled-components";

export const StyledTeamsShowcase = styled.div`
  .showcase__wrapper {
    padding: 80px 38px 0;
    background-color: ${({ theme }) => theme.colors.emerald};
  }

  .showcase__desktop-title {
    font-size: 2.25rem;
    line-height: 3rem;
    color: ${({ theme }) => theme.colors.seafoam};
    font-family: ${({ theme }) => theme.fonts.primary};
    margin-bottom: 20px;
    display: none;
  }

  .showcase__title {
    font-size: 2.25rem;
    line-height: 3rem;
    color: ${({ theme }) => theme.colors.seafoam};
    font-family: ${({ theme }) => theme.fonts.primary};
    margin-bottom: 20px;
    display: block;
  }

  .showcase__buttons {
    display: none;
  }

  .showcase__select {
    margin-bottom: 20px;
    position: relative;

    .showcase__dropdown-list {
      margin-bottom: 5px;
    }

    .showcase__dropdown-item {
      padding: 10px 32px;
      display: block;
      color: ${({ theme }) => theme.colors.springgreen};
      font-family: ${({ theme }) => theme.fonts.secondary};
      font-weight: ${({ theme }) => theme.fonts.weight.bold};
      transition: color 0.2s ease, background-color 0.2s ease,
        border-color 0.2s ease;
      cursor: pointer;

      :hover {
        color: ${({ theme }) => theme.colors.white};
      }
    }

    .showcase__selected-item {
      border: 2px solid ${({ theme }) => theme.colors.springgreen};
      border-radius: 26px;
      padding: 14px 32px;
      cursor: pointer;

      span {
        display: flex;
        justify-content: space-between;
        color: ${({ theme }) => theme.colors.white};
        font-family: ${({ theme }) => theme.fonts.secondary};
        font-weight: ${({ theme }) => theme.fonts.weight.bold};
        position: relative;
        z-index: 9;
      }
    }

    .showcase__chevron-icon {
      svg {
        width: 16px;
        rotate: 90deg;

        ${({ dropdownOpen }) =>
          dropdownOpen &&
          css`
            rotate: -90deg;
          `}
      }
    }
  }

  .showcase__dropdown-list {
    display: none;
    border: 2px solid ${({ theme }) => theme.colors.springgreen};
    border-top: 0;
    border-radius: 0 0 30px 30px;
    padding-bottom: 10px;
    position: absolute;
    width: 100%;
    background: ${({ theme }) => theme.colors.emerald};

    ${({ dropdownOpen }) =>
      dropdownOpen &&
      css`
        display: block;
      `}

    :before {
      content: "";
      display: block;
      top: -24px;
      height: 24px;
      left: -2px;
      position: absolute;
      width: 100%;
      background: ${({ theme }) => theme.colors.emerald};
      border-left: 2px solid ${({ theme }) => theme.colors.springgreen};
      border-right: 2px solid ${({ theme }) => theme.colors.springgreen};
      box-sizing: content-box;
    }
  }

  .showcase__description {
    padding-bottom: 40px;

    h3 {
      font-size: 1.375rem;
      line-height: 2rem;
      margin-bottom: 10px;
      font-family: ${({ theme }) => theme.fonts.secondary};
      font-weight: ${({ theme }) => theme.fonts.weight.bold};
      color: ${({ theme }) => theme.colors.white};
    }

    p {
      font-size: 1rem;
      line-height: 1.625rem;
      color: ${({ theme }) => theme.colors.white};
    }
  }

  .showcase__image {
    display: block;
    width: 100%;

    img {
      width: 100%;
    }
  }

  @media ${deviceScreenSizeQuery.laptop} {
    margin: 0 68px 100px 68px;

    .showcase__center {
      margin: 0 auto;
      max-width: 1304px;
      height: 780px;
      border-radius: 20px;
      overflow: hidden;
      display: flex;
      column-gap: 40px;
      background-color: ${({ theme }) => theme.colors.emerald};
    }

    .showcase__wrapper {
      max-width: 50%;
      padding: 80px 0 80px 60px;
      background-color: ${({ theme }) => theme.colors.emerald};
    }

    .showcase__image {
      display: flex;
      width: auto;
      max-width: 50%;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      img {
        width: auto;
        height: 100%;
      }
    }

    .showcase__desktop-title {
      font-size: 2.5rem;
      line-height: 3.25rem;
      margin-bottom: 30px;
      display: block;
    }

    .showcase__title {
      display: none;
    }

    .showcase__buttons {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 30px;
    }

    .showcase__button {
      border-radius: 26px;
      padding: 12px 30px;
      font-weight: ${({ theme }) => theme.fonts.weight.bold};
      color: ${({ theme }) => theme.colors.springgreen};
      border: 2px solid ${({ theme }) => theme.colors.springgreen};
      cursor: pointer;
      transition: color 0.2s ease, background-color 0.2s ease,
        border-color 0.2s ease;
    }

    .showcase__button--selected {
      color: ${({ theme }) => theme.colors.emerald};
      background-color: ${({ theme }) => theme.colors.white};
      border-color: ${({ theme }) => theme.colors.white};
    }

    .showcase__select {
      display: none;
    }

    .showcase__description {
      padding-bottom: 0;

      h3 {
        font-size: 1.375rem;
        line-height: 2rem;
        margin-bottom: 10px;
        font-family: ${({ theme }) => theme.fonts.secondary};
        font-weight: ${({ theme }) => theme.fonts.weight.bold};
        color: ${({ theme }) => theme.colors.white};
      }

      p {
        font-size: 1rem;
        line-height: 1.625rem;
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }

  @media ${deviceScreenSizeQuery.desktop} {
    .showcase__center {
      height: 640px;
    }
  }
`;
