import React, { useContext, useEffect, useMemo, useState } from "react";
import sortBy from "lodash/sortBy";
import PropTypes from "prop-types";

import useDocumentTitle from "hooks/documentTitle";
import useMetaDescription from "hooks/metaDescription";
import { Store, SET_AVAILABLE_JOBS, SET_DEPARTMENTS } from "Store";

import Button from "components/forms/Button";

import PageWrapper from "components/layout/PageWrapper";
import PageHeader from "components/layout/PageHeader";
import Section from "components/Section";
import PositionsBlock from "components/PositionsBlock";
import PositionsFilter from "components/PositionsFilter";

const ALL = "all";

const renderTeamSection = (team, availableJobs, departments) => {
  if (team === ALL) {
    return;
  }

  const selectedDepartment = departments.find((d) => d.selectId === team);
  const jobs = availableJobs.filter((job) =>
    job.departments.find(
      (jd) =>
        jd.id === selectedDepartment.id ||
        selectedDepartment.child_ids.indexOf(jd.id) >= 0
    )
  );

  const parsedJobs = jobs.map(({ absolute_url, location, title }) => ({ title, location, to: absolute_url }));

  return (
    <PositionsBlock 
      key={selectedDepartment.id}
      title={selectedDepartment.name}
      items={parsedJobs}  
    >
    </PositionsBlock>
  );
};

const JobsPage = ({ history, match }) => {
  const { dispatch, state } = useContext(Store);
  const { available, departments: departmentState } = state.jobs;
  const { value: availableJobs, loaded: jobsLoaded } = available;
  const { value: departments, loaded: departmentsLoaded } = departmentState;

  const departmentOptions = useMemo(() => {
    const options = departments.map((department) => ({
      label: department.name,
      id: department.selectId,
      disabled: department.disabled,
    }));
    options.unshift({
      label: "All Departments",
      id: ALL,
      disabled: false,
    });
    return options;
  }, [departments]);

  const [selectedDepartment, setSelectedDepartment] = useState(
    match.params.id || ALL
  );
  const selectedOption = departmentOptions.find(
    (job) => job.id === selectedDepartment
  );

  const title =
    selectedDepartment === ALL
      ? "Job Openings | OJO Homes"
      : `OJO ${selectedOption ? selectedOption.label : ""} Jobs`;
  useDocumentTitle(title);

  const description =
    selectedDepartment === ALL
      ? "Job openings at OJO - browse by department and apply today."
      : `${selectedOption ? selectedOption.label : ""} jobs at OJO`;
  useMetaDescription(description);

  useEffect(() => {
    if (!jobsLoaded) {
      window
        .fetch(
          "https://boards-api.greenhouse.io/v1/boards/ojolabs/jobs?content=true",
          {
            method: "GET",
          }
        )
        .then((response) => {
          response.json().then((data) => {
            dispatch({ type: SET_AVAILABLE_JOBS, payload: data.jobs });
          });
        });
    }

    if (!departmentsLoaded) {
      window
        .fetch(
          "https://boards-api.greenhouse.io/v1/boards/ojolabs/departments",
          {
            method: "GET",
          }
        )
        .then((response) => {
          response.json().then((data) => {
            const parentDepartments = sortBy(
              data.departments.filter((d) => !d.parent_id),
              ["name"]
            );
            parentDepartments.forEach((department) => {
              if (department.id === 0) {
                department.name = "Miscellaneous";
              }
              department.selectId = department.name
                .trim()
                .toLowerCase()
                .replace(" ", "-");

              department.disabled = department.jobs.length > 0 ? false : true;
            });

            // const filteredDepartments = parentDepartments.filter((dept) => dept.jobs.length);

            dispatch({ type: SET_DEPARTMENTS, payload: parentDepartments });
          });
        });
    }
  }, []);

  const showAllJobs = selectedDepartment === ALL;
  const loading = !jobsLoaded || !departmentsLoaded;

  return (
    <>
      <PageWrapper backgroundColor="white">
        <PageHeader
          title="Jobs at OJO"
          breadcrumbsLinks={[{ to: "/careers", text: "Careers" }]}
        />
      </PageWrapper>
      <PositionsFilter
        title="What positions are you interested in?"
        options={departmentOptions}
        selected={selectedDepartment}
        onSelect={(id) => {
          if (id !== selectedDepartment) {
            setSelectedDepartment(id);
            if (id === ALL) {
              history.replace(`/jobs`);
            } else {
              history.replace(`/jobs/${id}`);
            }
          }
        }}
      />
      <PageWrapper backgroundColor="white">
        <div>
          {!loading && (
            <div style={{marginBottom: "60px"}}>
              {showAllJobs &&
                departments.map(({ selectId }) =>
                  renderTeamSection(selectId, availableJobs, departments)
                )}
              {!showAllJobs &&
                renderTeamSection(
                  selectedDepartment,
                  availableJobs,
                  departments
                )}
            </div>
          )}
        </div>
      </PageWrapper>
      <Section
        title="Not finding what you’re looking for?"
        description={<><h3>Get in touch</h3> If you've got questions about joining OJO, or just want to learn more about the company, send us a note.</>}
        negative
        backgroundColor="emerald"
        split
      >
        <Button
            primary
            negative
            to="mailto:careers@ojo.com"
            linkProps={{ target: "_blank", rel: "noopener noreferrer" }}
          >
            Contact Us
          </Button>
      </Section>
    </>
  );
};

JobsPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default JobsPage;
