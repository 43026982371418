import { deviceScreenSizeQuery } from "components/theme/index";
import styled from "styled-components";

export const StyledPositionsFilter = styled.div`

  .positions-filter__wrapper {
    padding: 60px 38px;
    background-color: ${({theme}) => theme.colors.concrete};
  }

  .positions-filter__title {
    font-size: 2.75rem;
    line-height: 3rem;
    margin-bottom: 25px;
    color: ${({theme}) => theme.colors.emerald};
    text-align: center;
  }

  .positions-filter__container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }

  .positions-filter__option {
    border: 2px solid ${({theme}) => theme.colors.emerald};
    color: ${({theme}) => theme.colors.emerald};
    font-weight: ${({theme}) => theme.fonts.weight.bold};
    font-size: 1rem;
    line-height: 1.625rem;
    padding: 8px 30px;
    border-radius: 26px;
    cursor: pointer;

    &.disabled {
      cursor: default;
      border-color: #ccc;
      color: #ccc;
    }

    &.selected {
      border: 2px solid ${({theme}) => theme.colors.emerald};
      background-color: ${({theme}) => theme.colors.emerald};
      color: ${({theme}) => theme.colors.white};
    }
  }

  @media ${deviceScreenSizeQuery.tablet} {
    margin: 0 auto;
    padding: 0 0 30px 0;
    background: white;
    

    .positions-filter__wrapper { 
      margin: 0 auto;
      border-radius: 20px;
      max-width: 1304px;
    }

    .positions-filter__title {
      font-size: 3.25rem;
      line-height: 4.25rem;
    }

    .positions-filter__container {
      column-gap: 10px;
      row-gap: 20px;
    }
  }
`;
