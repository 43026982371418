import { deviceScreenSizeQuery } from "components/theme/index";
import styled from "styled-components";

export const StyledBigTextTile = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({theme}) => theme.colors.product}; 
  border-radius: 20px;
  padding: 30px;
  box-sizing: border-box;
  transition: box-shadow 500ms;
  margin-bottom: 20px;

  .big-text-tile__header {
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: ${({theme}) => theme.colors.raspberry};
    font-weight: ${({theme}) => theme.fonts.weight.bold};
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 0;
  }
  
  img {
    width: 133px;
    height: 147px;
    align-self: center;
    margin: 0 24px 20px;
    max-width: 100%;
  }

  @media ${deviceScreenSizeQuery.tablet} {
    max-width: 300px; 
    margin-bottom: 0; 
  }
`;