import React from "react";
import { StyledSearch } from "./styled";

const SEARCH_WIDGET_URL = process.env.REACT_APP_SEARCH_WIDGET_URL
  ? `${process.env.REACT_APP_SEARCH_WIDGET_URL}`
  : "https://www2.movoto.com/widget/home-finder/search/?simple=1&testuser=0";

const shouldRender = navigator.userAgent !== "ReactSnap";

const Search = () => {
  return (
    <StyledSearch>
      <div className="search__container">
          {
              shouldRender &&
              <iframe
                  title="Search your new home"
                  className="search-frame"
                  src={SEARCH_WIDGET_URL}
                  allowTransparency="true"
              />
          }
      </div>
    </StyledSearch>
  );
};

export default Search;
