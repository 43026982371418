import React from "react";

import useDocumentTitle from "hooks/documentTitle";
import useMetaDescription from "hooks/metaDescription";
import useMetaKeywords from "hooks/metaKeywords";

import PageWrapper from "components/layout/PageWrapper";
import PageFeature from "components/layout/PageFeature";
import Section from "components/Section";
import HorizontalScroll from "components/layout/HorizontalScroll";
import homeownerHeroImage from "assets/img/homeowner-hero.jpg";
import ItemCard from "components/ItemCard";
import OverlappingSection from "components/OverlappingSection";
import Button from "components/forms/Button";

import seeHomesImage from "assets/img/see-homes.png";
import calculateBuyingImage from "assets/img/calculate-buying-power.png";
import findAgentImage from "assets/img/find-your-agent.png";
import manageHomeImage from "assets/img/manage-your-home.png";
import homeValueImage from "assets/img/home-value.png";
import equityImage from "assets/img/equity.png";
import checkImage from "assets/img/check.png";
import magnifyingGlassIcon from "assets/svg/icon-magnifying-glass.svg";
import graphChartIcon from "assets/svg/icon-graph-chart.svg";
import circleCheckIcon from "assets/svg/icon-circle-check.svg";

const itemsList = [
  <ItemCard
    linkTo="https://movoto.com"
    linkText="See Homes"
    backgroundColor="seafoam"
    size="large"
    image={seeHomesImage}
  />,
  <ItemCard
    linkTo="https://www.movoto.com/mortgages/buyingpower/"
    linkText="Calculate Your Buying Power"
    negative
    backgroundColor="forest"
    size="small"
    image={calculateBuyingImage}
  />,
  <ItemCard
    linkTo="https://www.movoto.com/agents/"
    linkText="Find Your Agent"
    backgroundColor="springgreen"
    size="medium"
    image={findAgentImage}
  />,
  <ItemCard
    linkTo="https://homeowner.ojo.com/signup"
    linkText="Manage Your Home"
    backgroundColor="concrete"
    image={manageHomeImage}
  />,
];

const HomeownerPage = () => {
  useDocumentTitle("Manage your home with OJO");
  useMetaDescription(
    "OJO Homeowner helps you manage the equity you’ve built, track your home’s value, and keep up with home maintenance — think of it as your personal guide to homeownership."
  );
  useMetaKeywords("OJO");

  return (
    <>
      <PageWrapper backgroundColor="emerald" marginBottom={40}>
        <PageFeature
          supertext="Homeowner"
          title="Owning a home is a lot, we make it a lot better"
          description="OJO Homeowner gives you all the tools you need to manage your home in one place. Understand your home’s value, know when it’s time to refinance, and manage your to-dos — all personalized to you and your home."
          image={homeownerHeroImage}
          ctaLink="https://homeowner.ojo.com/signup"
          ctaLinkProps={{ target: "_blank", rel: "noopener noreferer" }}
          ctaText="Manage Your Home"
          negative
        />
      </PageWrapper>

      <Section center maxWidth={1080}>
        <OverlappingSection
          icon={magnifyingGlassIcon}
          title="Monitor your home’s value"
          description="Get monthly valuations based on tailored market insights so you can stay in the know and be ready to make moves."
          image={homeValueImage}
        />
        <OverlappingSection
          icon={graphChartIcon}
          title="Track your equity"
          description="Connect your mortgage and keep an eye on your equity with your homeowner dashboard so you find hidden savings and plan for the future."
          image={equityImage}
          zIndex={1}
        />
        <OverlappingSection
          icon={circleCheckIcon}
          title="Stay on top of your to-dos"
          description="Receive a personalized maintenance checklist specific to your home’s needs every month. We’ll even help you find the right experts for the job by connecting you to the industry’s best."
          image={checkImage}
          zIndex={2}
        />
      </Section>

      <Section
        title="Try OJO Homeowner"
        description="Set up your homeowner dashboard in minutes and get an instant valuation."
        center
        backgroundColor="concrete"
        footer={
          <Button
            primary
            negative
            to="https://homeowner.ojo.com/signup"
            linkProps={{ target: "_blank" }}
          >
            Get Started
          </Button>
        }
      ></Section>

      <Section
        title="Start your journey with OJO"
        center
        backgroundColor="emerald"
        negative
      >
        <HorizontalScroll itemsList={itemsList} paddingTop={30} />
      </Section>
    </>
  );
};

export default HomeownerPage;
