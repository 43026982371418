import { deviceScreenSizeQuery } from "components/theme/index";
import styled from "styled-components";

export const StyledAppContainer = styled.div`
  h1, h2, h3 {
    font-family: ${({theme}) => theme.fonts.primary}
  }

  position: relative;
  margin: 0 auto;
  background-color: ${({theme}) => theme.colors.concrete};
  border-top: 64px solid ${({theme}) => theme.colors.emerald};

  @media ${deviceScreenSizeQuery.tablet} {
    border-top: 100px solid ${({theme}) => theme.colors.emerald};
  }
`;
