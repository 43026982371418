import React from "react";
import PropTypes from "prop-types";
import { StyledButton, EmeraldButton } from "./button/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "components/layout/Link";

const Button = (props) => {
  const BtnType = props.emerald ? EmeraldButton : StyledButton;

  const Btn = (
    <BtnType {...props}>
      {props.icon && (
        <div className="icon">
          <FontAwesomeIcon icon={props.icon} />
        </div>
      )}
      {props.children}
    </BtnType>
  );

  if (props.to) {
    return (
      <Link to={props.to} {...props.linkProps}>
        {Btn}
      </Link>
    );
  }
  return Btn;
};

Button.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.any,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  large: PropTypes.bool,
  linkProps: PropTypes.object,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
  small: PropTypes.bool,
  stretch: PropTypes.bool,
  to: PropTypes.string,
};

Button.defaultProps = {
  active: false,
  children: "",
  disabled: false,
  icon: "",
  large: false,
  linkProps: {},
  medium: false,
  primary: false,
  secondary: false,
  tertiary: false,
  small: false,
  stretch: false,
  to: "",
};

export default Button;
