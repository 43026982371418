import styled from "styled-components";
import { deviceScreenSizeQuery } from "components/theme/index";

export const StyledPositionsBlock = styled.div`
  padding: 30px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.product};
  margin-bottom: 10px;

  :last-child {
    margin-bottom: 0;
    border: 0;
  }

  .positions-block__title {
    padding-top: 30px;
    font-size: 2.25rem;
    line-height: 2.75rem;
    color: ${({ theme }) => theme.colors.emerald};
    font-family: ${({ theme }) => theme.fonts.primary};
    margin-bottom: 20px;
  }

  .positions-block__position {
    padding: 20px 0;
  }

  .positions-block__position-title {
    font-size: 1.125rem;
    line-height: 1.625rem;
    font-family: ${({ theme }) => theme.fonts.secondary};
  }

  .positions-block__position-location {
    color: ${({ theme }) => theme.colors.product};
    line-height: 1.625rem;
    font-size: 1.125rem;
    margin-bottom: 5px;
  }

  a {
    line-height: 1.625rem;
    font-size: 1.125rem;
    color: ${({ theme }) => theme.colors.raspberry};
    font-weight: ${({ theme }) => theme.fonts.weight.bold};
    display: flex;
    align-items: center;

    svg {
      margin-left: 10px;
    }
  }

  @media ${deviceScreenSizeQuery.tablet} {
    padding-top: 0;

    .positions-block__container {
      display: flex;
      flex-wrap: wrap;
      column-gap: 80px;
    }

    .positions-block__position {
      max-width: 612px;
      width: 40%;
    }
  }

  @media ${deviceScreenSizeQuery.laptop} {
    .positions-block__position {
      width: 44%;
    }
  }

  @media ${deviceScreenSizeQuery.desktop} {
    .positions-block__position {
      max-width: 612px;
      width: 46.93%;
    }
  }
`;
