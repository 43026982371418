import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Link from 'components/layout/Link';

import { ReactComponent as CloseIcon } from 'assets/svg/modal_close_x.svg';
import { ReactComponent as LinkedInIcon } from 'assets/svg/social-icons/linkedin-logo.svg';

import Modal from './Modal';
import classes from './BioModal.module.scss';

const BioModal = ({ close, isOpen, name, title, bio, image, linkedInUrl }) => {
  return (
    <Modal
      close={close}
      isOpen={isOpen}
      contentClass={classes.content}
      showCloseButton={false}
      backdropClass={classes.backdrop}
    >
      <div className={classes.holder}>
        {image && <div className={classes.image}>
          <img src={image} alt={`Headshot of ${name}`} />
        </div>}
        <div>
          <div className="body2">{name}</div>
          <div className="footnote">{title}</div>
          <Link
            to={linkedInUrl}
            className={classes.linkedInLink}
            target='_blank'
            rel='noopener noreferrer'
          >
            <LinkedInIcon width={24} height={24} />
          </Link>
        </div>
      </div>
      <div className={classes.bio}>
        <div dangerouslySetInnerHTML={{__html: bio}} />
      </div>
      <div className={classes.closeButton} role='button' onClick={close}>
        <CloseIcon />
      </div>
      <div className={classnames(classes.closeButton, classes.mobileBottomButton)} role='button' onClick={close}>
        <CloseIcon />
      </div>
      <div className={classes.fade} />
    </Modal>
  );
}

BioModal.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  bio: PropTypes.node.isRequired,
}

export default BioModal;