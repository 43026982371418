import React from "react";
import { FontAwesomeIcon } from "../../node_modules/@fortawesome/react-fontawesome/index";
import Link from "./layout/Link";
import { StyledPositionsBlock } from "./PositionsBlock/styled";

const PositionsBlock = ({ items, title }) => {
  return (
    <>
      {items.length > 0 && (
        <StyledPositionsBlock>
          <h2 className="positions-block__title">{title}</h2>
          <div className="positions-block__container">
          {items.map((item, index) => (
            <div className="positions-block__position" key={index}>
              <h3 className="positions-block__position-title">{item.title}</h3>
              <div className="positions-block__position-location">
                {item.location.name}
              </div>
              <Link to={item.to} >View Position <FontAwesomeIcon icon="arrow-right" /></Link>
            </div>
          ))}
          </div>
        </StyledPositionsBlock>
      )}
    </>
  );
};

export default PositionsBlock;
