import React from "react";
import PropTypes from "prop-types";

import Button from "components/forms/Button";
import { StyledBrandTile } from "./BrandTile/styled";

const BrandTile = ({
  headerTitle,
  headerImage,
  imageHeight,
  description,
  buttonTarget,
  buttonText,
  buttonLinkProps,
  reversed,
  negative,
  footer,
}) => {
  return (
    <StyledBrandTile reversed={reversed} negative={negative}>
      <div className="tile__image">
        {headerImage && (
          <img src={headerImage} alt={buttonText} height={imageHeight} />
        )}
      </div>
      <div className="tile__content">
        <div className="tile__wrapper">
          <h2 className="tile__title">{headerTitle}</h2>
          <div className="tile__description">{description}</div>
          {buttonTarget && (
            <div>
              <Button
                secondary
                negative={negative}
                to={buttonTarget}
                linkProps={buttonLinkProps}
              >
                {buttonText}
              </Button>
            </div>
          )}
          {footer && 
            <div className="tile__footer">{footer}</div>
          }
        </div>
      </div>
    </StyledBrandTile>
  );
};

BrandTile.defaulProps = {
  buttonLinkProps: undefined,
};

BrandTile.propTypes = {
  headerImage: PropTypes.string.isRequired,
  imageHeight: PropTypes.number,
  description: PropTypes.node.isRequired,
  buttonTarget: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonLinkProps: PropTypes.object,
  reversed: PropTypes.bool,
  negative: PropTypes.bool,
};

export default BrandTile;
