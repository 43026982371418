import React from "react";

import useDocumentTitle from "hooks/documentTitle";
import useMetaDescription from "hooks/metaDescription";
import useMetaKeywords from "hooks/metaKeywords";

import PageWrapper from "components/layout/PageWrapper";
import PageFeature from "components/layout/PageFeature";
import Section from "components/Section";
import BrandTile from "components/tiles/BrandTile";
import HorizontalScroll from "components/layout/HorizontalScroll";
import homesellerHeroImage from "assets/img/homeseller-hero.jpg";
import supportTileImage from "assets/img/homeseller-support.jpg";
import keepTileImage from "assets/img/homeseller-keep.jpg";
import homeTileImage from "assets/img/homeseller-home.jpg";
import ItemCard from "components/ItemCard";

import seeHomesImage from "assets/img/see-homes.png";
import calculateBuyingImage from "assets/img/calculate-buying-power.png";
import findAgentImage from "assets/img/find-your-agent.png";
import manageHomeImage from "assets/img/manage-your-home.png";
const itemsList = [
  <ItemCard
    linkTo="https://movoto.com"
    linkText="See Homes"
    backgroundColor="seafoam"
    size="large"
    image={seeHomesImage}
  />,
  <ItemCard
    linkTo="https://www.movoto.com/mortgages/buyingpower/"
    linkText="Calculate Your Buying Power"
    negative
    backgroundColor="forest"
    size="small"
    image={calculateBuyingImage}
  />,
  <ItemCard
    linkTo="https://www.movoto.com/agents/"
    linkText="Find Your Agent"
    backgroundColor="springgreen"
    size="medium"
    image={findAgentImage}
  />,
  <ItemCard
    linkTo="https://homeowner.ojo.com/signup"
    linkText="Manage Your Home"
    backgroundColor="concrete"
    image={manageHomeImage}
  />,
];

const HomeSeller = () => {
  useDocumentTitle(
    "Sell your home with OJO"
  );
  useMetaDescription(
    "OJO helps homeowners get the most out of their home sale using market insights, connections to industry experts, and support from the right listing agent."
  );
  useMetaKeywords("Support for the home seller");

  return (
    <>
      <PageWrapper backgroundColor="emerald">
        <PageFeature
          supertext="Home Seller"
          title={
            <>
              Sell your home
              <br /> on your terms
            </>
          }
          description="With local agents, the latest market insights, and seamless access to industry experts, get the most out of your home sale with OJO."
          image={homesellerHeroImage}
          ctaLink="https://www.movoto.com/seller-resources/"
          ctaLinkProps={{ target: "_blank", rel: "noopener noreferer" }}
          ctaText="Get Started"
          negative
        />
      </PageWrapper>
      <Section marginBottom={40}>
        <BrandTile
          headerTitle="Support from start to close"
          headerImage={supportTileImage}
          description="Work with a local OJO Network Agent to help maximize your home sale and assist you in getting the best price."
          buttonTarget="https://www.movoto.com/agents/"
          buttonText="Find an Agent"
          buttonLinkProps={{ rel: "nofollow", target: "_blank" }}
        />
        <BrandTile
          reversed
          headerTitle="Keep up with the market"
          headerImage={keepTileImage}
          description="Stay up to date with real-time market data and monthly valuation reports so you can estimate your home's worth and sell with confidence."
          buttonTarget="https://www.movoto.com/seller-resources/"
          buttonText="Find Seller Resources"
          buttonLinkProps={{ rel: "nofollow", target: "_blank" }}
        />
        <BrandTile
          headerTitle="Don't home alone"
          headerImage={homeTileImage}
          description="From bathroom renovations to lawn maintenance, seamlessly connect with industry experts to help get your home in selling shape."
          buttonTarget="https://www.movoto.com/homeservices/"
          buttonText="Find a Pro"
          buttonLinkProps={{ rel: "nofollow", target: "_blank" }}
        />
      </Section>
      <Section
        title="Start your journey with OJO"
        center
        backgroundColor="emerald"
        negative
        fullWidth
      >
        <HorizontalScroll itemsList={itemsList} paddingTop={30}/>
      </Section>
    </>
  );
};

export default HomeSeller;
