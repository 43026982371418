import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { CSSTransition } from "react-transition-group";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import Media from "react-media";

import logo from "assets/svg/ojo-logo.svg";
import caretDown from "assets/svg/caret-down.svg";
import hamburger from "assets/svg/hamburger.svg";
import CustomToggle from "components/forms/CustomToggle";

import classes from "./Header.module.scss";
import {
  StyledHeader,
  StyledHeaderContent,
  StyledNavigation,
  StyledMobileNavigation,
} from "../header/styled.js";

const PATH_CONFIG = {
  "/": {
    mobile: 618,
    tablet: 516,
    desktop: 664,
    hasHero: true,
  },
  "/our-story": {
    mobile: 878,
    tablet: 576,
    desktop: 664,
    hasHero: true,
  },
  "/agent-teams": {
    mobile: 638,
    tablet: 468,
    desktop: 568,
    hasHero: true,
  },
  "/homebuyer": {
    mobile: 662,
    tablet: 656,
    desktop: 672,
    hasHero: true,
  },
  "/partnerships": {
    mobile: 662,
    tablet: 656,
    desktop: 672,
    hasHero: true,
  },
  "/careers": {
    mobile: 432,
    tablet: 633,
    desktop: 673,
    hasHero: true,
  },
  "/jobs": {
    mobile: 432,
    tablet: 533,
    desktop: 673,
    hasHero: true,
  },
  default: {
    mobile: 100,
    tablet: 100,
    desktop: 100,
    hasHero: false,
  },
};

const Header = ({ location, hideNavigation }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [{ isAboutMenuOpen }, setMenuState] = useState({
    isAboutMenuOpen: false
  });

  const closeMenu = () => setMenuOpen(false);

  useEffect(() => {
    if (isMenuOpen) {
      disableBodyScroll();
    }

    return function cleanup() {
      clearAllBodyScrollLocks();
    };
  });

  const setAboutMenuOpen = (isOpen) => {
    setMenuState({
      isAboutMenuOpen: isOpen,
      isLoginMenuOpen: false,
    });
  };

  const pathConfig = PATH_CONFIG[location.pathname] || PATH_CONFIG.default;

  return (
    <StyledHeader withHero={pathConfig.hasHero} >
      <StyledHeaderContent>
        <Link to="/" className="logo-link">
          <img className="logo" src={logo} alt="ojo labs" />
        </Link>
        <StyledNavigation>
          <div
            id="menu"
            className="mobileMenuButton"
            onClick={() => setMenuOpen(!isMenuOpen)}
          >
            <img src={hamburger} alt="expand mobile menu" />
          </div>
          {!hideNavigation && (
            <>
              <div className={classes.desktopNavMenuItem}>
                <Dropdown
                  show={isAboutMenuOpen}
                  onToggle={(isOpen) => setAboutMenuOpen(isOpen)}
                  onMouseEnter={() => setAboutMenuOpen(true)}
                  onMouseLeave={() => setAboutMenuOpen(false)}
                  className={classes.dropdown}
                >
                  <Dropdown.Toggle
                    as={CustomToggle}
                    id="about-us-dropdown"
                    className={classes.dropdownToggle}
                  >
                    About Us
                  </Dropdown.Toggle>
                  <Dropdown.Menu className={classes.dropdownMenu}>
                    <Dropdown.Item as={Link} eventKey="1" to="/our-story">
                      Our Story
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="3" as={Link} to="/media-room">
                      Media Room
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="5" as={Link} to="/careers">
                      Careers
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className={classes.desktopNavMenuItem}>
                <Link to="/homebuyer">Homebuyer</Link>
              </div>
              <div className={classes.desktopNavMenuItem}>
                <Link to="/homeowner">Homeowner</Link>
              </div>
              <div className={classes.desktopNavMenuItem}>
                <Link to="/home-seller">Home Seller</Link>
              </div>
              <div className={classes.desktopNavMenuItem}>
                <Link to={{pathname: "https://agents.movoto.com/movoto-programs"}} target="_blank">Real Estate Professional</Link>
              </div>
            </>
          )}

          {createPortal(
            <CSSTransition
              in={isMenuOpen}
              timeout={300}
              classNames={{
                enter: "menuEnter",
                enterActive: "menuEnterActive",
                exit: "menuExit",
                exitActive: "menuExitActive",
              }}
              unmountOnExit
            >
              <StyledMobileNavigation>
                <div className="mobile-nav__header">
                  <Link to="/" className="logo-link" onClick={() => setMenuOpen(false)}>
                    <img className="logo" src={logo} alt="ojo labs" />
                  </Link>
                  <div
                    id="menu-close"
                    role="button"
                    tabIndex="0"
                    onClick={() => setMenuOpen(false)}
                    className="mobile-nav__close"
                  >
                    <img src={hamburger} alt="collapse mobile menu" />
                  </div>
                </div>
                <div className="mobile-nav__items">
                  {!hideNavigation && (
                    <>
                      <div
                        className={`mobile-nav__item ${isAboutMenuOpen && 'expanded'}`}
                        onClick={() => setAboutMenuOpen(!isAboutMenuOpen)}
                      >
                        <div className="mobile-nav__item-text">About Us</div>
                        <img
                          className="caret"
                          src={caretDown}
                          alt="Expand About Us"
                        />
                      </div>
                      <div
                        className={`mobile-nav__sub-menu ${isAboutMenuOpen && 'expanded'}`}
                      >
                        <div className="mobile-nav__item">
                          <Link to="/our-story" onClick={closeMenu}>
                            Our Story
                          </Link>
                        </div>
                        <div className="mobile-nav__item">
                          <Link to="/media-room" onClick={closeMenu}>
                            Media Room
                          </Link>
                        </div>
                        <div className="mobile-nav__item">
                          <Link to="/careers" onClick={closeMenu}>
                            Careers
                          </Link>
                        </div>
                      </div>
                      <div className="mobile-nav__item">
                        <Link to="/homebuyer" onClick={closeMenu}>
                          Homebuyer
                        </Link>
                      </div>
                      <div className="mobile-nav__item">
                        <Link to="/homeowner" onClick={closeMenu}>
                          Homeowner
                        </Link>
                      </div>
                      <div className="mobile-nav__item">
                        <Link to="/home-seller" onClick={closeMenu}>
                          Home Seller
                        </Link>
                      </div>
                      <div className="mobile-nav__item">
                        <Link to={{pathname: "https://agents.movoto.com/movoto-programs"}} target="_blank" onClick={closeMenu}>
                          Real Estate Professionals
                        </Link>
                      </div>
                    </>
                  )}
                </div>
              </StyledMobileNavigation>
            </CSSTransition>,
            document.body
          )}
        </StyledNavigation>
      </StyledHeaderContent>
    </StyledHeader>
  );
};

const HeaderWithMediaQuery = (props) => {
  return (
    <Media
      queries={{
        small: "(max-width: 767px)",
        medium: "(min-width: 768px) and (max-width: 1214px)",
        large: "(min-width: 1304px)",
      }}
    >
      {(matches) => (
        <Header
          isMobile={matches.small}
          isTablet={matches.medium}
          isDesktop={matches.large}
          {...props}
        />
      )}
    </Media>
  );
};

export default HeaderWithMediaQuery;
