import React from 'react';
import { queryString } from 'object-query-string';

import useDocumentTitle from 'hooks/documentTitle';
import useMetaDescription from 'hooks/metaDescription';

import classes from './AgentApplication.module.scss';
import { getIFrameUrl } from '../util/common';

const AgentApplication = () => {
  useDocumentTitle('Apply to become an agent on the OJO Select Network');
  useMetaDescription('Get introduced to homebuyers and sellers from the OJO Select Network');

  function receiveMessage(event) {
    if (event && event.data && event.data.url) {
      const url = event.data.url;
      if (!!event.data.replace) {
        window.location.replace(url)
      } else {
        window.location.assign(url);
      }
    }
   }

  React.useEffect(() => {
    window.addEventListener("message", receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage);
    }
  })

  let queryParams = '';
  try {
    queryParams = queryString(JSON.parse(window.localStorage.getItem('initialParams') || null) || '' );
  } catch(e) {
    // empty
  }

  const iframeUrl = getIFrameUrl('__iframeUrl', 'https://agent-tools.dev.aws.us-east-1.ojocore.com/osn-signup', 'https://referrals.ojo.me/osn-signup');
  const iframeSrc = `${iframeUrl}?${queryParams}`;

  return (
    <div className={classes.page}>
        <div className={classes.iframeContainer}>
          <iframe
              title="Agent Application"
              src={iframeSrc}
          />
        </div>
    </div>
  );
};

export default AgentApplication;