import React, { useState } from "react";
import PropTypes from "prop-types";

import Link from "components/layout/Link";
import { StyledArticleBrief } from "./styled";

const ArticleBrief = ({
  backupImage,
  date,
  href,
  image,
  isLocal,
  location,
  title,
  showInfo,
  showImage,
  showSnippet,
  size,
  snippet,
}) => {
  const [imageFailedToLoad, setImageFailedToLoad] = useState(false);

  const linkProps = isLocal
    ? {}
    : { target: "_blank", rel: "noopener noreferer" };

  return (
    <StyledArticleBrief size={size} showImage={showImage}>
      <div className="brief__content">
        {showImage && (
          <div className="brief__image">
            <img
              src={imageFailedToLoad ? backupImage : image}
              alt={title}
              onError={() => setImageFailedToLoad(true)}
            />
          </div>
        )}
        <div className="brief__description">
          {showInfo && (
            <div className="brief__info">
              {date &&
                new Date(date).toLocaleDateString(undefined, {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })}
              {location && <span> - {location}</span>}
            </div>
          )}
          <p className="brief__title">
            <Link to={href} {...linkProps}>
              <span dangerouslySetInnerHTML={{ __html: title }} />
            </Link>
          </p>
          <div className="brief__more">
            <Link to={href} {...linkProps}>
              <span>Read </span>
            </Link>
          </div>
          {showSnippet && (
            <div dangerouslySetInnerHTML={{ __html: snippet }} />
          )}
        </div>
      </div>
    </StyledArticleBrief>
  );
};

ArticleBrief.propTypes = {
  backupImage: PropTypes.string,
  date: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  isLocal: PropTypes.bool.isRequired,
  location: PropTypes.string,
  title: PropTypes.string.isRequired,
  showImage: PropTypes.bool,
  showSnippet: PropTypes.bool,
  size: PropTypes.oneOf(["large", "small", "medium", "wide", "wideFlex"]),
  snippet: PropTypes.string,
};

ArticleBrief.defaultProps = {
  isLocal: true,
  location: "",
  showInfo: false,
  showImage: false,
  showSnippet: false,
  size: "small",
  backupImage: "",
};

export default ArticleBrief;
