import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import classes from './ArticleGrid.module.scss';
import Link from 'components/layout/Link';

const renderHighlightArticleGrid = (articles, title) => {
  return (
    <div className={classnames(classes.grid, classes.highlight)}>
      <div className={classes.title}>{title}</div>
      <div className={classes.articles}>
        {articles.map(({
          title: articleTitle,
          smallImage,
          id,
        }, index) => (
          <div className={classes.article} key={id}>
            <img src={smallImage} alt={articleTitle} />
            <div className={classes.highlightIndex}>{index + 1}</div>
            <div>
              <div className={classes.title}>{articleTitle}</div>
              <div className={classes.link}>
                <Link withIcon to={`/resources/${id}`}>READ MORE</Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const ArticleGrid = ({articles, highlight, title}) => {

  if (highlight) {
    return renderHighlightArticleGrid(articles, title);
  }

  return (<div className={classes.grid}>
    <div className={classes.title}>{title}</div>
    <div className={classes.articles}>
      {articles.map(({
        smallImage,
        title: articleTitle,
        snippet,
        author,
        publishDate,
        readDuration,
        id,
      }) => (
        <div className={classes.article} key={id}>
          <img src={smallImage} alt={articleTitle} />
          <div>
            <div className={classes.date}>
              {new Date(publishDate).toLocaleDateString(undefined, {month: 'long', day: 'numeric', year: 'numeric'})}
            </div>
            <div className={classes.title}>
              <Link to={`/resources/${id}`}>
                {articleTitle}
              </Link>
            </div>
            <div className={classes.snippet}>{snippet}</div>
            <div className={classes.footer}>
              <span className={classes.author}>{author}</span>
              <span className={classes.readDuration}>{readDuration}</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>);
};

ArticleGrid.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.shape({
    author: PropTypes.string,
    id: PropTypes.string,
    publishDate: PropTypes.string,
    readDuration: PropTypes.string,
    smallImage: PropTypes.string,
    snippet: PropTypes.string,
    title: PropTypes.string,
  })),
  highlight: PropTypes.bool,
  title: PropTypes.string,
};

ArticleGrid.defaultProps = {
  highlight: false,
  title: '',
};

export default ArticleGrid;
