import { find } from "lodash";
import React, { useState } from "react";
import { StyledTeamsShowcase } from "./TeamsShowcase/styled";
import { ReactComponent as ChevronRight } from "assets/svg/chevron-right.svg";
import WebsiteTheme from "./theme/index";
import teamsImage from "assets/img/careers-teams-picture.jpg";

const teams = [
  {
    id: 0,
    name: "Engineering",
    description:
      "Complex problem solvers, imagineers, and creators tackling gnarly technical challenges and developing delightful customer experiences.",
  },
  {
    id: 1,
    name: "Product",
    description:
      "Encompassing product, product design, and analytics, we build, design, and measure the experiences that guide consumers across our marketplace, personalizing every step for their unique needs.",
  },
  {
    id: 2,
    name: "Marketing",
    description:
      "When your company mission is to support all people on their home journey, you need a passionate, customer-obsessed team. We meet the challenge by using data to shape storytelling, building our brand with intention, and delivering business growth.",
  },
  {
    id: 3,
    name: "Operations",
    description:
      "Enabling our vision of a more equitable real estate industry requires strategic planning and a mindful approach to our consumer. To support growth, you’ll establish and execute scalable processes, while relentlessly searching for ways to optimize the business.",
  },
  {
    id: 4,
    name: "Finance",
    description:
      "We are a team of finance, operations, and strategy professionals who bring financial transparency and strategic support to teams across the company. We use an analytical lens to drive decision making that enables a fast-growing, sustainable business model.",
  },
  {
    id: 5,
    name: "Legal",
    description:
      "It takes a skilled and agile team to ensure the highest corporate and ethical compliance at OJO, while maintaining business growth. In legal, you’ll support a variety of tasks, including VC financings, partner contracts, acquisitions, and intellectual property.",
  },
  {
    id: 6,
    name: "Real Estate",
    description:
      "We guide and support people throughout their real estate journey, through a fusion of human and machine intelligence. Help drive great experiences and success for consumers and agents with your creative thinking, versatility, and implementation skills.",
  },
  {
    id: 7,
    name: "Customer Success",
    description:
      "We create a mutually beneficial partnership between OJO and our top-performing agents by consistently providing results oriented guidance along key touchpoints in the agent journey.",
  },
  {
    id: 8,
    name: "Business Development",
    description:
      "Putting homeownership within reach of everyone is not a mission OJO can achieve alone. Help make progress toward this ambitious goal by forging strategic partnerships with like-minded businesses.",
  },
  {
    id: 9,
    name: "Revenue",
    description:
      "Our mission is to drive revenue growth for the company through consumer acquisition into our guided marketplace and leverage our market operations, performance analytics and program management teams in close collaboration with other teams across the business.",
  },
  {
    id: 10,
    name: "Brokerage",
    description:
      "We’re creating a best-in-class agent-centric platform that inspires experienced agents to operate and grow their business. We’re looking for experienced leaders in the real estate industry to help us create a differentiated experience for agents and brokers.",
  },
];

const TeamsShowcase = ({ desktopTitle, title }) => {
  const [selectedTeam, setSelectedTeam] = useState(teams[0]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const onChangeItem = (item) => {
    const newTeam = find(teams, (team) => item.id === team.id);
    setSelectedTeam(newTeam);
    setDropdownOpen(false);
  };

  const filteredList = teams.filter((item) => item !== selectedTeam);

  return (
    <StyledTeamsShowcase dropdownOpen={dropdownOpen}>
      <div className="showcase__center">
        <div className="showcase__wrapper">
          <div className="showcase__desktop-title">{desktopTitle}</div>
          <div className="showcase__title">{title}</div>
          <div className="showcase__select">
            <div
              className="showcase__selected-item"
              onClick={() => setDropdownOpen(!dropdownOpen)}
            >
              <span>
                {selectedTeam.name}
                <div className="showcase__chevron-icon">
                  <ChevronRight fill={WebsiteTheme.colors.springgreen} />
                </div>
              </span>
            </div>
            <div className="showcase__dropdown-list">
              {filteredList.map((team, index) => (
                <div
                  className="showcase__dropdown-item"
                  key={index}
                  onClick={() => onChangeItem(team)}
                >
                  {team.name}
                </div>
              ))}
            </div>
          </div>
          <div className="showcase__buttons">
            {teams.map((team, index) => (
              <div
                className={`showcase__button ${
                  team.id === selectedTeam.id && "showcase__button--selected"
                }`}
                key={index}
                onClick={() => onChangeItem(team)}
              >
                {team.name}
              </div>
            ))}
          </div>
          <div className="showcase__description">
            <h3>{selectedTeam.name}</h3>
            <p>{selectedTeam.description}</p>
          </div>
        </div>
        <div className="showcase__image">
          <img src={teamsImage} alt={title} />
        </div>
      </div>
    </StyledTeamsShowcase>
  );
};

export default TeamsShowcase;
