import { deviceScreenSizeQuery } from "components/theme/index";
import styled, { css } from "styled-components";

export const StyledExpandableListItem = styled.li`
  padding-bottom: 28px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.solitude};
  list-style: none;
  position: relative;

  + li {
    margin-top: 20px;
  }

  .expandable-list__item-title {
    transition: padding 500ms;
    cursor: pointer;
    padding-right: 3rem;
    margin-bottom: 0;
    font-size: 1.5rem;
    line-height: 2rem;
    color: ${({ theme }) => theme.colors.night};
    font-family: ${({ theme }) => theme.fonts.primary};
    position: relative;
  }

  .expandable-list__item-icon {
    position: absolute;
    right: 0;
    top: 0;

    svg {
      width: 1rem;
      transform: rotate(90deg);
    }
  }

  .expandable-list__item-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 500ms;
  }

  ${({ expanded }) =>
    expanded &&
    css`
      .expandable-list__item-title {
        padding-bottom: 28px;

        svg {
          transform: rotate(-90deg);
        }
      }

      .expandable-list__item-content {
        max-height: 400px;
      }
    `}

    @media ${deviceScreenSizeQuery.tablet} {
      .expandable-list__item-title { 
        font-size: 1.625rem;
        line-height: 2.375rem;
      }
    }
`;

export const StyledExpandableList = styled.ul`
  padding: 0;
  margin: 0;
`;
