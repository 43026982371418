import styled from "styled-components";

export const StyledBreadcrumbs = styled.div`
  color: ${({theme}) => theme.colors.raspberry};

  a {
    color: ${({theme}) => theme.colors.raspberry};
    
    svg {
      margin-right: 10px;
    }
  }
`;