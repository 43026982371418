export const faqOptions = [
  {
    label: 'All Topics',
    id: 'all',
  },
  {
    label: 'About OJO Home',
    id: 'about',
  },
  {
    label: 'Getting Started',
    id: 'getting-started',
  },
  {
    label: 'Finding the one',
    id: 'finding-the-one',
  },
  {
    label: 'Conversational UI',
    id: 'conversational-ui',
  },
  {
    label: 'Affordability',
    id: 'affordability',
  }
];

export const faqSections = {
  about: [
    {
      question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
      answer: 'Nullam tincidunt dolor tempor lectus sollicitudin, a malesuada lectus maximus. Mauris ornare lacinia ipsum, eget convallis enim suscipit vitae. Duis convallis commodo nulla nec porta. Etiam at leo ac quam pulvinar rutrum. Donec nisi nisl, interdum sit amet dignissim sed, semper non diam.',
    },
    {
      question: 'Lorem ipsum elit Nullam tincidunt dolor tempor lectus?',
      answer: 'Nullam tincidunt dolor tempor lectus sollicitudin, a malesuada lectus maximus. Mauris ornare lacinia ipsum, eget convallis enim suscipit vitae. Duis convallis commodo nulla nec porta. Etiam at leo ac quam pulvinar rutrum. Donec nisi nisl, interdum sit amet dignissim sed, semper non diam.',
    },
    {
      question: 'Sed et feugiat ligula?',
      answer: 'Nullam tincidunt dolor tempor lectus sollicitudin, a malesuada lectus maximus. Mauris ornare lacinia ipsum, eget convallis enim suscipit vitae. Duis convallis commodo nulla nec porta. Etiam at leo ac quam pulvinar rutrum. Donec nisi nisl, interdum sit amet dignissim sed, semper non diam.',
    },
    {
      question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam tincidunt dolor tempor lectus sollicitudin?',
      answer: 'Nullam tincidunt dolor tempor lectus sollicitudin, a malesuada lectus maximus. Mauris ornare lacinia ipsum, eget convallis enim suscipit vitae. Duis convallis commodo nulla nec porta. Etiam at leo ac quam pulvinar rutrum. Donec nisi nisl, interdum sit amet dignissim sed, semper non diam.',
    },
  ],
  'getting-started': [
    {
      question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
      answer: 'Nullam tincidunt dolor tempor lectus sollicitudin, a malesuada lectus maximus. Mauris ornare lacinia ipsum, eget convallis enim suscipit vitae. Duis convallis commodo nulla nec porta. Etiam at leo ac quam pulvinar rutrum. Donec nisi nisl, interdum sit amet dignissim sed, semper non diam.',
    },
    {
      question: 'Lorem ipsum elit Nullam tincidunt dolor tempor lectus?',
      answer: 'Nullam tincidunt dolor tempor lectus sollicitudin, a malesuada lectus maximus. Mauris ornare lacinia ipsum, eget convallis enim suscipit vitae. Duis convallis commodo nulla nec porta. Etiam at leo ac quam pulvinar rutrum. Donec nisi nisl, interdum sit amet dignissim sed, semper non diam.',
    },
    {
      question: 'Sed et feugiat ligula?',
      answer: 'Nullam tincidunt dolor tempor lectus sollicitudin, a malesuada lectus maximus. Mauris ornare lacinia ipsum, eget convallis enim suscipit vitae. Duis convallis commodo nulla nec porta. Etiam at leo ac quam pulvinar rutrum. Donec nisi nisl, interdum sit amet dignissim sed, semper non diam.',
    },
    {
      question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam tincidunt dolor tempor lectus sollicitudin?',
      answer: 'Nullam tincidunt dolor tempor lectus sollicitudin, a malesuada lectus maximus. Mauris ornare lacinia ipsum, eget convallis enim suscipit vitae. Duis convallis commodo nulla nec porta. Etiam at leo ac quam pulvinar rutrum. Donec nisi nisl, interdum sit amet dignissim sed, semper non diam.',
    },
  ],
  'finding-the-one': [
    {
      question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
      answer: 'Nullam tincidunt dolor tempor lectus sollicitudin, a malesuada lectus maximus. Mauris ornare lacinia ipsum, eget convallis enim suscipit vitae. Duis convallis commodo nulla nec porta. Etiam at leo ac quam pulvinar rutrum. Donec nisi nisl, interdum sit amet dignissim sed, semper non diam.',
    },
    {
      question: 'Lorem ipsum elit Nullam tincidunt dolor tempor lectus?',
      answer: 'Nullam tincidunt dolor tempor lectus sollicitudin, a malesuada lectus maximus. Mauris ornare lacinia ipsum, eget convallis enim suscipit vitae. Duis convallis commodo nulla nec porta. Etiam at leo ac quam pulvinar rutrum. Donec nisi nisl, interdum sit amet dignissim sed, semper non diam.',
    },
    {
      question: 'Sed et feugiat ligula?',
      answer: 'Nullam tincidunt dolor tempor lectus sollicitudin, a malesuada lectus maximus. Mauris ornare lacinia ipsum, eget convallis enim suscipit vitae. Duis convallis commodo nulla nec porta. Etiam at leo ac quam pulvinar rutrum. Donec nisi nisl, interdum sit amet dignissim sed, semper non diam.',
    },
    {
      question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam tincidunt dolor tempor lectus sollicitudin?',
      answer: 'Nullam tincidunt dolor tempor lectus sollicitudin, a malesuada lectus maximus. Mauris ornare lacinia ipsum, eget convallis enim suscipit vitae. Duis convallis commodo nulla nec porta. Etiam at leo ac quam pulvinar rutrum. Donec nisi nisl, interdum sit amet dignissim sed, semper non diam.',
    },
  ],
  'conversational-ui': [
    {
      question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
      answer: 'Nullam tincidunt dolor tempor lectus sollicitudin, a malesuada lectus maximus. Mauris ornare lacinia ipsum, eget convallis enim suscipit vitae. Duis convallis commodo nulla nec porta. Etiam at leo ac quam pulvinar rutrum. Donec nisi nisl, interdum sit amet dignissim sed, semper non diam.',
    },
    {
      question: 'Lorem ipsum elit Nullam tincidunt dolor tempor lectus?',
      answer: 'Nullam tincidunt dolor tempor lectus sollicitudin, a malesuada lectus maximus. Mauris ornare lacinia ipsum, eget convallis enim suscipit vitae. Duis convallis commodo nulla nec porta. Etiam at leo ac quam pulvinar rutrum. Donec nisi nisl, interdum sit amet dignissim sed, semper non diam.',
    },
    {
      question: 'Sed et feugiat ligula?',
      answer: 'Nullam tincidunt dolor tempor lectus sollicitudin, a malesuada lectus maximus. Mauris ornare lacinia ipsum, eget convallis enim suscipit vitae. Duis convallis commodo nulla nec porta. Etiam at leo ac quam pulvinar rutrum. Donec nisi nisl, interdum sit amet dignissim sed, semper non diam.',
    },
    {
      question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam tincidunt dolor tempor lectus sollicitudin?',
      answer: 'Nullam tincidunt dolor tempor lectus sollicitudin, a malesuada lectus maximus. Mauris ornare lacinia ipsum, eget convallis enim suscipit vitae. Duis convallis commodo nulla nec porta. Etiam at leo ac quam pulvinar rutrum. Donec nisi nisl, interdum sit amet dignissim sed, semper non diam.',
    },
  ],
  'affordability': [
    {
      question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
      answer: 'Nullam tincidunt dolor tempor lectus sollicitudin, a malesuada lectus maximus. Mauris ornare lacinia ipsum, eget convallis enim suscipit vitae. Duis convallis commodo nulla nec porta. Etiam at leo ac quam pulvinar rutrum. Donec nisi nisl, interdum sit amet dignissim sed, semper non diam.',
    },
    {
      question: 'Lorem ipsum elit Nullam tincidunt dolor tempor lectus?',
      answer: 'Nullam tincidunt dolor tempor lectus sollicitudin, a malesuada lectus maximus. Mauris ornare lacinia ipsum, eget convallis enim suscipit vitae. Duis convallis commodo nulla nec porta. Etiam at leo ac quam pulvinar rutrum. Donec nisi nisl, interdum sit amet dignissim sed, semper non diam.',
    },
    {
      question: 'Sed et feugiat ligula?',
      answer: 'Nullam tincidunt dolor tempor lectus sollicitudin, a malesuada lectus maximus. Mauris ornare lacinia ipsum, eget convallis enim suscipit vitae. Duis convallis commodo nulla nec porta. Etiam at leo ac quam pulvinar rutrum. Donec nisi nisl, interdum sit amet dignissim sed, semper non diam.',
    },
    {
      question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam tincidunt dolor tempor lectus sollicitudin?',
      answer: 'Nullam tincidunt dolor tempor lectus sollicitudin, a malesuada lectus maximus. Mauris ornare lacinia ipsum, eget convallis enim suscipit vitae. Duis convallis commodo nulla nec porta. Etiam at leo ac quam pulvinar rutrum. Donec nisi nisl, interdum sit amet dignissim sed, semper non diam.',
    },
  ],
};

const agentBrokersOptions = [
  {
    label: 'All Topics',
    id: 'all',
  },
  {
    label: 'Agents',
    id: 'agents',
  },
  {
    label: 'Teams',
    id: 'teams',
  },
];

const agentBrokersSections = {
  agents: [
    {
      question: 'What is the OJO Select Network?',
      answer: 'The OJO Select Network is bringing together the country\'s top agents to build a way to better meet homebuyers and sellers needs throughout their journey. This program provides agents with introductions to fully-screened consumers, which enables agents to do what they do best - help people close on the right home. Through highly-personalized guidance, OJO nurtures buyers and sellers, learning what\'s important to them along the way and helping them become prepared to close.',
    },
    {
      question: 'What differentiates OJO from other lead-generating platforms?',
      answer: 'OJO incorporates patented AI-powered technology to create a personalized nurturing environment for homebuyers and sellers. OJO keeps buyers engaged with custom insights and tailored home recommendations based on a buyer’s preferences. By combining human intelligence with machine learning, OJO can help increase buyer preparedness and assess readiness to purchase.',
    },
    {
      question: 'Where does OJO source its leads from?',
      answer: 'OJO sources leads through multiple media channels across websites, search platforms, and other digital advertising platforms. Once a potential client has requested information, signed up or submitted their information, a highly-personalized experience begins.',
    },
    {
      question: 'What are the preferred eligibility requirements to participate?',
      answer: 'We prefer agents who have had 25 transactions in the last 12 months and at least 3 years of full-time experience. We are interested in working with agents who provide excellent customer service, have fast response times, and high conversion rates.',
    },
    {
      question: 'How long does the application and onboarding process take?',
      answer: `The application should take less than 10 minutes to complete. Once we’ve confirmed availability in the areas you service, someone will reach out to begin the onboarding process. Please note that acceptance is subject to availability in one or more of the areas you’ve indicated you’re able to service.  Wait times will vary depending on location.
      You can check the status of your application <a href="https://applicationstatus.ojo.com/s/" target="_blank" rel="noopener noreferrer">here</a>.`,
    },
    {
      question: 'Can I still sign up if the OJO Select Network isn’t available yet in my market?',
      answer: 'If you meet the eligibility criteria above, you are still encouraged to apply for the program. As we expand the OJO Select Network, we will notify you if and when it is available in your market.',
    },
    {
      question: 'What do I do if I determine I cannot help the homebuyer or seller I\'ve been introduced to?',
      answer: 'You can communicate that through your Agent Dashboard or your appointed Concierge so we can assist in transitioning the client.',
    },
    {
      question: 'How does the OJO Concierge team help me and my client?',
      answer: 'Once you are matched with a buyer or seller, we will assign a dedicated OJO Concierge. The Concierge stays in communication with both parties throughout the process, helps keep the buyer or seller\'s profile up to date, and provides support as needed to ensure a positive experience for everyone.',
    },
    {
      question: 'Are these leads being sent to more than one agent?',
      answer: 'Leads are initially sent to up to 5 agents and the first one to respond to the lead notification wins the introduction. Once you win a lead, it will not be shared with other agents. However, if you don’t accept the initial introduction in a timely manner, it will be transferred to another agent. ',
    },
    {
      question: 'What is the cost to participate in the OJO Select Network?',
      answer: 'There is no up-front cost to participate in the program, nor do we charge per lead. OJO collects a referral fee of up to 35% after each successful closing. Referral fees vary by market or transaction value. Please contact <a href="mailto:selectnetworkinquiry@ojolabs.com" target="_blank" rel="noopener noreferrer">selectnetworkinquiry@ojolabs.com</a>.',
    },
    {
      question: 'What makes agents successful in the OJO Select Network?',
      answer: 'We prioritize introductions to the agents who are most responsive, provide a best-in-class experience for clients, and have the highest close rates. We closely monitor CSAT scores and close rates to ensure that homebuyers and sellers have the best service throughout their real estate journey.',
    },
  ],
  teams: [
    {
      question: 'Does the OJO Select Network accept teams?',
      answer: 'Yes! You’re welcome to apply as a team. Remember, future introductions will be based on customer satisfaction, conversion rates and responsiveness, so only assign your very best team members to receive introductions.',
    },
    {
      question: 'Is there a minimum or maximum number of team members who can participate?',
      answer: 'There is no minimum or maximum number of team members who can participate in the program. However, since customer satisfaction, conversion rates and response times impact our ability to send you future introductions, please only recommend your best team members.',
    },
    {
      question: 'What is the role of the Lead Receiver? How should I best select that person?',
      answer: 'The Lead Receiver is a member on your team who you will appoint to receive the opportunities from OJO via call or text. Be sure to choose an individual who is highly responsive and willing to pick up the phone quickly and often. ',
    },
    {
      question: 'How does lead routing through my appointed Lead Receiver work?',
      answer: 'Once the Lead Receiver has accepted an OJO lead and our Customer Experience Representative has assigned them in the OJO system, the Lead Receiver will be able to go into their Agent Dashboard and reassign that lead to someone on their team.',
    },
    {
      question: 'Do all the members on my team have to meet the same preferred eligibility criteria?',
      answer: 'No. As long as you meet the eligibility criteria of a minimum of 25 transactions in the last 12 months and at least 3 years of experience, then you may select whichever team members you think are best suited to participate. Keep in mind that this program is created for top producers and your top-performing agents will fare the best.',
    },
    {
      question: 'If a member of my team fails to claim a lead in a timely manner, will that lead be transferred to another agent on the team or sent back to the greater pool of agents?',
      answer: 'Agents are called on a first come, first served basis in response to the offer text message. If the Lead Receiver accepts the lead but does not answer the call, then our team will move on to the next agent (not on your team) who responded to the offer text message. The team relies on the Lead Receiver to both accept the lead and answer the call for the team to be connected via a live introduction to the customer.',
    },
  ],
};

export default {
  default: {
    options: faqOptions,
    pageTitle: 'OJO Select Network Frequently Asked Questions',
    pageMetaDescription: 'Have questions about the OJO Select Network? Find answers here.',
    sections: faqSections,
    title: 'OJO Select Network Frequently Asked Questions',
    defaultHighlight: 'about',
  },
  'agent-teams': {
    options: agentBrokersOptions,
    pageTitle: 'OJO Select Network Frequently Asked Questions',
    pageMetaDescription: 'Have questions about the OJO Select Network? Find answers here.',
    sections: agentBrokersSections,
    title: 'FAQs',
    defaultHighlight: 'agents',
  }
};
