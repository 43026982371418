import React, { useState } from "react";

import data from "util/data";
import useDocumentTitle from "hooks/documentTitle";
import useMetaDescription from "hooks/metaDescription";
import useMetaKeywords from "hooks/metaKeywords";
import PersonTile from "components/tiles/PersonTile";
import PageWrapper from "components/layout/PageWrapper";
import PageFeature, { PageFeatureCTAFirst } from "components/layout/PageFeature";
import Section from "components/Section";
import FeaturedSection from "components/FeaturedSection";
import LogoList from "components/LogoList";
import Row from "components/layout/Row";
import Column from "components/layout/Column";
import TestimonialBubble from "components/bubbles/TestimonialBubble";
import LeadershipGrid from "components/layout/LeadershipGrid";
import BioModal from "components/modals/BioModal";
import Button from "components/forms/Button";
import Tabs from "components/Tabs";

import whatIsOjoImage from "assets/img/whats-ojo-house.png";
import ourStoryImage from "assets/img/about-us-hero.jpg";
import ourCultureImage from "assets/img/our-culture-section.jpg";
import careersImage from "assets/img/careers-at-ojo.jpg";

const EXECUTIVE_TAB = "executive-team";
const BOARD_TAB = "board-of-directors";

const AboutUs = () => {
  useDocumentTitle(
    "Learn more about what we do at OJO and why"
  );
  useMetaDescription(
    "Learn more about OJO, our company, our purpose, our culture, and how we’re working to make homeownership more accessible to more people."
  );
  useMetaKeywords("About OJO");

  const [leadershipTab, setLeaderShipTab] = useState(EXECUTIVE_TAB);
  const [bioModalOpen, setBioModalOpen] = useState(false);
  const [bioModalProps, setBioModalProps] = useState({
    name: "",
    title: "",
    bio: "",
  });

  return (
    <>
      <PageWrapper backgroundColor="emerald" marginBottom={0}>
        <PageFeature
          supertext="Our Story"
          title="Imagine a world where everyone has what they need to own a home."
          description="We know homeownership means more than just owning property — it unlocks an abundance of financial, social, and emotional benefits. But we also know that the real estate industry doesn’t always reflect that. For many, the process of buying, selling, or owning a home is devoid of partnership, empathy, and equity — everything owning a home should be. At OJO, we’re changing that. By your side for every moment in your home journey, we get to know where you are and provide you with guidance so you feel confident taking that next step."
          image={ourStoryImage}
          imageWidthWrap
          negative
          reversed
        />
      </PageWrapper>

      <FeaturedSection
        title="What is OJO?"
        backgroundColor="forest"
        description="OJO is an Austin-based real estate technology company focused on improving homeownership. We help consumers on their home journey by enabling them to customize their search, connect them with industry experts, and equip them with financial tools so they feel prepared to buy, sell, or own."
        marginTop={100}
        center
        negative
        small
      >
        <img src={whatIsOjoImage} alt="" />
      </FeaturedSection>

      <Section title="Our Leadership" center>
        <Tabs>
          <Button
            emerald
            {...(leadershipTab === EXECUTIVE_TAB && { negative: true })}
            onClick={() => setLeaderShipTab(EXECUTIVE_TAB)}
          >
            Executive Team
          </Button>
          <Button
            emerald
            {...(leadershipTab === BOARD_TAB && { negative: true })}
            onClick={() => setLeaderShipTab(BOARD_TAB)}
          >
            Board of Directors
          </Button>
        </Tabs>
        <LeadershipGrid>
          {(leadershipTab === EXECUTIVE_TAB
            ? data.leadership
            : data.boardOfDirectors
          ).map((leader) => (
            <PersonTile
              {...leader}
              key={leader.name}
              link="Read"
              onLinkClick={(e) => {
                e.preventDefault();
                setBioModalProps({
                  name: leader.name,
                  title: leader.title,
                  bio: leader.bio,
                  linkedInUrl: leader.linkedInUrl,
                });
                setBioModalOpen(true);
              }}
            />
          ))}
        </LeadershipGrid>
      </Section>

      <PageWrapper backgroundColor="emerald">
        <PageFeature
          title="Our Culture"
          description={
            <>
              Recognized as a great place to work for our culture, growth, and
              leadership, our headquarters are in the heart of Austin’s South
              Congress District, with team members also in Chicago, Minneapolis,
              Silicon Valley, and Vieux Fort, St. Lucia. <br /> <br />{" "}
              Cultivating an award-winning work culture, our teams collaborate
              to level the playing field in real estate and equip our consumers
              with what they need to be a successful homeowner.
            </>
          }
          negative
          image={ourCultureImage}
          reversed
          minHeight={456}
        />
      </PageWrapper>

      <Section backgroundColor="white" center>
        <Row marginBottom={50} maxWidth={1080} center>
          <Column size={3}>
            <TestimonialBubble
              name="Business Insider"
              quotation="OJO, which guides users through every step of the homebuying and selling process, has raised $180 million and grown to more than 700 employees across several countries."
            />
          </Column>
          <Column size={3}>
            <TestimonialBubble
              name="Inc."
              quotation="Unlike static platforms that simply aggregate property data for users to search through, OJO combines a deep understanding of the market with insights into users’ individual preferences and needs to deliver dynamic, meaningful recommendations."
            />
          </Column>
        </Row>
      </Section>

      <Section center title="Our Investors" backgroundColor="white">
        <div style={{ maxWidth: "1044px", margin: "0 auto" }}>
          <LogoList>
            <img
              src="/img/3rd-party-logos/investor-breyer-capital.png"
              alt="Breyer Capital logo"
            />
            <img
              src="/img/3rd-party-logos/investor-liveoak-venture-partners.png"
              alt="LiveOak logo"
            />
            <img
              src="/img/3rd-party-logos/investor-northwestern-mutual.png"
              alt="Northwestern Mutual logo"
            />
            <img
              src="/img/3rd-party-logos/investor-rbc-royal-bank.png"
              alt="RBC Royal Bank logo"
            />
            <img
              src="/img/3rd-party-logos/investor-anywhere.png"
              alt="Anywhere logo"
            />
            <img
              src="/img/3rd-party-logos/investor-service-master.png"
              alt="ServiceMaster logo"
            />
            <img
              src="/img/3rd-party-logos/investor-silverton-partners.png"
              alt="Silverton Partners logo"
            />
            <img
              src="/img/3rd-party-logos/investor-wafra.png"
              alt="Wafra logo"
            />
          </LogoList>
        </div>
      </Section>

      <PageWrapper backgroundColor="concrete">
        <PageFeatureCTAFirst
          title="Careers at OJO"
          description="Equipping people with what they need to be successful homeowners starts with an exceptional team. Interested in helping us make a difference? Check out our open positions."
          ctaLink="/careers"
          ctaText="Find Your Role"
          ctaLinkProps={{ target: "_blank", rel: "nofollow" }}
          image={careersImage}
          minHeight={320}
        />
      </PageWrapper>
      <hr />
      <BioModal
        isOpen={bioModalOpen}
        close={() => setBioModalOpen(false)}
        {...bioModalProps}
      />
    </>
  );
};

export default AboutUs;
