import styled, { css } from "styled-components";
import { deviceScreenSizeQuery } from "components/theme/index";

export const StyledSection = styled.div`
  padding: 40px 38px;
  overflow: hidden;

  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${({ theme }) => theme.colors[backgroundColor]};
    `}

  @media ${deviceScreenSizeQuery.tablet} {
    ${({marginBottom}) => marginBottom && css`
      margin-bottom: ${marginBottom}px;
    `}
  }  

  @media ${deviceScreenSizeQuery.laptop} {
    padding: 60px 68px;
  }

  ${({ underlined }) =>
    underlined &&
    css`
      padding-bottom: 0;

      @media ${deviceScreenSizeQuery.laptop} {
        padding-bottom: 0;
      }
    `}

  .section__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
  }

  .section__header {
    margin-left: auto;
    margin-right: auto;
  }

  .section__wrapper {
    margin-left: auto;
    margin-right: auto;

    ${({ underlined }) =>
      underlined &&
      css`
        :last-child {
          padding-bottom: 60px;
          border-bottom: 1px solid ${({ theme }) => theme.colors.shark};
        }
      `}

    @media (min-width: 768px) {
      margin: 0 auto;
    }

    @media (min-width: 1215px) {
      margin: 0 auto;
      max-width: 1304px;
      width: 100%;
    }

    ${({maxWidth}) => maxWidth && css`
      max-width: ${maxWidth}px;

      @media (min-width: 1215px) {
        max-width: ${maxWidth}px;
      }
    `}
  }

  .section__title {
    font-size: 2.75rem;
    line-height: 3.25rem;
    margin-bottom: 1.275rem;
    color: ${({ theme }) => theme.colors.emerald};

    ${({ center }) =>
      center &&
      css`
        text-align: center;
      `}

    @media ${deviceScreenSizeQuery.tablet} {
      font-size: 3.25rem;
      line-height: 4.25rem;
    }

    ${({ negative }) =>
      negative &&
      css`
        color: ${({ theme }) => theme.colors.seafoam};
      `}

    ${({ center }) =>
      center &&
      css`
        @media ${deviceScreenSizeQuery.tablet} {
          margin-left: auto;
          margin-right: auto;
          max-width: 1080px;
          text-align: center;
        }
      `}
  }

  .section__description {
    font-size: 1.125rem;
    line-height: 1.625rem;
    margin-bottom: 30px;
    max-width: 1080px;

    h3 {
      font-size: 2rem;
      line-height: 3rem;
      display: block;
      color: ${({ theme }) => theme.colors.seafoam};
    }

    ${({ negative }) =>
      negative &&
      css`
        color: ${({ theme }) => theme.colors.white};
      `}

    ${({ center }) =>
      center &&
      css`
        @media ${deviceScreenSizeQuery.tablet} {
          margin-left: auto;
          margin-right: auto;
          text-align: center;
        }
      `}
  }

  ${({ split }) =>
    split &&
    css`
      text-align: center;

      .section__description {
        text-align: left;
      }

      .section__title {
        margin-bottom: 40px;
      }

      @media ${deviceScreenSizeQuery.tablet} {
        text-align: left;

        .section__wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        .section__split {
          padding: 0 60px;
        }

        .section__wrapper {
          width: auto;
        }
      }
    `}

  .vimeo-video {
    border-radius: 20px;
    padding: 56.25% 0 0 0;
    position: relative;
    z-index: 0;
  }  

  ${({ video }) => video && css`
    padding-top: 100px;
    padding-bottom: 100px;

    .section__description {
      margin-bottom: 60px;
      text-align: center;
    }

    .section__title {
      text-align: center;
    }

  ` }

  ${({ fullWidth }) => fullWidth && css`
    padding: 40px 0;

    @media ${deviceScreenSizeQuery.laptop} {
      padding: 60px 0;
    }

    .section__header,
    .section__description,
    .section__footer {
      max-width: 1440px;
      padding-left: 38px;
      padding-right: 38px;
      margin-left: auto;
      margin-right: auto;

      @media ${deviceScreenSizeQuery.tablet} {
        padding-left: 68px;
        padding-right: 68px;
      }
    }

    .section__footer {
      justify-content: flex-start;
      margin-top: 0;
    }

    .section__wrapper {
      max-width: unset;
      padding-left: 0;
      padding-right: 0;

      @media (min-width: 768px) {
        margin: 0 auto;
      }

      @media (min-width: 1215px) {
        max-width: unset;
      }
    }
  `}

`;
