import React from 'react';
import PropTypes from 'prop-types';

import { StyledBigTextTile } from './BigTextTile/styled';

const BigTextTile = ({ image, text, header }) => {
  return (
  <StyledBigTextTile>
    {image && <img src={image} alt={header} />}
    <div className="big-text-tile__header">
      {header}
    </div>
    <p>{text}</p>
  </StyledBigTextTile>
  );
};

BigTextTile.propTypes = {
  header: PropTypes.string.isRequired,
  image: PropTypes.string,
  text: PropTypes.node.isRequired,
};

BigTextTile.defaultProps = {
  image: '',
};

export default BigTextTile;
