import { deviceScreenSizeQuery } from "components/theme/index";
import styled, { css } from "styled-components";

export const StyledSelect = styled.div`
  height: 52px;
  width: 100%;
  min-width: 160px;
  border-radius: 26px;
  background-color: ${({ theme }) => theme.colors.emerald};
  display: inline-block;
  padding: 12px 24px;
  box-sizing: border-box;
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.emerald};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};

  .options {
    border: 1px solid ${({ theme }) => theme.colors.solitude};
  }

  .options {
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    opacity: 0;
    max-height: 0;
    border-radius: 26px;
    background-color: ${({ theme }) => theme.colors.white};
    overflow: hidden;
    z-index: 1000;

    ${({ expanded }) =>
      expanded &&
      css`
        opacity: 1;
        max-height: 300px;
        overflow: auto;

        @media (min-width: ${deviceScreenSizeQuery.tablet}) {
          max-height: 600px;
        }
      `}
  }

  .option {
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 24px;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    transition: background-color .3s ease, color .3s ease;

    :hover {
      background-color: ${({ theme }) => theme.colors.raspberry};
      color: ${({ theme }) => theme.colors.white};
    }

    &.selected {
      color: ${({ theme }) => theme.colors.emerald};
    }

    .checkmark {
      padding-left: 10px;
      font-size: 1rem;
    }

    .collapseButton {
      justify-self: flex-end;
      margin-left: auto;
      width: 16px;
      transform: rotate(-90deg);
    }

    &.placeholder {
      cursor: default;
    }
  }

  .select__selected {
    color: ${({ theme }) => theme.colors.white};
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    line-height: 1.625rem;

    &.noHighlight {
      color: ${({ theme }) => theme.colors.emerald};
    }

    svg {
      transform: rotate(90deg);
      width: 16px;
    }
  }
`;
