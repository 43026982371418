import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HashLink as RouterLink } from 'react-router-hash-link';

import classes from './Link.module.scss';

const Link = ({ children, className, icon, inverse, to, withIcon, href, ...rest }) => {

  let Tag = 'a';
  if (to && !to.startsWith('http') && !to.startsWith('mailto')) {
    Tag = RouterLink;
  }

  return (<span className={classnames(className, classes.link, {
    [classes.inverse]: inverse,
    [classes.withIcon]: withIcon,
  })}>
    <Tag to={to} href={to} {...rest}>
      {children}
      {withIcon && <span className={classes.icon}><FontAwesomeIcon icon={icon} /></span>}
    </Tag>
  </span>);
};

Link.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.string,
  inverse: PropTypes.bool,
  to: PropTypes.string,
  withIcon: PropTypes.bool,
};

Link.defaultProps = {
  children: null,
  className: '',
  icon: 'arrow-circle-right',
  inverse: false,
  to: '',
  href: '',
  withIcon: false,
};

export default Link;