import React, { useEffect, useState } from "react";

import useDocumentTitle from "hooks/documentTitle";
import useMetaDescription from "hooks/metaDescription";
import useMetaKeywords from "hooks/metaKeywords";

import ArticleBrief from "components/layout/articles/ArticleBrief";
import Button from "components/forms/Button";
import PageWrapper from "components/layout/PageWrapper";
import PageHeader from "components/layout/PageHeader";
import PageFooter from "components/layout/PageFooter";
import Paginator from "components/Paginator";

import { getPressList } from "util/contentfulApi";

const PAGE_SIZE = 10;

const CompanyNews = ({ history, match }) => {
  useDocumentTitle("Real estate reports and announcements from OJO");
  useMetaDescription(
    "Read the latest announcements and reports from OJO."
  );
  useMetaKeywords("Press resources");

  const pageNumber = match.params.pageNumber
    ? Number.parseInt(match.params.pageNumber, 10)
    : 0;
  const [totalPages, setTotalPages] = useState(1);
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    getPressList('news-room', PAGE_SIZE, pageNumber * PAGE_SIZE).then(
      (data) => {
        setArticles(data.articles);
        setTotalPages(Math.ceil(data.total / PAGE_SIZE));
      }
    );
  }, ['news-room', pageNumber]);

  const breadcrumbsLinks = [
    {
      to: "/media-room",
      text: "Media Room",
    },
  ];

  return (
    <PageWrapper center backgroundColor="white">
      <PageHeader breadcrumbsLinks={breadcrumbsLinks} title="Company News" />

      {articles.map((article, index) => (
        <ArticleBrief size="large" showInfo showSnippet {...article} key={index}/ >
      ))}
      
      <PageFooter>
        <Paginator
          totalPages={totalPages}
          pageNumber={pageNumber}
          onPageChange={(data) => {
            if (data.selected !== pageNumber) {
              // todo: check this
              history.push(`/company-news/${data.selected}`);
            }
          }}
        />
        <Button secondary solid to="/media-room">
          Return to Media Room
        </Button>
      </PageFooter>
    </PageWrapper>
  );
};

export default CompanyNews;
