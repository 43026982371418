import React from "react";
import { StyledLeadershipGrid } from "./LeadershipGrid/styled";

const LeadershipGrid = ({ children }) => {
  return (
    <StyledLeadershipGrid>
      { children }
    </StyledLeadershipGrid>
  );
};

export default LeadershipGrid;
