import { deviceScreenSizeQuery } from "components/theme/index";
import styled from "styled-components";

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  padding: 0 24px;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  box-sizing: border-box;
  height: 64px;
  justify-content: center;
  transition: background-color 500ms ease-in-out, box-shadow 250ms ease-in-out;
  background-color: ${({ theme }) => theme.colors.emerald};
  position: fixed;
  top: 0;

  .logo-link {
    display: flex;
  }

  .logo {
    width: 64px;
    position: relative;
    top: 3px;
  }

  @media ${deviceScreenSizeQuery.tablet} {
    padding: 0 68px;
    height: 100px;
    transform: none;

    .logo {
      height: 32px;
    }
  }

  .dropdown {
    padding: 10px 0 10px;
  }

  .dropdownToggle {
    padding: 10px 0 10px;
  }

  .dropdownToggle::after {
    display: none;
  }

  .dropdownIcon {
    margin-left: 4px;
    color: ${({theme}) => theme.colors.springgreen};
    vertical-align: middle;
  }

  .mobileMenuButton {
    font-size: 1.5rem;
    display: flex;
    align-items: flex-start;

    @media ${deviceScreenSizeQuery.tablet} {
      display: none;
    }
  }
`;

const StyledHeaderContent = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  max-width: 1304px;
  gap: 40px;
`;

const StyledNavigation = styled.nav`
  margin-left: auto;
  display: inline-flex;
  align-items: center;

  a {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }

  @media ${deviceScreenSizeQuery.tablet} {
    display: inline-flex;

    a {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
  }
`;

const StyledDropdown = styled.div`
  margin-left: auto;

  .dropdownMenu {
    box-shadow: 0px 4px 8px rgba(1, 11, 26, 0.12);
    border-radius: 12px;
    border: none;
    min-width: 145px;
    padding: 0;

    a {
      padding: 8px 12px;
      font-size: 1.125rem;
      line-height: 1.5rem;

      &:first-child {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
      }

      &:last-child {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
      }
    }

    a:hover {
      color: $white !important;
      background-color: $aubergine;
    }
  }

  .dropdown {
    padding: 10px 0 10px;
  }

  .dropdownToggle {
    padding: 10px 0 10px;
  }

  .dropdownToggle::after {
    display: none;
  }

  .dropdownIcon {
    margin-left: 4px;
    color: ${({ theme }) => theme.colors.springgreen};
    vertical-align: middle;
  }
`;

const StyledMobileNavigation = styled.div`
  background-color: ${({ theme }) => theme.colors.emerald};
  padding: 0 0 42px 0;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  box-shadow: 6px 6px 19px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;

  a,
  a:visited,
  a:focus,
  a:hover,
  a:active,
  .mobile-nav__item-text {
    font-size: 1.375rem;
    line-height: 1.75rem;
    color: ${({ theme }) => theme.colors.white};
    text-decoration: none;
  }

  .logo {
    width: 64px;
    position: relative;
    top: 3px;
  }

  button {
    width: 100%;
  }

  .button {
    margin-bottom: 18px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .buttons {
    padding: 0 50px;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    align-self: flex-end;
  }

  .mobile-nav__header {
    display: flex;
    padding: 0 24px;
    height: 64px;
    align-items: center;
  }

  .mobile-nav__close {
    margin-left: auto;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 50%;
    font-size: 1.25rem;
  }

  .mobile-nav__items {
    padding: 10px 0 30px;
    text-align: left;
  }

  .mobile-nav__item {
    padding: 20px 38px;
    cursor: pointer;
    display: flex;

    .caret {
      margin-left: 5px;
      transition: transform 100ms;
    }

    &.expanded {
      margin-bottom: 0;
      padding-bottom: 20px;

      .caret {
        transform: rotate(180deg);
      }
    }
  }

  .mobile-nav__sub-menu {
    padding: 0;
    background: ${({ theme }) => theme.colors.white};
    height: 0;
    overflow: hidden;

    .mobile-nav__item {
      a,
      a:visited,
      a:focus,
      a:hover,
      a:active,
      .mobile-nav__item-text {
        color: ${({ theme }) => theme.colors.night};
      }
    }

    &.expanded {
      padding: 20px 0;
      height: auto;
    }
  }

  &.menuEnter {
    transform: translateX(100%);
  }

  &.menuEnterActive {
    transform: translateX(0);
    transition: transform 300ms;
  }

  &.menuExit {
    transform: translateY(0);
  }

  &.menuExitActive {
    transform: translateX(100%);
    transition: transform 300ms;
  }
`;

export {
  StyledHeader,
  StyledHeaderContent,
  StyledNavigation,
  StyledDropdown,
  StyledMobileNavigation,
};
