import styled, { css } from "styled-components";
import { deviceScreenSizeQuery } from "components/theme/index";

export const StyledPageWrapper = styled.div`
  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${({ theme }) => theme.colors[backgroundColor]};
    `}

  padding-bottom: 60px;

  .page-wrapper__container {
    padding: 60px 38px 0 38px;
    max-width: 1440px;

    + * {
      padding-top: 60px;
    }

    ${({ center }) =>
      center &&
      css`
        max-width: 932px;
      `}
  }

  & + & {
    padding-top: 0;

    .page-wrapper__container {
      padding-top: 0;
    }

    @media ${deviceScreenSizeQuery.tablet} {
      padding-top: 0;

      .page-wrapper__container {
        padding-top: 40px;
      }
    }
  }

  @media ${deviceScreenSizeQuery.tablet} {
    .page-wrapper__container {
      padding: 60px 38px 0 38px;
      margin: 0 auto;

      + * {
        padding-top: 100px;
      }

      ${({ center }) =>
        center &&
        css`
          max-width: 992px;
        `}
    }

    ${({marginBottom}) => marginBottom && css`
      margin-bottom: ${marginBottom}px;
    `}
  }

  @media ${deviceScreenSizeQuery.laptop} {
    .page-wrapper__container {
      padding: 60px 68px 0 68px;
      margin: 0 auto;
    }
  }
`;

export const StyledHollowPageWrapper = styled.div`
  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${({ theme }) => theme.colors[backgroundColor]};
    `}
`;
