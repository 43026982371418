import React, { useEffect, useState } from 'react';

import { getSeoHomepage } from 'util/contentfulApi';
import useDocumentTitle from 'hooks/documentTitle';
import useMetaDescription from 'hooks/metaDescription';
import classes from './SEOHomepage.module.scss';
import Link from 'components/layout/Link';
import ArticleGrid from 'components/seo/ArticleGrid';
import SeoSignup from 'components/seo/SeoSignup';
import SeoTool from 'components/SeoTool';

const SEOHomepage = () => {
  const [data, setData] = useState(null);
  useDocumentTitle(data ? data.seoTitleTag : '');
  useMetaDescription(data ? data.seoMetaDescription : '');

  useEffect(() => {
    getSeoHomepage().then(homepageData => {
      setData(homepageData);
    });
  }, []);

  if (!data) {
    return (
      <div className={classes.page}>
        Loading...
      </div>
    );
  }

  const {
    featuredArticle,
    articleGrids,
    openGraphConfig,
    signUp,
  } = data;

  return (
    <React.Fragment>
      <SeoTool openGraphConfig={{...openGraphConfig, url: window.location.href}} />
      <div className={classes.page}>
        <div className={classes.sectionTitle}>{data.sectionTitle}</div>
        <div className={classes.title}>{data.title}</div>
        {featuredArticle.title && <div className={classes.featuredArticle}>
          <img src={featuredArticle.image} alt={featuredArticle.title} />
          <div>
            <div className={classes.date}>{new Date(featuredArticle.date).toLocaleDateString(undefined, {month: 'long', day: 'numeric', year: 'numeric'})}</div>
            <div className={classes.title}>{featuredArticle.title}</div>
            <div className={classes.snippet}>{featuredArticle.snippet}</div>
            <div className={classes.link}>
              <Link withIcon to={`/resources/${featuredArticle.link}`}>View full article</Link>
            </div>
          </div>
        </div>}
        {articleGrids.map(grid => (
          <ArticleGrid {...grid} key={grid.title} />
        ))}
        {signUp.title && <SeoSignup {...signUp} />}
      </div>
    </React.Fragment>
  )
};

export default SEOHomepage;