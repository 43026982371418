const PREVIEW_ENDPOINT = "https://preview.contentful.com";
const PROD_ENDPOINT = "https://cdn.contentful.com";
const ACCESS_TOKEN = "aXdh-yNAPcnrLbi9tAModYJpinQ2bDhRNeH7y_XiISM";
const PREVIEW_TOKEN = "zP2QekdGnUHTfv0_HgANE56b_omdN_jKFo6xfyfBpvc";
const SPACE_ID = "8ds2ecbq6fon";

const isDev =
  window.location.hostname.indexOf("beta") >= 0 ||
  window.location.hostname.indexOf("localhost") >= 0 ||
  window.location.hostname.indexOf("dev.ojo.me") >= 0;

const endpoint = isDev ? PREVIEW_ENDPOINT : PROD_ENDPOINT;
const token = isDev ? PREVIEW_TOKEN : ACCESS_TOKEN;

const getImageUrl = (id, assets) => {
  const imageAsset = assets.find((asset) => {
    return asset.sys.id === id;
  });

  return imageAsset && imageAsset.fields.file ? imageAsset.fields.file.url : "";
};

const createArticle = (id, { Entry, Asset }) => {
  const apiArticle = Entry.find((e) => e.sys.id === id);

  if (!apiArticle) {
    return;
  }

  return {
    ...apiArticle.fields,
    image: getImageUrl(apiArticle.fields.image.sys.id, Asset),
    smallImage: getImageUrl(apiArticle.fields.smallImage.sys.id, Asset),
  };
};

const createArticleGrid = (id, { Entry, Asset }) => {
  const apiGrid = Entry.find((e) => e.sys.id === id);

  return {
    ...apiGrid.fields,
    articles: apiGrid.fields.articles
      .map((art) => {
        return createArticle(art.sys.id, { Entry, Asset });
      })
      .filter((art) => !!art),
  };
};

const getOpenGraphConfig = (config) => {
  return {
    title: config.openGraphTitle || config.seoTitleTag,
    site_name: config.openGraphSiteName || "OJO Home",
    description: config.openGraphDescription || config.seoMetaDescription,
    type: config.openGraphType || "website",
    image: config.openGraphImage || "https://cdn.ojo.me/ojo_circle.png",
  };
};

export const getPressList = (id, limit, skip, excludeId) => {
  return new Promise((resolve, reject) => {
    let url = `${endpoint}/spaces/${SPACE_ID}/environments/master/entries?access_token=${token}&content_type=article&fields.tags=${id}&limit=${limit}&skip=${skip}&order=-fields.date`;
    if (excludeId) {
      url += `&sys.id[nin]=${excludeId}`;
    }
    window
      .fetch(url, {
        method: "GET",
      })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            resolve({
              total: data.total,
              skip,
              limit,
              articles: data.items.map((item) => ({
                id: item.sys.id,
                image: item.fields.image
                  ? getImageUrl(item.fields.image.sys.id, data.includes.Asset)
                  : "",
                title: item.fields.title,
                date: item.fields.date,
                location: item.fields.location,
                snippet: item.fields.snippet,
                text: item.fields.text,
                isLocal: !item.fields.link,
                href: item.fields.link || `/article/${id}/${item.sys.id}`,
                video: item.fields.video,
              })),
            });
          });
        } else {
          reject();
        }
      })
      .catch(reject);
  });
};

export const getPressArticle = (id) => {
  return new Promise((resolve, reject) => {
    window
      .fetch(
        `${endpoint}/spaces/${SPACE_ID}/environments/master/entries?sys.id=${id}&access_token=${token}`,
        {
          method: "GET",
        }
      )
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            const item = data.items[0];
            resolve({
              id: item.sys.id,
              image: item.fields.image
                ? getImageUrl(item.fields.image.sys.id, data.includes.Asset)
                : "",
              mobileImage: item.fields.mobileImage
                ? getImageUrl(
                    item.fields.mobileImage.sys.id,
                    data.includes.Asset
                  )
                : "",
              title: item.fields.title,
              date: item.fields.date,
              location: item.fields.location,
              snippet: item.fields.snippet,
              text: item.fields.text,
              isLocal: !item.fields.link,
              href: item.fields.link || `/article/${id}/${item.sys.id}`,
              video: item.fields.video,
            });
          });
        } else {
          reject();
        }
      })
      .catch(reject);
  });
};

export const getBrokerLicenses = () => {
  return new Promise((resolve, reject) => {
    window
      .fetch(
        `${endpoint}/spaces/${SPACE_ID}/environments/master/entries?content_type=brokerLicensePage&include=2&access_token=${token}`,
        {
          method: "GET",
        }
      )
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            const page = data.items[0];
            const includesMap = {};
            data.includes.Entry.forEach((item) => {
              if (!item.fields.license) {
                item.fields.license = "";
              }
              includesMap[item.sys.id] = item.fields;
            });
            const licenses = page.fields.licenses.map((item) => {
              return includesMap[item.sys.id];
            });
            resolve(licenses);
          });
        } else {
          reject();
        }
      })
      .catch(reject);
  });
};

export const getSeoHomepage = () => {
  return new Promise((resolve, reject) => {
    window
      .fetch(
        `${endpoint}/spaces/${SPACE_ID}/environments/master/entries?content_type=seoHomepage&include=2&access_token=${token}`,
        {
          method: "GET",
        }
      )
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            const config = data.items[0].fields;
            const entries = {};
            const assets = {};
            const homepage = {};

            data.includes.Entry.forEach(
              (entry) => (entries[entry.sys.id] = entry)
            );
            data.includes.Asset.forEach(
              (asset) => (assets[asset.sys.id] = asset.fields.file.url)
            );

            homepage.title = config.title;
            homepage.sectionTitle = config.sectionTitle;
            homepage.seoTitleTag = config.seoTitleTag;
            homepage.seoMetaDescription = config.seoMetaDescription;
            homepage.featuredArticle = {
              date: config.featuredArticleDate,
              title: config.featuredArticleTitle,
              snippet: config.featuredArticleSnippet,
              link: entries[config.featuredArticleLink.sys.id].fields.id,
              image: getImageUrl(
                config.featuredArticleImage.sys.id,
                data.includes.Asset
              ),
            };
            homepage.signUp = {
              title: config.signUpTitle,
              subTitle: config.signUpSubTitle,
              buttonText: config.signUpButtonText,
            };

            homepage.openGraphConfig = getOpenGraphConfig(config);

            homepage.articleGrids = config.articleGrids.map((grid) => {
              return createArticleGrid(grid.sys.id, data.includes);
            });

            resolve(homepage);
          });
        } else {
          reject();
        }
      })
      .catch(reject);
  });
};

export const getSeoArticle = (id) => {
  return new Promise((resolve, reject) => {
    window
      .fetch(
        `${endpoint}/spaces/${SPACE_ID}/environments/master/entries?fields.id=${id}&content_type=seoArticle&include=2&access_token=${token}`,
        {
          method: "GET",
        }
      )
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            const apiArticle = data.items[0];

            const article = {
              ...apiArticle.fields,
              image: getImageUrl(
                apiArticle.fields.image.sys.id,
                data.includes.Asset
              ),
              smallImage: getImageUrl(
                apiArticle.fields.smallImage.sys.id,
                data.includes.Asset
              ),
              articleList: apiArticle.fields.articleList
                ? createArticleGrid(
                    apiArticle.fields.articleList.sys.id,
                    data.includes
                  )
                : null,
              openGraphConfig: getOpenGraphConfig(apiArticle.fields),
            };

            resolve(article);
          });
        } else {
          reject();
        }
      })
      .catch(reject);
  });
};

export default {
  getPressList,
};
