import React from "react";

import useDocumentTitle from "hooks/documentTitle";
import useMetaDescription from "hooks/metaDescription";
import useMetaKeywords from "hooks/metaKeywords";

import Offerings from "components/layout/Offerings";
import Section from "components/Section";
import HeroSection from "components/layout/HeroSection";

const Home = () => {
  useDocumentTitle("Buy, sell, and own with OJO");
  useMetaDescription(
    "OJO provides tools and expert guidance to help make homeownership more accessible to more people."
  );
  useMetaKeywords("OJO");

  return (
    <>
      <HeroSection largeTitle="Make the next move in your home journey"></HeroSection>

      <Offerings />
      <Section
        title="Think of us as your homeowning sidekick"
        description={
          <>
            Owning a home is a big deal. But who says you have to do it alone?
            <br /> At OJO, we provide the know-how and technology to help you
            take the next step with confidence.
          </>
        }
        center
        backgroundColor="forest"
        negative
        video
      >
        <>
          <div className="vimeo-video">
            <iframe
              src="https://player.vimeo.com/video/755627330?h=cf43597986&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                borderRadius: "20px",
              }}
              title="OJO: Make Moves"
            />
          </div>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </>
      </Section>
    </>
  );
};

export default Home;
