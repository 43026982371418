import {useEffect, useState} from "react";

const isNotOnTop = () => window.pageYOffset > 60;

function useScrollToView() {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const updateVisibility = () => { setVisible(isNotOnTop()) }

    window.addEventListener("scroll", updateVisibility);  

    return () => window.removeEventListener("scroll", updateVisibility);
  })
  
  return visible;
}

export default useScrollToView;
