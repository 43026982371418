import { deviceScreenSizeQuery } from "components/theme/index";
import styled from "styled-components";

export const StyledLeadershipGrid = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;

  @media ${deviceScreenSizeQuery} {
    
    flex-wrap: wrap;
    flex-direction: row;
  }
`;