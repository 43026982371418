import React from "react";
import { StyledPageHeader } from "./pageHeader/styled";
import Breadcrumbs from "./Breadcrumbs";

const PageHeader = ({ title, supertext, breadcrumbsLinks, negative }) => (
  <StyledPageHeader negative={negative}>
  {supertext && <div className="page-header__supertext">{supertext}</div>}
    {breadcrumbsLinks && (
      <div className="page-header__supertext">
        <Breadcrumbs links={breadcrumbsLinks} />
      </div>
    )}
    <h2 className="page-header__title">{title}</h2>
  </StyledPageHeader>
);

export default PageHeader;
