import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HashLink as RouterLink } from "react-router-hash-link";
import { StyledBreadcrumbs } from "./breadcrumbs/styled";

const Breadcrumb = ({link, index}) => {
  const { to, text } = link;
  let Tag = "a";
  
  if (to && !to.startsWith("http") && !to.startsWith("mailto")) {
    Tag = RouterLink;
  }

  return (
    <>
      {index !== 0 && <span> / </span>}
      <Tag to={to} href={to}>
        {index === 0 && <FontAwesomeIcon icon="arrow-right" rotation={180} />}
        {text}
      </Tag>
    </>
  )
};

const Breadcrumbs = ({
  links
}) => {
  return (
    <StyledBreadcrumbs>
      {links && 
        links.map((link, index) => <Breadcrumb link={link} index={index} key={index} />)
      }
    </StyledBreadcrumbs>
  );
};

Breadcrumbs.propTypes = {
  links: PropTypes.array,
};

Breadcrumbs.defaultProps = {
  links: null
};

export default Breadcrumbs;
