import React from "react";
import { StyledOverlappingSection } from "./overlappingSection/styled";

const OverlappingSection = ({
  icon,
  title,
  description,
  image,
  zIndex,
}) => (
  <StyledOverlappingSection zIndex={zIndex}>
    <div className="overlapping-section__wrapper">
      <div className="overlapping-section__icon"><img src={icon} alt=""/></div>
      <h2 className="overlapping-section__title">{title}</h2>
      <p className="overlapping-section__description">{description}</p>
    </div>
    <div className="overlapping-section__image">
      <img src={image} alt={title} />
    </div>
  </StyledOverlappingSection>
);

export default OverlappingSection;
