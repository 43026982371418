import { deviceScreenSizeQuery } from "components/theme/index";
import styled from "styled-components";

export const StyledFaqSection = styled.div`
  .faq-section__title {
    font-size: 2rem;
    line-height: 2.75rem;
    color: ${({theme}) => theme.colors.emerald};
    font-family: ${({theme}) => theme.fonts.primary};
    display: block;
    padding: 20px 0;
    margin-bottom: 20px;
  }

  + * {
    margin-top: 30px;
  }

  @media ${deviceScreenSizeQuery.tablet} {
    .faq-section__title {
      font-size: 2.5rem;
      line-height: 3.375rem;
      padding: 30px 0;
    }
  }
`;