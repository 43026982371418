import styled, { css } from "styled-components";
import { deviceScreenSizeQuery } from "components/theme/index";

export const StyledPageHeader = styled.div`
  .page-header__supertext {
    font-size: 1.375rem;
    line-height: 1.75rem;
    margin-bottom: 30px;
    font-weight: ${({ theme }) => theme.fonts.weight.bold};
    color: ${({ theme }) => theme.colors.raspberry};
  }

  .page-header__title {
    font-size: 2.75rem;
    line-height: 3rem;
    color: ${({ theme }) => theme.colors.emerald};
    padding-bottom: 30px;
    margin-bottom: 0;
  }

  @media ${deviceScreenSizeQuery.tablet} {
    .page-header__supertext {
      font-size: 1.125rem;
      line-height: 1.625rem;
    }

    .page-header__title {
      font-size: 4.75rem;
      line-height: 5.75rem;
    }
  }

  ${({ negative }) => negative && css`
    .page-header__title {
      color: ${({theme}) => theme.colors.seafoam};
    }

    .page-header__supertext {
      color: ${({theme}) => theme.colors.white};
    }
  `}
`;

export const StyledArticleHeader = styled(StyledPageHeader)`
  margin-bottom: 30px;

  .page-header__title {
    font-size: 2.25rem;
    line-height: 2.75rem;
    padding-bottom: 10px;
  }

  .page-header__info {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  @media ${deviceScreenSizeQuery.tablet} {
    .page-header__title {
      font-size: 2.5rem;
      line-height: 3rem;
    }
  }
`;
