import React from "react";

import BrandTile from "components/tiles/BrandTile";
import homeownerImage from "../../assets/img/manage_composed.png";
import homebuyerImage from "../../assets/img/find_composed.png";
import agentsImage from "../../assets/img/sell_composed.png";
import { StyledOfferings } from "./Offerings/styled";

const Offerings = () => {
  return (
    <StyledOfferings>
      <h2 className="offerings__title">We’re here to help with every step</h2>
      <div className="offerings__wrapper">
        <BrandTile
          headerTitle="Manage your home"
          description="Homeownership looks different every day. OJO Homeowner helps you manage it all in one place — think of it as your personal assistant for homeowning."
          buttonText="Sign Up"
          buttonTarget="https://homeowner.ojo.com/signup"
          buttonLinkProps={{ target: "_blank", rel: "noopener noreferer" }}
          headerImage={homeownerImage}
        />
        <BrandTile
          headerTitle="Find your home"
          description="Movoto by OJO gets you one step closer to “welcome home.” With the ability to customize your search experience based on your preferences, you can zero in on the houses that feel like home."
          buttonText="Start Your Search"
          buttonTarget="https://www.movoto.com/"
          buttonLinkProps={{ target: "_blank", rel: "noopener noreferer" }}
          headerImage={homebuyerImage}
          reversed
        />
        <BrandTile
          headerTitle="Sell your home"
          description="Understand your home’s estimated value and receive expert guidance from a local agent to help you maximize your next move."
          buttonText="Learn More"
          buttonTarget="https://www.movoto.com/seller-resources/"
          buttonLinkProps={{ target: "_blank", rel: "noopener noreferer" }}
          headerImage={agentsImage}
        />
      </div>
    </StyledOfferings>
  );
};

export default Offerings;
