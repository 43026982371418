import React from "react";

import { StyledCoreValuesContainer } from "./CoreValuesContainer/styled";

const CoreValuesContainer = ({children}) => {
  return (
    <StyledCoreValuesContainer>
      {children}
    </StyledCoreValuesContainer>
  );
};

export default CoreValuesContainer;
