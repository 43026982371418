import React from "react";

import useDocumentTitle from "hooks/documentTitle";
import useMetaDescription from "hooks/metaDescription";
import PageWrapper from "components/layout/PageWrapper";
import PageHeader from "components/layout/PageHeader";
import TextBlock from "components/layout/TextBlock";
import useMetaKeywords from "hooks/metaKeywords";


const PrivacyPage = () => {
  useDocumentTitle("OJO Labs Inc. Privacy Policy");
  useMetaDescription("OJO Privacy Policy");
  useMetaKeywords("OJO");
  
  return (
    <>
      <PageWrapper backgroundColor="white">
        <PageHeader
          title="OJO Labs Inc. Privacy Policy"
          supertext="Last updated: May 1, 2018"
        />
        <TextBlock>
          This Privacy Policy (“Policy”) describes and governs the information
          collection, use, and sharing practices of OJO Labs, Inc. and our
          affiliates or subsidiaries (“OJO Labs,” “OJO,” “we,” “us,” and “our”)
          with respect to your use of OJO Labs’ websites, products, tools,
          promotions, and any other services that reference this Policy
          (collectively, the “Services”).
          <br />
          <br />
          Before you use or submit any information through or in connection with
          the Services, please carefully review this Policy. By using any part
          of the Services, regardless of how you access the Services, you
          consent to the collection, use, and disclosure of your information as
          further outlined in this Policy. IF YOU DO NOT AGREE TO THIS POLICY,
          PLEASE DO NOT USE THE SERVICES. We will continue to evaluate this
          Policy as we update and expand the Services and our offerings, and we
          may make changes to the Policy accordingly. Any changes will be posted
          here and you should check this page periodically for updates. If we
          make material changes to this Policy, we will provide you with notice
          as required by law. Your continued use of the Services will signify
          acceptance of the terms of the updated Policy.
          <br />
          <br />
          <strong>1. Information We Collect</strong>
          <br />
          <br />
          We collect information in multiple ways, including when you provide
          information directly to us, and when we passively collect information
          from you, such as from your browser or device.
          <br />
          <br />
          a. Information You Provide Directly To Us
          <br />
          <br />
          We may collect information from you in
          <br />
          a variety of ways, such as when you:
          <br />
          <br />
          <ul>
            <li>Respond to our communications or communicate with us;</li>
            <li>Register for an account;</li>
            <li>
              Use certain features that require your information to function;
            </li>
            <li>Fill out a survey;</li>
            <li>Provide us with Feedback;</li>
            <li>
              Request certain features (e.g., newsletters, updates, and other
              products); or
            </li>
            <li>
              Post User Content, including comments, to or on any of our
              Services.
            </li>
          </ul>
          The information you provide directly to us may include, but is not
          limited to: (i) name; (ii) email address; (iii) physical addresses
          (e.g. your place of work); (iv) phone number; (v) birthdate; (vi)
          details about properties you are interested in and your preferences
          related to home searches (vii) photographs and audio/video content;
          and (viii) financial information. b. Information that is Passively or
          Automatically Collected
          <br />
          <br />
          b. Information that is Passively or Automatically Collected
          <ul className="no-decoration">
            <li>
              i. Device/Usage Information
            </li>
          </ul>
          We may automatically collect certain information about the computer or
          devices (including mobile devices or tablets) you use to access the
          Services. As described further below, we may collect and analyze
          information such as (a) IP addresses, geolocation information, unique
          device identifiers, IMEI and TCP/IP address, and other information
          about your computer or device(s), browser types, browser language,
          operating system, mobile device carrier information, the state or
          country from which you accessed the Services; and (b) information
          related to the ways in which you interact with the Services, such as:
          referring and exit web pages and URLs, platform type, the number of
          clicks, domain names, landing pages, pages and content viewed and the
          order of those pages, statistical information about the use of the
          Services, the amount of time spent on particular pages, the date and
          time you used the Services, the frequency of your use of the Services,
          error logs, and other similar information. As described further below,
          we may use third-party analytics providers and technologies, including
          cookies and similar tools, to assist in collecting this information.
          <br />
          <br />
          <ul>
            <li className="no-decoration">
              ii. Cookies and Other Electronic Technologies
            </li>
          </ul>
          We may also collect data about your use of the Services through the
          use of Internet server logs, cookies and/or tracking pixels. A web
          server log is a file where website activity is stored. A cookie is a
          small text file that is placed on your computer when you visit a
          website, that enables us to: (i) recognize your computer; (ii) store
          your preferences and settings; (iii) understand the web pages of the
          Services you have visited; (iv), enhance your user experience by
          delivering content and advertisements specific to your inferred
          interests; (v) perform searches and analytics; and (vi) assist with
          security administrative functions. Some cookies are placed in your
          browser cache while those associated with Flash technologies are
          stored with your Adobe Flash Player files. Tracking pixels (sometimes
          referred to as web beacons or clear GIFs) are tiny electronic tags
          with a unique identifier embedded in websites, online ads and/or
          email, and that are designed to provide usage information like ad
          impressions or clicks, measure popularity of the Services and
          associated advertising, and to access user cookies. We also may
          include Web beacons in email messages, newsletters and other
          electronic communications to determine whether the message has been
          opened and for other analytics, personalization and advertising. As we
          adopt additional technologies, we may also gather additional
          information through other methods.
          <br />
          <br />
          Please note that you can change your settings to notify you when a
          cookie is being set or updated, or to block cookies altogether. Please
          consult the "Help" section of your browser for more information (e.g.,
          Internet Explorer; Google Chrome; Mozilla Firefox; or Apple Safari).
          You can also manage the use of Flash technologies, including cookies
          and local storage objects with the Flash management tools available at
          Adobe's website. Please note that by blocking any or all cookies, you
          may not have access to certain features or offerings of the Services.
          <br />
          <br />
          <ul>
            <li className="no-decoration">
              iii. Location Information
            </li>
          </ul>
          We may collect different types of information about your location,
          including general information (e.g., IP address, zip code) and more
          specific information (e.g., GPS-based functionality on mobile devices
          used to access the Services), and may use that information to
          customize the Services with location-based information and features.
          We may use such information to improve the Services, including
          providing you with location- based features (e.g. to identify listings
          that may interest you). To deliver customized content and advertising,
          we may share your location information with our agents, vendors, or
          advertisers. If you access the Services through a mobile device and
          you do not want your device to provide us with location-tracking
          information, you can disable the GPS or other location-tracking
          functions on your device, provided your device allows you to do this.
          See your device manufacturer’s instructions for further details. If
          you disable certain functions, you may be unable to use certain parts
          of the Services.
          <br />
          <br />
          <ul>
            <li className="no-decoration">
              iv. Information from Third Parties
            </li>
          </ul>
          We may collect information about you or others through our affiliates
          or through non- affiliated third parties. Third parties have their own
          policies for handling your information. For a description of how these
          sites may use and disclose your information, including any information
          you make public, please consult the sites' privacy policies. We have
          no control over how any third-party site uses or discloses the
          personal information it collects about you.
          <br />
          <br />
          We may also collect information about you or others through
          non-affiliated third parties. For example, to the extent permitted by
          law, we may, in our sole discretion, ask for and collect supplemental
          information from third parties, such as information to verify your
          identity or trustworthiness, or for other fraud or safety protection
          purposes. We may combine information that we collect from you through
          the Services with information that we obtain from such third parties
          and information derived from any other products or services we
          provide.
          <br />
          <br />
          c. Collection of information across personal devices
          <br />
          <br />
          Sometimes we (or our service providers) may use the information we
          collect - for instance, log-in credentials, IP addresses, hashed email
          addresses, and unique mobile device identifiers - to locate or try to
          locate the same unique users across multiple browsers or devices (such
          as smartphones, tablets, or computers), or work with providers that do
          this, in order to better tailor content, features, and advertising,
          and provide you with a seamless experience across the devices you use
          to access the Services.
          <br />
          <br />
          <strong>2. How We Use Your Information</strong>
          <br />
          <br />
          We may use the information we collect from and about you for the
          following purposes:
          <br />
          <br />
          <ul>
            <li>
              <div>For the purposes for which you provided it;</div>
            </li>
            <li>
              <div>To recognize and authenticate you on the Services;</div>
            </li>
            <li>
              <div>
                To initiate or to provide the features, services and products
                available through the Services;
              </div>
            </li>
            <li>
              <div>
                To process transactions and send related information such as
                confirmations and invoices;
              </div>
            </li>
            <li>
              <div>
                To send you information about your relationship or transactions
                with us, technical notices, updates, account or security alerts,
                or other communications, such as newsletters to which you have
                subscribed;
              </div>
            </li>
            <li>
              <div>
                To contact you with information, surveys, or services that we
                believe may be of interest to you both regarding our products
                and Services and those of third parties;
              </div>
            </li>
            <li>
              <div>To process and respond to your inquiries;</div>
            </li>
            <li>
              <div>For internal research and reporting;</div>
            </li>
            <li>
              <div>
                To monitor and analyze trends, usage and activities in
                connection with our Services and for statistical purposes;
              </div>
            </li>
            <li>
              <div>
                To measure, improve, and understand the effectiveness of
                content, features, and Services, or develop new Services;
              </div>
            </li>
            <li>
              <div>
                To personalize the content and advertising that you see on the
                Services or on other websites;
              </div>
            </li>
            <li>
              <div>
                To facilitate the sending of SMS messages that provide
                information to you in response to your inquiries and on behalf
                of an agent you are communicating with;
              </div>
            </li>
            <li>
              <div>
                With your consent, to call or send you SMS messages regarding
                our Services as well as your relationship with us or offers or
                services that may interest you;
              </div>
            </li>
            <li>
              <div>
                To detect, investigate, and prevent fraud and other illegal
                activities;
              </div>
            </li>
            <li>
              <div>
                To enforce the legal terms that govern your use of the Services;
                and
              </div>
            </li>
            <li>
              <div>To administer and troubleshoot the Services.</div>
            </li>
          </ul>
          Please note that we may combine information that we collect from you
          and about you (including automatically-collected information) from one
          part of our Services with information that we collect from you and
          about you from other parts of our Services. We may also combine this
          information with information we obtain about you from our affiliates
          and/or non-affiliated third parties, and use such combined information
          in accordance with this Policy.
          <br />
          <br />
          We may aggregate and/or de-identify information collected through the
          Services. We may use de-identified and/or aggregated data for any
          purpose, including without limitation for research, analytics, and
          marketing purposes, and may also share such data with any third
          parties, including advertisers, promotional partners, other users,
          and/or others.
          <br />
          <br />
          We may send you commercial emails on behalf of ourselves, our
          affiliates, and advertisers.
          <br />
          <br />
          <strong>3. When We Disclose Your Information</strong>
          <br />
          <br />
          We may disclose and/or share your information to or with any
          non-affiliated third parties under the following circumstances:
          <br />
          <br />
          <ul>
            <li>
              Consent. We may disclose your information to any third parties
              based on your consent to do so when necessary to provide the
              Services you requested. For example, if you are working with a
              real estate brokerage, we will disclose your information and
              preferences with that brokerage and its agents. Similarly, if you
              are seeking to obtain a mortgage, we may share your information
              with a mortgage company.
            </li>
            <li>
              Service Providers and Sub-Contractors. We may provide access to or
              share your information with select third parties and
              sub-contractors who perform services on our behalf, including
              without limitation, marketing, market research, customer support,
              data storage, analysis and processing, and legal services.
            </li>
            <li>
              Partners with whom we conduct business. When OJO Labs partners
              with other businesses to offer products and services, we may share
              your information with those business partners.
            </li>
            <li>
              Public Record Information: Home sales and purchases are a matter
              of public record. Buyers’ name and address, the price paid,
              property taxes, and other information is automatically available
              from public sites. We may disclose to others some information that
              typically becomes part of the public record of a home sale or
              purchase.
            </li>
            <li>
              Protection of OJO Labs and Others. You acknowledge, consent, and
              agree that OJO Labs may access, preserve, and disclose your
              information and/or any content you submit or make available for
              inclusion on the Services, if required to do so by law or in a
              good faith belief that such access, preservation, or disclosure is
              permitted by this Policy or reasonably necessary or appropriate
              for any of the following reasons: (1) to comply with legal
              process; (2) to enforce this Policy, or other contracts with you,
              including investigation of potential violations thereof; (3) to
              respond to claims that any content violates the rights of third
              parties; (4) to respond to your requests for customer service;
              and/or (5) to protect the rights, property, or personal safety of
              OJO Labs, its agents and affiliates, its users, and the public.
              This includes exchanging information with other companies and
              organizations for fraud protection, and spam/malware prevention,
              and similar purposes.
            </li>
            <li>
              Business Transfers. As we continue to develop our business, we may
              buy, merge or partner with other companies. In such transactions,
              (including in contemplation of such transactions, e.g., due
              diligence) user information may be among the transferred assets.
              If a portion or all of OJO Labs’ assets are sold or transferred to
              a third-party, your information may be included among those
              transferred business assets.
            </li>
          </ul>
          <br />
          <br />
          <strong>4. Online Analytics and Advertising</strong>
          <br />
          <br />
          a. Analytics
          <br />
          <br />
          We may use third-party web analytics services (such as those of Google
          Analytics) on our Services to collect and analyze the information
          discussed above, and to engage in auditing, research or reporting. The
          information (including your IP address) collected by various analytics
          technologies described in the “Cookies and Similar Technologies”
          section will be disclosed to or collected directly by these service
          providers, who use the information to evaluate your use of the
          Services, including by noting the third-party website from which you
          arrive, analyzing usage trends, assisting with fraud prevention, and
          providing certain features to you. To prevent Google Analytics from
          using your information for analytics, you may install the Google
          Analytics Opt-out Browser Add-on by clicking here. We may also use
          Adobe Analytics to analyze and optimize the performance of our
          websites, advertising, and content.
          <br />
          <br />
          If you receive email from us, we may use certain analytics tools, such
          as clear GIFs to capture data such as when you open our message or
          click on any links or banners our email contains. This data allows us
          to gauge the effectiveness of our communications and marketing
          campaigns.
          <br />
          <br />
          b. Online Advertising
          <br />
          <br />
          We may use third-party advertising technologies that allow for the
          delivery of relevant content and advertising on our Services, as well
          as on other websites you visit. We also work with website analytics
          and advertising partners, including Google, Doubleclick Campaign
          Manager, and Facebook, to deliver OJO ads on third party publisher
          websites, and these partners may set cookies on your device's web
          browser. The ads may be based on various factors such as the content
          of the page you are visiting, information you enter such as your age
          and gender, your searches, demographic data, user-generated content,
          and other information we collect from you. These ads may be based on
          your current activity or your activity over time and across other
          websites and online services and may be tailored to your interests.
          <br />
          <br />
          We may also allow other third parties (e.g., ad networks and ad
          servers such as Google Analytics, DoubleClick and others) to serve
          tailored ads to you on the Services, other sites, and in other
          applications, and to access their own cookies or other tracking
          technologies on your computer, mobile phone, or other device you use
          to access the Services. We may provide our customer information (such
          as email addresses) to service providers, who may “match” this
          information in de-identified form to cookies (or mobile ad
          identifiers) and other proprietary IDs, in order to target or
          “retarget” you with ads when you visit other websites and mobile
          applications. (You may opt out of many of these service providers as
          described in the next paragraph.)
          <br />
          <br />
          We neither have access to, nor does this Policy govern, the use of
          cookies or other tracking technologies that may be placed on your
          computer, mobile phone, or other device you use to access the Services
          by non-affiliated, third-party ad technology, ad servers, ad networks
          or any other non-affiliated third parties. Those parties that use
          these technologies may offer you a way to opt out of ad targeting as
          described below. You may receive tailored advertising on your computer
          through a web browser. If you are interested in more information about
          tailored browser advertising and how you can generally control cookies
          from being put on your computer to deliver tailored advertising, you
          may visit the Network Advertising Initiative’s Consumer Opt-Out link
          or the Digital Advertising Alliance’s Consumer Opt-Out link to opt-out
          of receiving tailored advertising from companies that participate in
          those programs. To opt out of Google Analytics for display advertising
          or customize Google display network ads, you can visit the Google Ads
          Settings page.
          <br />
          <br />
          Please note that to the extent advertising technology is integrated
          into the Services, you may still receive advertisements even if you
          opt-out. In that case, the advertising will not be tailored to your
          interests. Also, we do not control any of the above opt-out links or
          whether any particular company chooses to participate in these opt-out
          programs. We are not responsible for any choices you make using these
          mechanisms or the continued availability or accuracy of these
          mechanisms.
          <br />
          <br />
          <strong>5. Notice Concerning Do-Not-Track Signals</strong>
          <br />
          <br />
          Do Not Track (“DNT”) is a privacy preference that users can set in
          certain web browsers. We do not recognize or respond to
          browser-initiated DNT signals, as the Internet industry is currently
          still working toward defining exactly what DNT means, what it means to
          comply with DNT, and a common approach to responding to DNT.
          <br />
          <br />
          <strong>6. Accessing and Controlling Your Data</strong>
          <br />
          <br />
          In order to ensure that the information we maintain is accurate, you
          may access certain of your information and delete, change, or modify
          that information in accordance with applicable laws. You must promptly
          update us if information you have provided to us previously changes or
          is inaccurate. Please be advised, however, that certain information
          may remain visible to OJO Labs. You may be able to request such
          deletion or modification through the Services or you can email
          legal@ojolabs.com. Upon your request, we will close your account and
          remove your information from view as soon as reasonably possible.
          Please contact OJO Labs at legal@ojolabs.com to request closure of
          your account. Please be advised that we may retain information from
          closed accounts to comply with the law, prevent fraud, collect any
          fees owed, resolve disputes, troubleshoot problems, assist with any
          investigations of any user, enforce our Terms of Use, and/or for any
          other purposes otherwise permitted by law that we deem necessary in
          our sole discretion. Once you transmit content through the Services,
          you may not be able to change or remove it.
          <br />
          <br />
          <strong>7. Consentto Transfer</strong>
          <br />
          <br />
          Our computer systems are currently based in the United States and your
          personal data will be processed by us in the United States and in
          other countries. The data protection and privacy regulations in each
          of these jurisdictions may not offer the same level of protection as
          in other parts of the world. By using the Services, you agree to this
          Policy and you consent to the transfer of all such information to the
          jurisdictions identified above, which may not offer a level of
          protection equivalent to that required in the jurisdiction in which
          you reside, and to the processing of that information as described in
          this Policy.
          <br />
          <br />
          <strong>8. Children’s Privacy</strong>
          <br />
          <br />
          The Services are intended for general audiences and not for children
          under the age of 13. If we become aware that we have collected
          personal information (as defined by the Children’s Online Privacy
          Protection Act) from children under the age of 13, we will take
          reasonable steps to delete it as soon as practicable.
          <br />
          <br />
          <strong>9. Security</strong>
          <br />
          <br />
          We have implemented administrative, technical, and physical security
          measures to protect against the loss, misuse and/or alteration of your
          information. These safeguards vary based on the sensitivity of the
          information that we collect and store. However, we cannot and do not
          guarantee that these measures will prevent every unauthorized attempt
          to access, use, or disclose your information since despite our
          efforts, no Internet and/or other electronic transmissions can be
          completely secure.
          <br />
          <br />
          You are responsible for maintaining the security of your applicable
          account. To the extent that you engage with the Services via SMS
          messages, you must protect access to your devices in order to prevent
          unauthorized access to the Services. If you believe that your account
          has been accessed without authorization, you must contact us
          immediately at legal@ojolabs.com immediately. We are not responsible
          if someone else accesses your account(s) through information or
          devices that they have obtained from you.
          <br />
          <br />
          <strong>10. Third-Party Links and Services</strong>
          <br />
          <br />
          The Services may contain links to or “frame” third-party websites,
          applications and other services (e.g., websites related to listings).
          Please be aware that we are not responsible for the privacy practices
          of such other sites and services. We encourage our users to be aware
          when they leave our Services and to read the privacy statements of
          each and every site they visit that collects their information.
          <br />
          <br />
          <strong>11. Your California Privacy Rights</strong>
          <br />
          <br />
          California Law permits visitors who are California residents to
          request certain information once per year regarding our disclosure of
          “personal information” (as that term is defined under applicable
          California law) to third parties for such third parties’ direct
          marketing purposes. To make such a request, please send an email to
          legal@ojolabs.com with “Privacy Support” in the subject line or write
          to us at OJO Labs, Inc., 720 Brazos St, #110, Austin, TX 78701,
          Attention Privacy Support.
          <br />
          <br />
          <strong>12. Changes to this Privacy Policy.</strong>
          <br />
          <br />
          OJO Labs reserves the right to change this Privacy Policy at any time
          to reflect changes in the law, our data collection and use practices,
          the features of our Services, or advances in technology. Please check
          this page periodically for changes. Your continued use of the Services
          following the posting of changes to this Privacy Policy will mean you
          accept those changes.
          <br />
          <br />
          <strong>13. Questions About this Policy</strong>
          <br />
          <br />
          If you have any questions about our Policy, you can contact us by
          emailing us at legal@ojolabs.com. You can also write to us at: OJO
          Labs, Inc., 720 Brazos St, #110, Austin, TX 78701, Attention: General
          Counsel.
        </TextBlock>
      </PageWrapper>
      <PageWrapper backgroundColor="white">
        <PageHeader
          title="NRT LLC Privacy Policy"
          supertext="Last updated: May 3, 2019"
        />
        <TextBlock>
          Scope of this Privacy Statement
          <br />
          <br />
          This Privacy Statement applies to your use of this Site only.
          <br />
          <br />
          The Site contains links to third-party web sites. When you click on
          those links, you will go to a third-party site where you will be
          subject to that site’s privacy statement and we encourage you to read
          that policy statement. Please be aware that we are not responsible for
          the privacy practices of those other web sites and we expressly
          disclaim any liability for their actions, including actions relating
          to the use and disclosure of Personal Information by those third
          parties.
          <br />
          <br />
          <strong>1. What is Personal Information?</strong>
          <br />
          <br />
          Personal Information is information collected online by us that
          identifies the person to whom such information pertains, including
          without limitation: name, address, telephone number, and e- mail
          address.
          <br />
          <br />
          <strong>2. Collection and Use of Your Information</strong>
          <br />
          <br />
          <ul className="no-decoration">
            <li>2(a) Personal Information</li>
          </ul>
          We collect your Personal Information on the Site only when submitted
          by you in order to enhance the services we offer you.
          <br />
          <br />
          Do Not Track Signals
          <br />
          <br />
          Do Not Track mechanisms or signals (“DNT signals”) enable a consumer
          to request the disabling of the tracking of their online activities
          over time and across third-party websites or online services. As
          permitted by law, we do not permit the disabling of DNT signals.
          <ul className="no-decoration">
            <li>2(b) Non-Personal Information</li>
          </ul>
          Even if you do not send us any Personal Information, we collect
          information about how you use our site (“Non-Personal Information”).
          This information cannot be used to identify you personally and is
          explained in more detail below.
          <br />
          <br />
          What is Non-Personal Information?
          <br />
          <br />
          Like many sites, we obtain Non-Personal Information, such as: IP
          (internet protocol) addresses, browser types, the name of a user’s ISP
          (internet service provider), information about where a user comes from
          before arriving at our Site, what pages a user visits, the order of
          those pages, and the amount of time spent on each. A vendor performs
          this service for us by sending a cookie to gather this information,
          compile it, and report to us.
          <br />
          <br />
          Use of Non-Personal Information
          <br />
          <br />
          We use Non-Personal Information to customize the advertising and
          content you see, improve our services, conduct research, and provide
          anonymous aggregated reporting for internal audits and third-parties.
          <br />
          <br />
          Cookies
          <br />
          <br />
          A cookie is a small data file, often including an anonymous unique
          identifier, that is sent from a web site's computer and stored on your
          computer's hard drive. Use of cookies is common on the Internet. A web
          site can send its own cookie to your browser if your browser's
          preferences allow it, but (to protect your privacy) your browser
          permits a web site to access only the cookies it has already sent to
          you, not the cookies sent to you by other sites. You can configure
          your browser to accept all cookies, reject all cookies, or notify you
          when a cookie is sent. (Each browser is different, so check the "Help"
          menu of your browser to learn how to change your cookie preferences.)
          You can reset your browser to refuse all cookies or indicate when a
          cookie is being sent. But if you refuse cookies, some parts of the
          Site will not function properly and may not provide services or
          information you have requested. For example, without cookies, we will
          not be able to provide you with searches that you have asked us to
          save.
          <br />
          <br />
          You can opt-out to the creation of a user profile, Hotjar’s storing of
          data about your usage of our site and Hotjar’s use of tracking cookies
          on other websites by following this opt-out link.
          <ul className="no-decoration">
            <li>2(c) Use of Information With Respect to Third Parties</li>
          </ul>
          We reserve the right to share your Personal Information with our
          affiliated companies and marketing and technology partners, as
          permitted by law, in order to fulfill our commitment of providing you
          with full service real estate brokerage services.
          <br />
          <br />
          In addition, we do share your Personal Information under the following
          circumstances:
          <br />
          <br />
          A. Third party vendors we engage to provide services on our behalf,
          such as hosting, web-site development, support, and consumer contact
          and lead development that may include phone calls and SMS messages,
          have access to Personal Information, but they have agreed not to
          disclose the Personal Information or to use it for any purpose other
          than providing the requested services.
          <br />
          <br />
          B. There may arise special circumstances where it is necessary for us
          to disclose and use Personal Information and Non-Personal Information,
          such as: to enforce this Privacy Statement or any other part of our
          Terms of Use; to protect our property or rights; to protect the safety
          of anyone; to investigate, prevent, or take action regarding possible
          illegal or improper activities; to carry out a merger or consolidation
          with another entity, a sale or transfer of some or all of our assets,
          or any similar transaction; or for other reasons we determine in good
          faith are necessary, appropriate, or required by law, such as when we
          respond to subpoenas, court orders, or other legal or administrative
          process, or in coordination with law enforcement.
          <br />
          <br />
          <strong>
            3. Location of Servers and Transfer of Information to the United
            States
          </strong>
          <br />
          <br />
          The Site is hosted by servers in the United States, and, as such, the
          Site is governed by United States law. If you are located outside of
          the United States, your Personal Information will be transferred to
          the United States. By submitting your Personal Information, you
          consent to its transfer to the United States and to its storage,
          processing, and use there in accordance with this Privacy Statement
          and United States law.
          <br />
          <br />
          <strong>4. Security</strong>
          Our hosting service maintains its systems in accordance with
          reasonable industry standards to reasonably secure the information of
          its customers against unauthorized access, accidental or intentional
          manipulation, and loss. However, no data transmission over the
          Internet can be guaranteed to be 100% secure, and you use the Site at
          your own risk.
          <br />
          <br />
          <strong>5. California Residents</strong>
          Under California law, California residents have the right to request
          in writing from businesses with whom they have an established business
          relationship (1) a list of categories of personal information, such as
          name, address, e-mail address, and the type of services provided to
          the customer, that a business has disclosed to third parties
          (including affiliates that are separate legal entities) during the
          immediately preceding calendar year for the third parties’ direct
          marketing purposes and (2) the names and addresses of all such third
          parties.
          <br />
          <br />
          To request the above information please contact us.
          <br />
          <br />
          We will respond to such requests within 30 days of receipt. Please
          note that we are only required to respond to each customer once per
          calendar year.
          <br />
          <br />
          <strong>6. Children</strong>
          <br />
          <br />
          This Site is not directed to children under the age of 13
          (“Children”), and we do not knowingly collect any information,
          including Personal Information, from Children.
          <br />
          <br />
          <strong>7. Correction/Updating Personal Information</strong>
          <br />
          <br />
          If your Personal Information changes or if you no longer wish to use
          the Site, you may correct or update your Personal Information or
          unsubscribe from any email alert services you have requested by
          modifying or deleting your profile on the Site.
          <br />
          <br />
          <strong>8. Comments and Questions</strong>
          <br />
          <br />
          If you have any questions or concerns about this Privacy Statement,
          please use the Contact Us form on coldwellbankerhomes.com.
        </TextBlock>
      </PageWrapper>
    </>
  );
};

export default PrivacyPage;
