import styled from "styled-components";
import { deviceScreenSizeQuery } from "components/theme/index";

export const StyledLogoList = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  align-items: center;
  padding-top: 60px;

  img{
    display: inline-block;
  }

  @media ${deviceScreenSizeQuery.tablet} {
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 30px;
    column-gap: 40px;
    row-gap: 30px;
    justify-content: center;
  }
`;