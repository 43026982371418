import React from "react";
import { StyledCheckList } from "./CheckList/styled";

const CheckList = ({ items, columns, gap }) => (
  <StyledCheckList columns={columns} gap={gap}>
    {items.map((item, index) => (
      <li className="checklist-item" key={index}>
        <span className="checklist-icon"></span>
        {item.text}
      </li>
    ))}
  </StyledCheckList>
);

export default CheckList;
