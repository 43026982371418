import { deviceScreenSizeQuery } from "components/theme/index";
import styled from "styled-components";

export const StyledCoreValuesContainer = styled.div`
  @media ${deviceScreenSizeQuery.tablet} {
    display: flex;
    flex-wrap: wrap;
    gap: 18px;
    justify-content: center;
  }

  @media (min-width: 920px) {
    max-width: 920px;
    margin: 0 auto;
  }

  @media (min-width: 1298px) {
    max-width: 100%;
    margin: 0 auto;
  }
`;