import styled, { css } from "styled-components";

export const StyledButton = styled.button`
  font-size: 1rem;
  line-height: 1.5rem;
  border-radius: 64px;
  background-color: ${({ theme }) => theme.colors.springgreen};
  border: 2px solid ${({ theme }) => theme.colors.springgreen};
  color: ${({ theme }) => theme.colors.night};
  cursor: pointer;
  padding: 12px 24px;
  font-family: ${({ theme }) => theme.fonts.default};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  transition: background-color ease-in 0.2s, color ease-in 0.2s,
    border-color ease-in 0.2s;
  user-select: none;

  ::focus {
    outline: none;
  }

  &:disabled {
    cursor: disabled;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}

  ${({ primary }) =>
    primary &&
    css`
      background-color: ${({ theme }) => theme.colors.springgreen};
      border-color: ${({ theme }) => theme.colors.springgreen};
      color: ${({ theme }) => theme.colors.night};

      &:hover {
        background-color: ${({ theme }) => theme.colors.green};
        border-color: ${({ theme }) => theme.colors.green};
        color: ${({ theme }) => theme.colors.night};
      }

      &:active {
        background-color: ${({ theme }) => theme.colors.darkgreen};
        border-color: ${({ theme }) => theme.colors.darkgreen};
      }

      &:disabled {
        background-color: ${({ theme }) => theme.colors.concrete};
        border-color: ${({ theme }) => theme.colors.concrete};
        color: ${({ theme }) => theme.colors.shark};
        cursor: disabled;

        &:hover,
        &:active {
          background-color: ${({ theme }) => theme.colors.concrete};
          border-color: ${({ theme }) => theme.colors.concrete};
          color: ${({ theme }) => theme.colors.shark};
        }
      }
    `}

  ${({ negative }) =>
    negative &&
    css`
      background-color: ${({ theme }) => theme.colors.springgreen};
      border: 2px solid ${({ theme }) => theme.colors.springgreen};
      color: ${({ theme }) => theme.colors.night};

      &:hover {
        background-color: ${({ theme }) => theme.colors.green};
        border-color: ${({ theme }) => theme.colors.green};
        color: ${({ theme }) => theme.colors.night};
      }

      &:active {
        background-color: ${({ theme }) => theme.colors.darkgreen};
        border-color: ${({ theme }) => theme.colors.darkgreen};
      }

      &:disabled {
        background-color: ${({ theme }) => theme.colors.concrete};
        border-color: ${({ theme }) => theme.colors.concrete};
        color: ${({ theme }) => theme.colors.shark};
        cursor: disabled;

        &:hover,
        &:active {
          background-color: ${({ theme }) => theme.colors.concrete};
          border-color: ${({ theme }) => theme.colors.concrete};
          color: ${({ theme }) => theme.colors.shark};
        }
      }
    `}  

  ${({ large }) =>
    large &&
    css`
      padding: 14px 32px;
      font-size: 18px;
      border-radius: 64px;

      &.active,
      :hover {
        padding: 14px 32px;
      }
    `} 

  ${({ tertiary }) =>
    tertiary &&
    css`
      background-color: transparent;
      border: 2px solid ${({ theme }) => theme.colors.raspberry};
      color: ${({ theme }) => theme.colors.raspberry};

      &:hover {
        background-color: ${({ theme }) => theme.colors.raspberry};
        border: 2px solid ${({ theme }) => theme.colors.raspberry};
        color: ${({ theme }) => theme.colors.white};
      }

      &:disabled {
        background-color: ${({ theme }) => theme.colors.concrete};
        border-color: ${({ theme }) => theme.colors.concrete};
        color: ${({ theme }) => theme.colors.shark};
        cursor: disabled;

        &:hover,
        &:active {
          background-color: ${({ theme }) => theme.colors.concrete};
          border-color: ${({ theme }) => theme.colors.concrete};
          color: ${({ theme }) => theme.colors.shark};
        }
      }
    `}
  
  ${({ secondary }) =>
    secondary &&
    css`
      background-color: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.night};
      border: 2px solid ${({ theme }) => theme.colors.night};
      white-space: nowrap;

      .icon {
        color: ${({ theme }) => theme.colors.night};
      }

      &:hover {
        padding: 12px 24px;
        background-color: ${({ theme }) => theme.colors.concrete};
        color: ${({ theme }) => theme.colors.night};
        border: 2px solid ${({ theme }) => theme.colors.night};
      }

      &:active {
        background-color: ${({ theme }) => theme.colors.gray};
      }

      &:disabled {
        background-color: ${({ theme }) => theme.colors.concrete};
        border-color: ${({ theme }) => theme.colors.gray};
        cursor: disabled;
      }

      ${({ negative }) =>
        negative &&
        css`
          background-color: ${({ theme }) => theme.colors.white};
          color: ${({ theme }) => theme.colors.night};
          border: 2px solid ${({ theme }) => theme.colors.night};

          &:hover {
            background-color: ${({ theme }) => theme.colors.concrete};
            color: ${({ theme }) => theme.colors.night};
            border: 2px solid ${({ theme }) => theme.colors.night};
          }

          &:active {
            background-color: ${({ theme }) => theme.colors.gray};
          }
        `}

      ${({ solid }) =>
        solid &&
        css`
          background-color: ${({ theme }) => theme.colors.white};
          color: ${({ theme }) => theme.colors.night};
          border: 2px solid ${({ theme }) => theme.colors.night};
        `}
    `}

  ${({ medium }) =>
    medium &&
    css`
      font-size: 0.875rem;
      padding: 10px 43px 11px;

      .icon {
        font-size: 2rem;
        margin-right: 7px;
      }

      &.withIcon {
        padding: 5px 9px 5px;
      }

      &.iconOnly .icon {
        margin: 0 auto;
      }
    `}
  
  ${({ small }) =>
    small &&
    css`
      font-size: 0.6875rem;
      padding: 13px 27px;

      .icon {
        font-size: 1.5rem;
        margin-right: 7px;
      }

      &.withIcon {
        padding: 4px 9px;
      }

      &.iconOnly .icon {
        margin: 0 auto;
      }
    `}
  

  ${({ withIcon }) =>
    withIcon &&
    css`
      display: flex;
      align-items: center;
    `} 
  
  ${({ stretch }) =>
    stretch &&
    css`
      width: 100%;
    `}
`;

export const EmeraldButton = styled.button`
  font-size: 1rem;
  line-height: 1.5rem;
  border-radius: 64px;
  color: ${({ theme }) => theme.colors.emerald};
  border: 2px solid ${({ theme }) => theme.colors.emerald};
  background-color: transparent;

  cursor: pointer;
  padding: 12px 24px;
  font-family: ${({ theme }) => theme.fonts.default};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  transition: background-color ease-in 0.2s, color ease-in 0.2s,
    border-color ease-in 0.2s;
  user-select: none;

  ${({ negative }) =>
    negative &&
    css`
      background-color: ${({ theme }) => theme.colors.springgreen};
      border: 2px solid ${({ theme }) => theme.colors.springgreen};
      color: ${({ theme }) => theme.colors.night};

      .active,
      &:hover {
        background-color: ${({ theme }) => theme.colors.springgreen};
        border: 2px solid ${({ theme }) => theme.colors.springgreen};
        color: ${({ theme }) => theme.colors.night};
      }
    `}
`;
