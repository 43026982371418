import { deviceScreenSizeQuery } from "components/theme/index";
import styled, { css } from "styled-components";

export const StyledItemCard = styled.div`
  .card__wrapper {
    display: block;
    transition: transform 0.4s ease;
    padding-top: 0px;

    @media ${deviceScreenSizeQuery.tablet} {
      ${({ size }) =>
        size === "small" &&
        css`
          padding-top: 30px;
        `}

      ${({ size }) =>
        size === "medium" &&
        css`
          padding-top: 80px;
        `}

      :hover {
        transform: translateY(-30px);
      }
    }
  }

  .card__container {
    width: 273px;
    height: 396px;
    border-radius: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;

    ${({ backgroundColor, theme }) =>
      backgroundColor &&
      css`
        background-color: ${theme.colors[backgroundColor]};
      `}

    @media ${deviceScreenSizeQuery.tablet} {
      width: auto;
      height: auto;
    }
  }

  .card__title {
    span {
      display: block;
      padding: 40px;
      font-size: 1.375rem;
      line-height: 1.75rem;
      font-weight: ${({ theme }) => theme.fonts.weight.semibold};
      color: ${({ theme }) => theme.colors.emerald};

      ${({ negative, theme }) =>
        negative &&
        css`
          color: ${theme.colors.seafoam};
        `}

      :hover {
        text-decoration: none;
      }
    }
  }
`;
